import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPeptideCls } from 'store/mainSlice';

//---------------------------------------------------------------------------
function AmDonutChartV4({ data, innerRadius = "40%", rowRefs, height = 570, ...props }) {
  const dispatch = useDispatch()

  const hoveredPeptideName = useSelector((state) => state.main.amDonutCharV4HoveredPeptideName);
  const isPeptideListHovering = useSelector((state) => state.main.isPeptideListHovering);

  const [mainSeries, setMainSeries] = useState(null);

  useEffect(() => {
    let chart = null;
    if (data) {
      // Enable chart theme
      am4core.useTheme(am4themes_animated);

      // (1) Create the chart
      let chart = am4core.create("chartdiv", am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0;

      // Add data to the chart
      chart.data = data;
      // Add inner radius for 3D effect
      chart.innerRadius = innerRadius;

      // (2) Create the series
      let series = chart.series.push(new am4charts.PieSeries3D());

      // Disable pull out on slice click
      var slice = series.slices.template;
      slice.states.getKey("active").properties.shiftRadius = 0;


      series.dataFields.value = "value";
      series.dataFields.category = "category";

      // Font Style
      series.labels.template.fontSize = "11px";
      series.ticks.template.stroke = am4core.color("#ffffff");
      series.labels.template.fill = am4core.color("#ffffff");

      series.labels.template.radius = am4core.percent(-2);
      // series.labels.template.horizontalCenter = "left";
      // series.labels.template.bent = true;
      // series.labels.template.padding(0, 1, 0, 1);

      // (3) Section Style
      const colorArray = [
        "#FAAC5F", "#db3a34", "#ffc857", "#AB1445", "#7FFFD4",
        "#4682B4", "#177e89", "#B0E0E6", "#B0C4DE", "#DDA0DD",
        "#54A9AD", "#DC143C", "#F08080", "#E9967A", "#FA8072",
        "#FFA07A", "#FFD700", "#BDB76B", "#F0E68C"
      ];
      const am4coreColors = colorArray.map(color => am4core.color(color));

      // Now, you can assign this to your series
      series.colors.list = am4coreColors;

      //  (4) Detect hover over any slice
      series.slices.template.events.on("over", function (event) {
        const eachData = event.target.dataItem.dataContext;
        if (eachData) {
          const category = eachData.category;

          if (rowRefs) {
            const element = rowRefs.current[category];
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest' // If the item is in the browser view, won't scroll the item to the top of browser
              });
              dispatch(updateSelectedPeptideCls({ value: category }))
            }
          }
        }

      });

      // Detect hover out of any slice
      series.slices.template.events.on("out", function (event) {
        // dispatch(updateSelectedPeptideCls({ value: ''}))
      });

      setMainSeries(series)
    }

    // Cleanup chart instance on component unmount
    return () => {
      if (data && chart) {
        chart.dispose();
      }
    };
  }, [data]);


  const [prevPeptideName, setPrevPeptideName] = useState('');
  useEffect(() => {
    const index = data.findIndex(item => item.category === hoveredPeptideName)
    const prevIndex = data.findIndex(item => item.category === prevPeptideName)

    if (mainSeries) {
      if (prevIndex !== -1 && mainSeries.dataItems.getIndex(prevIndex)) {
        const prevSlice = mainSeries.dataItems.getIndex(prevIndex).slice;
        if (prevSlice) {
          prevSlice.isHover = false;
        }
      }

      if (index !== -1 && mainSeries.dataItems.getIndex(index)) {
        const slice = mainSeries.dataItems.getIndex(index).slice;
        if (slice) {
          slice.isHover = true;
          setPrevPeptideName(hoveredPeptideName)
        }
      }
    }
    return () => {
    };
  }, [hoveredPeptideName]);


  useEffect(() => {
    if (mainSeries && !isPeptideListHovering) {
      mainSeries.slices.each((slice) => {
        slice.isHover = false;
      });
    }
    return () => {
    };
  }, [isPeptideListHovering, mainSeries]);

  return <div id="chartdiv" style={{ width: "100%", height: `${height}px` }} />;
}

export default AmDonutChartV4;