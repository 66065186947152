import { Button, Checkbox, MenuItem, Select, TableRow, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolEHPToolSearch.module.scss';
// import scssTools from 'views/tool/Tools.module.scss';
import scssHotSpot from './ToolHotSpotSearch.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText } from 'components/tag/Text';
import { TableRow2Body } from 'components/table/MuiTable';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { checkInvalidSeq, getFullPath, isOnlySpacesOrNewlines } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import StyledTextField from 'components/tool/StyledTextField';
import { RouterLink } from 'components/router/RouterLink';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import MuiSelect from 'components/mui/MuiSelect';
import LayoutPageV2 from 'layouts/LayoutPageV2';
// import { MuiTableContainer } from "components/MuiTable";

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'EHP-Tool' button -> this page
// path: paths.spp.tool.ehp_tool_search(),
const texts = {
  bread: breadData.create('Tools', 'Enzymatic Hydrolysis Prediction Tool (EHP-Tool)', 'EHP-Tool'),
  desc: 'EHP-Tool can cleave a given protein sequence according to the potential cleavage sites of proteases to obtain potential bioactive peptides. It will return the detailed information of the cleaved sequence, thereby to carry out relevant analysis on the resulting sequence, such as restriction site, sequence activity, bitterness prediction, etc.',

  step: ["Step 01", "Step 02 (Option)", "Step 03"],
  title0: ["Select a single protein", "Enter a peptide sequence", "Select simulated hydrolase"],
  title: {
    head: [
      'Introduction',
      'Enzymes List',
    ],
    json: [],
    body: [
      'EHP-Tool can cleave a given protein sequence according to the potential cleavage sites of proteases to obtain potential bioactive peptides. EHP-Tool will return the detailed information of the cleaved sequence, thereby to carry out relevant analysis on the resulting sequence, such as restriction site, sequence activity, bitterness prediction, etc.',
      'EnzymeTable'
    ],
  },

  step1: {
    title: 'Step1: Please select or enter a protein/peptide sequence.',
    head: [
      'Food source proteins',
      'Manual input',
    ],
    json: [],
    body: [
      '',
      "Please select the protein through the selection list above, or directly enter the protein or peptide sequence in the input box below, \ne.g. 'SERVELAT' (note: you can only choose one of the two selection methods):"
    ],
  },
  searchHint: 'Please enter protein/peptide sequences with single letters or sequences...',
  select: {
    class: 'Classification:',
    source: 'Source:',
    protein: 'Protein name:',
  },

  step2: {
    title: 'Step2: Please select simulated hydrolase.',
    head: [
      'Select enzymes',
      'All available enzymes',
    ],
    json: [],
    body: [
      'Default selection: Hydrolysis with human gastrointestinal endopeptidases (pepsin, trypsin and α-chymotrypsin).',
      '',
    ]
  },

  buttons: [
    'Check All',
    'Uncheck All',
    'Reverse Check',
    'Perform',
    'Reset',
  ],
  helps: [
    'Select all available enzymes or combinations',
    'Do not select all available enzymes or combinations',
    'Get the reverse selection of the current enzyme(s) or combination(s)',
    'the cleavage of the protein.',
    'the fields.',
  ],
  enzymes: [
    'Chymotrypsin', 'Ficin', 'Prolyl oligopeptidase',
    'Trypsin', 'Proteinase K', 'Pancreatic elastase',
    'Pepsin (pH = 1.3)', 'Clostripain', 'Pancreatic elastase II',
    'Pepsin (pH ? 2)', 'Thermolysin', 'Cathepsin G',
    'Glutamyl endopeptidase (pH = 4)', 'Chymotrypsin C', 'Chymase',
    'Glutamyl endopeptidase (pH = 7.8)', 'Leukocyte elastase', 'Thrombin',
    'Glutamyl endopeptidase II', 'Stem bromelain', 'Coagulation factor Xa',
    'Oligopeptidase B', 'Calpain-2', 'Glycyl endopeptidase',
    'Lactocepin', 'Xaa-Pro dipeptidase', 'Subtilisin',
    'Papain',
  ],
}

function rowData(name, value) {
  return { name, value };
}

function indexData(start, end) {
  return { start, end }
}

function optionData(label, value) {
  return { label, value }
}

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)
/* 
const MuiTableCell1st = (props) => (
  <MuiTableCell component="th" scope="row"
    {...props}>
    {props.children}
  </MuiTableCell>
)
 */
const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='right'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

export function cleanEHPToolState() {
  cookie.setCookie(cookie.keys.tool.ehpUserClassID, '')
  cookie.setCookie(cookie.keys.tool.ehpUserSourceID, '')
  cookie.setCookie(cookie.keys.tool.ehpUserProteinID, '')
  cookie.setCookie(cookie.keys.tool.ehpUserKeyword, '')
  cookie.setCookie(cookie.keys.tool.ehpUserEnzymeID, '')
}

export function setEHPToolState(keyword) {
  if( keyword !== undefined ) {
    cookie.setCookie(cookie.keys.tool.ehpUserClassID, '')
    cookie.setCookie(cookie.keys.tool.ehpUserSourceID, '')
    cookie.setCookie(cookie.keys.tool.ehpUserProteinID, '')
    cookie.setCookie(cookie.keys.tool.ehpUserEnzymeID, '')
    cookie.setCookie(cookie.keys.tool.ehpUserKeyword, keyword)
  } else
    console.log('ehpUserKeyword=', keyword);
}

//---------------------------------------------------------------------------
const ToolEHPToolSearch = ({ setInfo }) => {
  const [rows, setRows] = useState(getRows(texts))
  function getRows(texts) {
    return ([
      getRowFormText(texts.title),
      getRowFormText(texts.step1),
      getRowFormText(texts.step2),
    ])
  }
  function getRowFormText(table) {
    let rows = table.head.map((item, index) => (rowData(item, table.body[index])))
    // console.log('rows', rows)
    return (rows)
  }
  
  const userEnzymeId = cookie.getCookie(cookie.keys.tool.ehpUserEnzymeID)
  const userEnzymeIdArr = userEnzymeId ? userEnzymeId.split(',') : ''
  
  let location = useLocation()
  let bread = linkData('EHP-Tool', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  //---------------------------------------------------------------------------
  const [input, setInput] = useState({})
  const [isGetting, setIsGetting] = useState(false)
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const jsonsHot = spp.tool.hot_spot
  const apiProteinName = () => {
    setIsGetting(false)
    let input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
    setInput(input)

    const config = apiConfig.tool.hotspot_protein_name(input) //38
    // console.log(input);
    // console.log(config);

    axios(config).then(result => {
      let input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
      setInput(input)

      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())

        let items = result.data[spp.common.output.items]
        setProteinList(oldList => [...oldList, ...items]);
        setProteinNameStartIdx(result.data.end_index)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const [outputList, setOutputList] = useState({})
  const [itemsList, setItemsList] = useState([])
  const [stateList, setStateList] = useState(axiosState.init())
  const jsonsList = spp.protein.list
  const apiProteinList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        // console.log(result.data);

        setOutputList(result.data)
        let item = result.data[spp.common.output.items]
        setItemsList(item)
        // console.log('items', item);

        let classes = getOptions(item, jsonsList.items.class, jsonsList.items.class_id)
        setClassifyList(classes)
        // console.log('classify', classes);
        
        loadUserState(item)
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const [enzyme, setEnzyme] = useState([]) //output
  const [enzymeList, setEnzymeList] = useState([])
  // const [enzymeId, setEnzymeId] = useState([])
  // const [enzymeName, setEnzymeName] = useState([])
  const [enzymeIndex, setEnzymeIndex] = useState([])
  // const [enzymeChecked, setEnzymeChecked] = useState([]) //checked array [true, false, ...]
  // const [enzymeSelectIndex, setEnzymeSelectIndex] = useState([]) //select index
  const [stateEnzymesList, setStateEnzymesList] = useState(axiosState.init())
  const jsonsEHP = spp.tool.ehp_tool_enzymes_list
  const apiEnzymesList = () => {
    const config = apiConfig.tool.ehp_tool_enzymes_list() //49
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateEnzymesList(axiosState.error(false, stateEnzymesList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateEnzymesList(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        setEnzyme(output)
        //console.log('output', output);
        
        let id = output[jsonsEHP.output[0]]
        let item = output[jsonsEHP.output[1]]
        //console.log('enzyme id', id);
        // console.log('enzyme name', item);
        
        const arr = Array.from(item.keys())
        arr.sort( (a,b) => item[a].localeCompare(item[b]) )
        const itemSort = arr.map(i => item[i])
        const idSort = arr.map(i => id[i])
        
        let list = idSort.map((item, index) => {
          return {
            no: index+1,
            id: item, //"SPP_ID"
            name: itemSort[index], //"Term"
            isCheck: false,
            isDisable: false,
        }})
        setEnzymeList(list)
        // console.log('enzyme', list);
        
        // setEnzymeId(idSort)
        //console.log('enzyme id', idSort);
        
        // setEnzymeName(itemSort)
        setEnzymeCheckbox(itemSort)
        // console.log('enzyme name', itemSort);
      }
    }).catch(err => {
      setStateEnzymesList(axiosState.error(axiosCatch.isTimeout(err), stateEnzymesList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  // Step 01
  const [classifyList, setClassifyList] = useState([])
  const [classifyId, setClassifyId] = useState('')
  const handleChangeClassifyId = (value) => {
    setClassify(value, itemsList)
    setSourceId('')
    setProteinId('')

    setProteinNameStartIdx(0)
    setProteinList([])
    setIsGetting(false)
  }
  function setClassify(value, itemsList) {
    setClassifyId(value);
    // console.log('classify id:', value);
    let list = itemsList.filter(e => e[jsonsList.items.class_id] === value)
    let source = getOptions(list, jsonsList.items.source, jsonsList.items.source_id)
    setSourceList(source)
    // console.log('source list', list);
    // console.log('source option', source);
  }
  const [sourceId, setSourceId] = useState('')
  const [sourceList, setSourceList] = useState([])
  const [proteinNameStartIdx, setProteinNameStartIdx] = useState(0)
  const handleChangeSourceId = (value) => {
    // console.log('value', value, 'sourceId', sourceId);
    if (value !== sourceId) {
      // console.log('source id:', value);
      setSourceId(value);
      setProteinId('')

      setProteinNameStartIdx(0)
      setProteinList([])

      if (value !== '') {
        setState(axiosState.init())
        setIsGetting(true)
      }
    }
  }
  function getOptions(items, db_label, db_value) {
    let tmp = []
    tmp = items.map(a => a[db_label]);
    let labels = [...new Set(tmp)];
    tmp = items.map(a => a[db_value]);
    let values = [...new Set(tmp)];
    let options = []
    labels.forEach((label, index) => (
      options.push(optionData(label, values[index]))
    ))
    return options
  }
  const [proteinId, setProteinId] = useState('')
  const [proteinList, setProteinList] = useState([])
  const handleChangeProteinId = (value) => {
    // console.log('protein id:', value);
    setProteinId(value);
    
    let bDisable = (value !== '')
    if(bDisable)
      handleChangeSearch('')
    setDisableKeyword(bDisable)
  }
  
  function loadUserState(itemsList) {
    let userClass = cookie.getCookie(cookie.keys.tool.ehpUserClassID)
    let userSource = cookie.getCookie(cookie.keys.tool.ehpUserSourceID)
    let userProtein = cookie.getCookie(cookie.keys.tool.ehpUserProteinID)
    if( userClass !== undefined ) {
      setClassify(userClass, itemsList)
      if( userSource !== undefined ) {
        handleChangeSourceId(userSource)
        if( userProtein !== undefined ) {
          handleChangeProteinId(userProtein)
        }}}
  }
  
  //---------------------------------------------------------------------------
  // Step 02 (Option)
  let userKeyword = cookie.getCookie(cookie.keys.tool.ehpUserKeyword)
  if( userKeyword === undefined )
    userKeyword = ''
  const [keyword, setKeyword] = useState(userKeyword)
  const [isSearchError, setSearchError] = useState(false)
  const [isSeqInputError, setIsSeqInputError] = useState(false)
  const [seqInputErrMsg, setSeqInputErrMsg] = useState("")
  const [isDisableKeyword, setDisableKeyword] = useState(false)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)

    if (value === "") {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
    } else {
      setSearchError(false)
      setProteinId('')
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }
  
  //---------------------------------------------------------------------------
  // Step 03
  const optionSize = 3
  const handleChangeEnzyme = (id, prevList) => {    
    let bCheck
    setEnzymeList(prevItems => prevItems.map((item) => {
      if(item.id === id) {
        bCheck = !item.isCheck
        return { ...item, isCheck: bCheck };
      }
      return item
    }))
  }
  
  function setEnzymeCheckbox(item) {
    if (item !== undefined) {
      let index = []
      for (const i of Array(Math.ceil(item.length / optionSize)).keys())
        index.push(indexData(i * optionSize, (i * optionSize) + optionSize - 1))
      setEnzymeIndex(index)
      // console.log('index', index);
      
      if( userEnzymeIdArr.length > 0 ) {
        userEnzymeIdArr.forEach((id) => handleChangeEnzyme(id, item))
      } else {
        setEnzymeInit()
      }}
  }
  function setEnzymeInit() {
    let bCheck
    setEnzymeList(prevItems => prevItems.map((item) => {
      bCheck = (item.id === 'SPPENZY0047' //'Chymotrypsin C<br/>'
        || item.id === 'SPPENZY0059' //'Trypsin'
        || item.id === 'SPPENZY0027') //'Pepsin (pH = 1.3)'
      return { ...item, isCheck: bCheck };
    }))
  }
  
  const selectOption = (textButton, textHelp) => {
    // console.log(textButton);
    return (
      // <div className={scssButton.row_button} style={{ margin: '0px' }}>
      //   <Text className={'b1-darkGreen'}>{textHelp}</Text>
      // </div>
      <Button sx={{
        color: '#073431',
        padding: '8px 24px',
        borderRadius: '100px',
        border: ' 1px solid var(--Dark-Green, #073431)',
        margin: '0 10px'
      }}
        onClick={() => handleClickOption(textButton)}>{textButton}</Button>
    )
  }
  const handleClickOption = (value) => {
    switch (value) {
      case texts.buttons[0]: //'Check All',
        setEnzymeList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: true,
          isDisable: false,
        })))
        break
      case texts.buttons[1]: //'Uncheck All',
        setEnzymeList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: false,
          isDisable: false,
        })))
        break
      case texts.buttons[2]: //'Reverse Check',
        setEnzymeList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: !item.isCheck,
          isDisable: false,
        })))
        break
      default:
    }
  }
  
  //---------------------------------------------------------------------------
  // Bottom
  const handleClickReset = () => {
    setClassifyId('')
    setSourceId('')
    setProteinId('')
    setKeyword('')
    setDisableKeyword(false)
    setEnzymeInit()
    
    cleanEHPToolState()
  }
  
  const handleClickSearch = () => {    
    // console.log('enzymeList', enzymeList);
    let list = (enzymeList.filter(item => item.isCheck))
    // console.log('enzymeList', list);
    
    if(list.length > 0) {
      let listId = list.map(item => item.id)
      let listIdStr = listId.toString()
      // console.log('listIdStr', listIdStr);
      
      if( proteinId === '' && keyword === '') {
        setInfo(snackInfo.openError("Please finish step1 or step2(optional)"))
      } else if (proteinId !== '') {
        // console.log(proteinId)
        cookie.setCookie(cookie.keys.tool.ehpUserClassID, classifyId)
        cookie.setCookie(cookie.keys.tool.ehpUserSourceID, sourceId)
        cookie.setCookie(cookie.keys.tool.ehpUserProteinID, proteinId)
        cookie.setCookie(cookie.keys.tool.ehpUserKeyword, '')
        cookie.setCookie(cookie.keys.tool.ehpUserEnzymeID, listIdStr)
        
        navigate(paths.spp.tool.ehp_tool_result(listIdStr, { id: proteinId }))
      } else {
        const isInvalidCharObj = checkInvalidSeq(keyword);
        const onlySpaceNewlines = isOnlySpacesOrNewlines(keyword)
        if (isInvalidCharObj.isInvalid) {
          setIsSeqInputError(true)
          setSeqInputErrMsg(isInvalidCharObj.errMsg)
          setInfo(snackInfo.openError(isInvalidCharObj.errMsg))
        } else if (onlySpaceNewlines.isInvalid) {
          setIsSeqInputError(true)
          setSeqInputErrMsg(onlySpaceNewlines.errMsg)
          setInfo(snackInfo.openError(onlySpaceNewlines.errMsg))
        } else {
          setIsSeqInputError(false)
          setSeqInputErrMsg("")

          if (keyword !== '') {
            cookie.setCookie(cookie.keys.tool.ehpUserClassID, '')
            cookie.setCookie(cookie.keys.tool.ehpUserSourceID, '')
            cookie.setCookie(cookie.keys.tool.ehpUserProteinID, '')
            cookie.setCookie(cookie.keys.tool.ehpUserKeyword, keyword)
            cookie.setCookie(cookie.keys.tool.ehpUserEnzymeID, listIdStr)
            
            navigate(paths.spp.tool.ehp_tool_result(listIdStr, { keyword: keyword }))
          } else {
            setSearchError(true)
          }
        }
      }
    } else {
      if( proteinId !== '' || keyword !== '')
        setInfo(snackInfo.openError("Please finish setp3"))
      else
        setInfo(snackInfo.openError("Please finish step1, step2(optional) & setp3"))
      setSearchError(true)
    }
  }
  
  //---------------------------------------------------------------------------
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (proteinNameStartIdx !== -1) {
        apiProteinName();
      }
    }
  };
  
  useEffect(() => {
    if (axiosState.keepRest(stateList))
      apiProteinList()
    if (axiosState.keepRest(state) && isGetting === true)
      apiProteinName()
    if (axiosState.keepRest(stateEnzymesList))
      apiEnzymesList()
    // eslint-disable-next-line
  }, [isGetting])
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.desc}>
      
        {/* Enzymes List */}
        <ToolSingleBlock title={"Enzymes List"} mainBlockContent={
          <MuiTableContainer size='small'>
            <RouterLink newTab style={{ textDecoration: 'underline' }}
              to={paths.spp.tool.ehp_tool_enzyme_table()}>
              Enzymes List</RouterLink>
          </MuiTableContainer>} />
          
        {/* Step 01 */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.title0[0]} mainBlockContent={
          <MuiTableContainer>
            <div className={scssHotSpot.row_select} >
              <div className={scssHotSpot.select_each_block} >
                <Text>{texts.select.class}</Text>
                <MuiSelect id="standard-select-class"
                  className={scss.select_class}
                  value={classifyId}
                  onChange={(event) => handleChangeClassifyId(event.target.value)}
                >
                  {stateList.isLoading ? <LoadingAnime /> :
                    classifyList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                </MuiSelect>
              </div>

              <div className={scssHotSpot.select_each_block} >
                <Text>{texts.select.source}</Text>
                <MuiSelect id="standard-select-source"
                  className={scss.select_source}
                  value={sourceId}
                  onChange={(event) => handleChangeSourceId(event.target.value)}
                >
                  {stateList.isLoading ? <LoadingAnime /> :
                    sourceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                </MuiSelect>
              </div>

              <div className={scssHotSpot.select_each_block} >
                <Text>{texts.select.protein}</Text>
                <MuiSelect id="standard-select-protein"
                  className={scss.select_protein}
                  value={proteinId}
                  onChange={(event) => handleChangeProteinId(event.target.value)}
                  MenuProps={{
                    PaperProps: {
                      onScroll: handleScroll,
                    }
                  }}
                >
                  {proteinList.map((item, index) => {
                    let protein_name = item[jsonsHot.items[0]]
                    let SPP_ID = item[jsonsHot.items[1]]
                    return <MenuItem key={index} value={SPP_ID}>
                      {protein_name.length > 100 ? <Tooltip title={protein_name}>
                        {protein_name}
                      </Tooltip> : protein_name}
                    </MenuItem>
                  })}
                </MuiSelect>
              </div>
            </div>
          </MuiTableContainer>
        } />
        
        {/* Step 02 (Option) */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.title0[1]} mainBlockContent={
          <MuiTableContainer size='small'>
            {rows[1][1].value}
            <StyledTextField
              // sx={{ margin: 0, flex: 1, width: '97%' }}
              placeholder={texts.searchHint}
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              variant="standard"
              disabled={isDisableKeyword}
              error={isSeqInputError}
              helperText={seqInputErrMsg}
            />
          </MuiTableContainer>
        } />
        
        {/* Step 03 */}
        <ToolSingleBlock stepText={texts.step[2]} title={texts.title0[2]} sx={{ border: 0 }} mainBlockContent={
          <MuiTableContainer size='small' sx={{ border: 0 }} >
            <TableRow key={rows[2][1].name} >
              {enzymeIndex.map((value, count) => {
                return (
                  <TableRow2Body key={`body-${count}`} sx={{ border: 0 }} >
                    {stateEnzymesList.isLoading ? <LoadingAnime /> :
                      enzymeList.slice(value.start, value.end + 1).map((item, index) => {
                        let idx = (value.start + index)
                        return (
                          <StyledTableCell key={`cell-${count}-${index}`}
                            sx={index === 0 ? { padding: 0, paddingLeft: 1, border: 0 } : { padding: 0, border: 0 }}>
                            <div className={scss.checkbox} >
                              <MuiCheckbox
                                key={item.id}
                                name={item.name}
                                value={idx}
                                checked={item.isCheck}
                                onChange={() => handleChangeEnzyme(item.id, enzymeList)}
                                inputProps={{ 'aria-label': 'controlled' }} />
                              <div className={scss.checkbox_text} >
                                <RouterLink newTab
                                  to={paths.spp.tool.ehp_tool_enzyme_page(item.id)}>
                                  <TagText html={item.name} /></RouterLink>
                              </div>
                            </div>
                          </StyledTableCell>
                        )
                      })}
                  </TableRow2Body>
                )
              })}
            </TableRow>
          </MuiTableContainer>}

          anotherBlockContent={
            <TableRow key={rows[2][0].name}>
              {/* <MuiTableHeadCell1st>{rows[2][0].name}</MuiTableHeadCell1st> */}
              <MuiTableCell sx={{ border: 0 }}>
                {texts.buttons.slice(0, 2 + 1).map((item, index) => (
                  selectOption(item, texts.helps[index])
                ))}
                <div className={scss.select_block}>{rows[2][0].value}</div>
              </MuiTableCell>
            </TableRow>
        } />

        <div className={scssHotSpot.row_button}>
          <Button
            className={scssHotSpot.button_reset}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              textTransform: "none",
            }}
            onClick={handleClickReset}
          > {texts.buttons[4]}</Button>
          <Button
            className={scssHotSpot.button_search}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              textTransform: "none",
            }}
            onClick={handleClickSearch}
          > {texts.buttons[3]}</Button>
        </div>
        
    </LayoutPageV2>
  )
}
export default ToolEHPToolSearch