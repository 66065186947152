import React from 'react'
import EmailIcon from '@mui/icons-material/Email';

import scss from './ContactV2.module.scss';
// import NoneButton from 'components/button/NoneButton';
import Text from 'components/tag/Text';
import { DivLink } from 'components/tag/Div';
import NoneButton from 'components/button/NoneButton';
import GreenButton from 'components/button/GreenButton';
import ButtonPrim from 'components/button/ButtonPrim';

//---------------------------------------------------------------------------
const texts = {
  get: 'Get any questions? We are here to help.',
  contact: 'Contact us',
  email: 'cs@amway.com',
}

//---------------------------------------------------------------------------
// Contact Us
export default function ContactV2() {
  return (
    <div className={scss.layout}>
      <Text className='Title-Audiowide24px-Regular-Title'>{texts.get}</Text>
      
      <ButtonPrim text={texts.contact} startIcon={<EmailIcon />}
        onClick={() => { window.location.href = `mailto:${texts.email}` }} >
      </ButtonPrim>
    </div>);
};
