import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import v from 'assets/scss/_variables.scss';
import scss from 'components/tool/PeptideXYTable.module.scss'
import csScss from "components/chart/ChartSection.module.scss";
import axios, { apiConfig, axiosCatch, axiosState } from "utils/network/axios";
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from "utils/cookie";
import { spp } from "utils/network/jsons";
// import StyledTableCell from "styledComponents/StyledTableCell";
// import DenseStyledTableCell from "styledComponents/DenseStyledTableCell";
import { Space } from "utils/general";
import paths from "utils/network/apiPath";
import { MuiTableContainer } from "components/table/MuiTable";
import { RouterLink } from "components/router/RouterLink";

//---------------------------------------------------------------------------
const jsonNc = spp.tool.statistical_nc_table
const jsonA = spp.tool.statistical_a_table
const texts = {
  head_peptide: 'Peptide',
  nc_table: {
    title: 'The percentage of each amino acid coded at two terminals of different functional peptides',
    head: [
      'N-terminal (%)',
      'C-terminal (%)',
    ],
    json_n: 'n_',
    json_c: 'c_',
  },
  a_table: {
    title: 'The percentage of amino acid composition encoding different functional peptides',
    head: [
      'Amino acid',
    ],
    json_a: 'a_',
  }
}

// const useStyles = styled({
//   table: {
    // minWidth: 650,
    // "& .MuiTableCell-root": {
    //   border: '1px solid black'
    // }
//   }
// });

const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" arrow classes={{ popper: className }} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: v.green,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: v.green,
    color: v.darkGreen,
    fontSize: '16px',
  },
}));

const MuiTableCell = (props) => {
  const {children, ...other} = props
  return (
    <TableCell align="center" style={{padding: '1px'}} {...other}>
      {children}
    </TableCell>)
}

const MuiTableCell4Head = (props) => {
  const {children, ...other} = props
  return (
    <MuiTableCell align="center" className="bgc-dark-green color-white" {...other}>
      {children}
    </MuiTableCell>)
}
const XYTableCell4HeadTop = (props) => (
  <MuiTableCell4Head style={{fontSize: 16}} {...props}>
    {props.children}
  </MuiTableCell4Head>
)
const XYTableCell4Head = (props) => {
  const {children, index, isNC, ...other} = props
  let isLink = (String(children).length === 1)
  let link = paths.spp.tool.peptide_statistical_nc_chart(children)
  if( !isNC )
    link = paths.spp.tool.peptide_statistical_a_chart(children)
  return (
    <MuiTableCell4Head align={index === 0 ? 'right' : 'center'} {...other}>
      {isLink
      ? (<RouterLink newTab to={link} className="color-white">{children}</RouterLink>)
      : children}
    </MuiTableCell4Head>)
}

const XYTableCell = (props) => {
  const {children, className, ...other} = props
  let cls = scss.table_body_cell
  if( className !== undefined )
    cls += ` ${className}`
  return (
    <TableCell align="center" className={cls} {...other}>
      {children}
    </TableCell>)
}
const XYTableCell1st = (props) => {
  const {children, ...other} = props
  let name = children
  name = name.replaceAll('inhibitory', 'i')
  return (
    <XYTableCell align="right" component="th" scope="row" className={scss.table_body_cell_1st} {...other}>
      <MuiTooltip title={children}>{name}</MuiTooltip>
    </XYTableCell>)
}

const xyTableRowCell4Body = (row, texts, jsons) => {
  const key = texts.head_peptide
  const name = row[key]
  // console.log(key, name);
  return (
  <TableRow key={name}>
    {jsons.map((item, index) => {
      let value = row[item]
      if( index === 0 )
        return (<XYTableCell1st key={index}>{name}</XYTableCell1st>)
      else
        return (
          <XYTableCell key={index} 
            style={{color: getColor(value), background: getBackground(item)}}
          >{value}</XYTableCell>)
    })}
  </TableRow>)
}
function getColor(number) {
  let num = Number(number)
  if( num >= 10 ) //10%
    return 'red'
  else if( num < 10 && num >= 7 )
    return 'orange'
  else if( num < 7 && num > 0)
    return 'green'
  else
    return v.grey75
}
function getBackground(key) {
  if( key.indexOf(texts.nc_table.json_c) !== -1 )
    return v.grey25
  else
    return v.white
}
/*
const aTableRowCell4Body = (row, texts, jsons) => {
  const key = texts.head_peptide
  const name = row[key]
  // console.log(key, name);
  return (
  <TableRow key={name}>
    {jsons.map((item, index) => {
      let value = row[item]
      if( index === 0 )
        return (<XYTableCell1st key={index}>{name}</XYTableCell1st>)
      else
        return (
          <XYTableCell key={index}
            style={{color: getColor(value), background: getBackground(item)}}
          >{value}</XYTableCell>)
    })}
  </TableRow>)
}
*/
//---------------------------------------------------------------------------
export default function PeptideXYTable({ setInfo }) {
  // const classes = useStyles();
  
  // const [ncOutput, setNcOutput] = useState({})
  const [ncAminoAcids, setNcAminoAcids] = useState([])
  // const [ncFeatures, setNcFeatures] = useState({})
  const [ncFeaturesRows, setNcFeaturesRows] = useState([])
  const [ncHeads, setNcHeads] = useState([])
  const [ncJsons, setNcJsons] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiList = () => {
    const config = apiConfig.tool.statistical_nc_table() //72
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setNcOutput(output)
        // console.log('nc output', output)
        
        let aa = output[jsonNc.output[0]] //"amino_acids"
        setNcAminoAcids(aa)
        // console.log('amino_acids', aa)
        let heads = []
        heads.push(texts.head_peptide)
        heads = heads.concat(aa)
        let headsNC = heads.concat(aa)
        setNcHeads(headsNC)
        // console.log('heads', heads)
        // console.log('headsNC', headsNC)
        
        let jsonsN = heads.map((item) => (texts.nc_table.json_n + item))
        let jsonsC = heads.slice(1).map((item) => (texts.nc_table.json_c + item)) //with out name
        let jsonsNC = jsonsN.concat(jsonsC)
        setNcJsons(jsonsNC)
        // console.log('jsonsN', jsonsN)
        // console.log('jsonsNC', jsonsNC)
        
        let features = output[jsonNc.output[1]] //"features"
        // setNcFeatures(features)
        // console.log('nc features', features)
        
        let rows = getRows(features, heads)
        setNcFeaturesRows(rows)
        // console.log('rows', rows)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  function getRows(features, heads) {
    if(Object.isObject(features)) {
      // console.log('features', features);
      let rows = []
      for (let [key, value] of Object.entries(features)) {
        // console.log(key, value);
        let name = value[jsonNc.peptides[0]] //"display_name"
        let n = []
        n = value[jsonNc.peptides[1]]
        let c = []
        c = value[jsonNc.peptides[2]]
        let valueN = getRow(texts.nc_table.json_n, heads, name, n)
        // console.log('N', valueN);
        let valueC = getRow(texts.nc_table.json_c, heads, null, c)
        // console.log('C', valueC);
        let row = {...valueN, ...valueC}
        // console.log('row',row);
        rows.push(row)
      }
      // console.log('rows',rows);
      return (rows)
    }
    return {}
  }
  function getRow(text, heads, name, n) {
    // console.log('heads=',heads);
    // console.log('name=', name, 'n=', n);
    let obj = {}
    heads.forEach((item, index) => {
      let key = text + item
      if(index === 0) {
        if(name) { //NOT undefined, null, ""
          // obj[key] = name
          obj[item] = name
        }
      } else
        obj[key] = n[index-1]
    })
    // console.log(obj);
    return obj
  }
  
  // const [aOutput, setAOutput] = useState({})
  const [aAminoAcids, setAAminoAcids] = useState([])
  // const [aFeatures, setAFeatures] = useState({})
  const [aFeaturesRows, setAFeaturesRows] = useState([])
  const [aHeads, setAHeads] = useState([])
  const [aJsons, setAJsons] = useState([])
  
  const [stateA, setStateA] = useState(axiosState.init())
  const apiListA = () => {
    const config = apiConfig.tool.statistical_a_table() //73
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateA(axiosState.error(false, stateA.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateA(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setAOutput(output)
        // console.log('a output', output)
        
        let aa = output[jsonA.output[0]] //"amino_acids"
        setAAminoAcids(aa)
        // console.log('a amino_acids', aa)
        let heads = []
        heads.push(texts.head_peptide)
        heads = heads.concat(aa)
        setAHeads(heads)
        // console.log('a heads', heads)
        
        let jsons = heads.map((item) => (texts.a_table.json_a + item))
        setAJsons(jsons)
        // console.log('a jsons', jsons)
        
        let features = output[jsonA.output[1]] //"features"
        // setAFeatures(features)
        // console.log('a features', features)
        
        let rows = getRowsA(features, heads)
        setAFeaturesRows(rows)
        // console.log('a rows', rows)
      }
    }).catch(err => {
      setStateA(axiosState.error(axiosCatch.isTimeout(err), stateA.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  function getRowsA(features, heads) {
    if(Object.isObject(features)) {
      // console.log('features', features);
      let rows = []
      for (let [key, value] of Object.entries(features)) {
        // console.log(key, value);
        let name = value[jsonA.peptides[0]] //"display_name"
        let p = []
        p = value[jsonA.peptides[1]]
        let row = getRow(texts.a_table.json_a, heads, name, p)
        // console.log('row',row);
        rows.push(row)
      }
      // console.log('rows',rows);
      return (rows)
    }
    return {}
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiList()
    if (axiosState.keepRest(stateA))
      apiListA()
  }, [])
  
  return (
    <>
      <div className={csScss.main}>
        <div className={csScss.section_title}>{texts.nc_table.title}</div>
      </div>
      <div className={scss.main}>
        <MuiTableContainer>
          {/* <Table className={classes.table} aria-label="table"> */}
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <XYTableCell4HeadTop />
                {texts.nc_table.head.map((item) => (
                  <XYTableCell4HeadTop key={item} colSpan={ncAminoAcids.length}>{item}</XYTableCell4HeadTop>
                ))}
              </TableRow>
              <TableRow>
                {ncHeads.map((item, index) => (
                  <XYTableCell4Head key={index} index={index} isNC={true}>{item}</XYTableCell4Head>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ncFeaturesRows.map((row) => (
                xyTableRowCell4Body(row, texts, ncJsons)
              ))}
            </TableBody>
          </Table>
        </MuiTableContainer>
      </div>
      
      <Space />
      <div className={csScss.main}>
        <div className={csScss.section_title}>{texts.a_table.title}</div>
        <MuiTableContainer>
          {/* <Table className={classes.table} aria-label="table"> */}
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <XYTableCell4HeadTop />
                {texts.a_table.head.map((item) => (
                  <XYTableCell4HeadTop key={item} colSpan={aAminoAcids.length}>{item}</XYTableCell4HeadTop>
                ))}
              </TableRow>
              <TableRow>
                {aHeads.map((item, index) => (
                  <XYTableCell4Head key={index} index={index} isNC={false}>{item}</XYTableCell4Head>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {aFeaturesRows.map((row) => (
                // aTableRowCell4Body(row, texts, aJsons)
                xyTableRowCell4Body(row, texts, aJsons)
              ))}
            </TableBody>
          </Table>
        </MuiTableContainer>
      </div>
      
    </>
  );
}