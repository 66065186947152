import { useSearchParams } from "react-router-dom";

import scss from 'views/tool/Tools.module.scss';
import paths from "utils/network/apiPath";
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';
import LayoutPageV2 from "layouts/LayoutPageV2";

//------------------------------------------------------------------------------
// http://localhost:3000/peptide_mol?seq=aa
const texts = {
  bread: breadData.create('Molstar', ''),
}

//---------------------------------------------------------------------------
const Molstar = () => {
  const [queryParameters] = useSearchParams()
  const sequence = queryParameters.get(paths.params.sequence)
  const peptideSequence = sequence.toUpperCase()
  //只需要把這個變量對應起來即可，就是肽的序列
  // const peptideSequence = "AA";
  // console.log('peptideSequence', peptideSequence);
  
  texts.bread.title = 'Peptide Sequence ' + peptideSequence
  
  return (
    <LayoutPageV2 bread={texts.bread}>
        <div style={{
          position: 'relative',
          // width: '95vw', 
          height: '95vh',
           }}>
        <iframe
          src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${peptideSequence}`}
          width="100%"
          height="540px" //40px + height(molstart index.html #app)
          title="JS MolstarViewer"
          style={{ border: 'none' }}
        />
      </div>
    </LayoutPageV2>);
};

export default Molstar;
