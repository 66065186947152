import React from 'react'

import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/privacy
const texts = {
  bread: breadData.create('Privacy Policy', 'Privacy Policy'),
}

//---------------------------------------------------------------------------
const Privacy = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
    </LayoutPageV2>
  )
}

export default Privacy;