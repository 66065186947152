import { Button } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';

import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------
export default function ButtonSec12(props) {
  return (
  <Button
      className={scss.secondary12_button}
      sx={{
        boxShadow: "none",
        textTransform: "none",
      }}
      // startIcon={}
      // startIcon={<DeleteIcon />} //test
      {...props}
  >
    {props.text}
  </Button>)
}