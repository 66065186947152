import React from 'react'
import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//------------------------------------------------------------------------------
// http://localhost:3000/terms
const texts = {
  bread: breadData.create('Terms & Conditions', 'Terms & Conditions'),
}

//---------------------------------------------------------------------------
const Terms = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
    </LayoutPageV2>
  )
}

export default Terms;