

import { initBreadTextFontSize } from './BreadBar';
// import scss from './BreadText.module.scss';
import scss2 from './BreadTextV2.module.scss';
import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
export default function BreadTextV2(props) {
  const { bread } = props
  

  //---------------------------------------------------------------------------
  return (
    <div className={scss2.frame}>
      <Text className='Title-Audiowide28px-Regular-Title' fontSize={bread ? bread.fontSize : initBreadTextFontSize}>
        {bread ? bread.title : '...'}
      </Text>
    </div>)
};