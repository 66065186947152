import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss'
import { breadData } from 'components/bread/BreadBar';
import { cardData } from 'components/bread/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText, TagTextWithScrollBar } from 'components/tag/Text';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import scss from './PeptideDFBPIDResult.module.scss';

import cookie from 'utils/cookie';
import { isEmptyObjOrArray, outside } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage2 from 'layouts/LayoutPage2';
import links, { linkData } from 'utils/links';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getLinkFromArray, getScssNameCell } from 'components/peptide/sppID';
import { setEHPToolState } from 'views/tool/ToolEHPToolSearch';
import { handleScroll } from 'components/peptide/sppLayout';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Slide } from '@mui/material';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';
import ResultSidebar from 'components/protein/ResultSidebar';
import GreenButton from 'components/button/GreenButton';


//---------------------------------------------------------------------------
// Go in: 'Peptide' page -> any item list -> any 'SPP ID' -> this page
// path: paths.spp.peptide.id_result(),

const jsons = spp.peptide.search.id
const jsonsPrecursor = spp.peptide.search.id_precursor_result
const jsonsTarget = spp.peptide.search.id_target_protein
const texts = {
  tabs: [
    'Function-activity Relationship',
    
    'Physicochemical Properties',
    'Peptide Source & Food-borne Protein(s) Search',
    'Food Safety',
    'Biological/Functional Activity & Target Protein',
    'Taste Properties',
    
    '3D Structure',
    'Preparation Method',
    'Stability',
    'Additional Information',
    'Database Cross-references',
    
    'Reference(s)',
  ],
}

const tabs = {
  _00card: {
    head: jsons.output_text.slice(0, 3 + 1),
    json: jsons.output.slice(0, 3 + 1),
  },

  _0function: {
    title: texts.tabs[0],
    head: [
      ...jsons.output_text.slice(4, 5 + 1),
    ],
    json: [
      ...jsons.output.slice(4, 5 + 1),
    ],
  },

  _1calculated: {
    title: texts.tabs[1],
    head: [
      jsons.output_text[6],
      ...jsons.output_text.slice(7, 15 + 1),
      ...jsons.output_text.slice(34, 46 + 1),
      'Peptide Calculator',
    ],
    json: [
      jsons.output[6],
      ...jsons.output.slice(7, 15 + 1),
      ...jsons.output.slice(34, 46 + 1),
      'To calculate the physicochemical properties of bioactive peptide.',
    ],
    button_peptide: 'Peptide Calculator',
    unit_c: function (value) {
      return `<span>${value}<sup>C</sup></span>` //c
    },
  },

  _2calculated: {
    title: texts.tabs[2],
    head: [
      ...jsons.output_text.slice(16, 20 + 1),
      'Link-research',
    ],
    json: [
      ...jsons.output.slice(16, 20 + 1),
      'There are no literature reports on the discover of this sequence in other food-source proteins.',
    ],
    precursor_first: function (number) {
      return (`Source ${number + 1}: `)
    },
    no_precursor: 'No matching precursor protein found',
    button_view: 'View HotSpot',
    button_inductive: 'Inductive Analysis',
  },
  
  _30cytotoxicity: {
    title: texts.tabs[3],
    head: [
      ...jsons.output_text.slice(28, 28 + 1),
    ],
    json: [
      ...jsons.output.slice(28, 28 + 1),
    ],
  },

  _3biological: {
    title: texts.tabs[4],
    head: [
      ...jsons.output_text.slice(21, 22 + 1),
    ],
    json: [
      ...jsons.output.slice(21, 22 + 1),
      // ...jsons.output.slice(21, 21 +1),
      // 'Angiotensin-converting enzyme',
    ],
    specific: 'Specific Target Protein(s):',
    potential: 'Potential Target Protein(s):',
  },

  _4taste: {
    title: texts.tabs[5],
    head: [
      ...jsons.output_text.slice(23, 23 + 1),
    ],
    json: [
      ...jsons.output.slice(23, 23 + 1),
    ],
    bitness: 'prediction',
  },
  
  _41structure: {
    title: texts.tabs[6],
    head: [
      ...jsons.output_text.slice(24, 25 + 1),
    ],
    json: [
      ...jsons.output.slice(24, 25 + 1),
    ],
  },

  _5preparation: {
    title: texts.tabs[7],
    head: [
      ...jsons.output_text.slice(26, 27 + 1),
    ],
    json: [
      ...jsons.output.slice(26, 27 + 1),
    ],
  },

  _6stability: {
    title: texts.tabs[8],
    head: [
      ...jsons.output_text.slice(28, 28 + 1),
    ],
    json: [
      ...jsons.output.slice(28, 28 + 1),
    ],
    ehp_tool_link: 'Enzymatic Hydrolysis Prediction Tool (EHP-Tool)',
  },

  _7additional: {
    title: texts.tabs[9],
    // head: [
    //   // 'Although the active peptide is derived from rat serum. it can be obtained from food-borne proteins.',
    //   ...jsons.output_text.slice(29, 29 +1),
    // ],
    json: [
      ...jsons.output.slice(30, 30 + 1),
    ]
  },

  _8database: {
    title: texts.tabs[10],
    head: [
      ...jsons.cross_references_text.slice(0, 4 + 1),
    ],
    json: [
      ...jsons.output.slice(31, 31 + 1),
    ],
    text_d: function (number) {
      return (`[D${number}] `)
    },
  },

  _9reference: {
    title: texts.tabs[11],
    head: [
      ...jsons.output_text.slice(32, 34 + 1),
    ],
    json: [
      ...jsons.output.slice(32, 34 + 1),
    ],
    primary_title: [
      '',
      'PMID: ',
      'DOI: ',
    ]
  },
}

const MuiButton = (props) => {
  return (
    <NoneButton className={scss.cell_button} variant="contained" {...props}>
      <Text className='b2strong-darkGreen'>{props.children}</Text>
    </NoneButton>
  )
}

export function handleClickBitness(output) {
  let ps = output["peptide_sequence"]
  cookie.setCookie(cookie.keys.peptide.bitterPeptideSearchSeq, ps)
  // console.log('peptide_sequence', ps);
}

export function handleClickEHPtool(output) {
  let ps = output["peptide_sequence"]
  setEHPToolState(ps)
  // console.log('peptide_sequence', ps);
}

//---------------------------------------------------------------------------
const PeptideDFBPIDResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  let nameDFBPID = searchParams.get(paths.params.id)
  let jsonClassify = searchParams.get(paths.params.class)
  // let jsonClassify = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsTableName)
  const input = jsons.input(jsonClassify, nameDFBPID) //21
  // const inputPrecursor = jsonsPrecursor.input(jsonClassify, nameDFBPID) //77 inputPrecursor=input
  // const inputTarget = jsonsTarget.input(jsonClassify, nameDFBPID) //88 peptide_target_protein inputTarget=input
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let bread4th = cookie.getCookie(cookie.keys.protein.bread4th)
  // console.log('bread4th', bread4th);
  let bread5th = cookie.getCookie(cookie.keys.protein.bread5th)
  // console.log('bread5th', bread5th);
  let bread = breadData.create('Peptide', '', bread3rd, nameDFBPID)
  if( bread4th !== '' )
    bread = breadData.create('Peptide', '', bread3rd, bread4th, nameDFBPID)
  if( bread5th !== '' )
    bread = breadData.create('Peptide', '', bread3rd, bread4th, bread5th)
  
  const words = {
    bread: bread,
  }
  
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.peptide.id_search(input) //21
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        let output = result.data
        if( output.ic50 !== undefined )
          output.ic50 = output.ic50.replace(/,\s*(?![^<>]*>)/,', <br>')
        setOutput(output)
        // console.log('output', output)
        
        // setBread3rd(output["main_bioactivity"])

        let pm = output[jsons.output[9]] //"peptide_mass",
        // console.log('peptide_mass', pm)
        let bit = output[jsons.output[23]]
        // console.log('bitterness', bit)
        let ps = output[jsons.output[27]] //"peptide_stability",
        // console.log('peptide_stability', ps)
        let pc = output[jsons.output[28]] //"peptide_cytotoxicity",
        // console.log('peptide_cytotoxicity', pc)
        let cross = output[jsons.output[30]] //"cross_references",
        // console.log('cross_references', cross)
        let obj = {}
        obj.peptide_mass = pm
        obj.bitterness = bit
        obj.peptide_stability = ps
        obj.peptide_cytotoxicity = pc
        obj.cross_references = cross
        setOutputObj(obj)
        // console.log(obj)

        let card = []
        for (const i of (Array(4).keys())) {
          card.push(cardData(jsons.output_text[i], output[jsons.output[i]],))
        }
        setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)

      let obj = {}
      obj.peptide_mass = {}
      obj.bitterness = {}
      obj.peptide_stability = {}
      obj.peptide_cytotoxicity = {}
      obj.cross_references = {}
      setOutputObj(obj)
    })
  }
  
  //---------------------------------------------------------------------------
  // Precursor Protein(s) Search
  const [outputPrecursorObj, setOutputPrecursorObj] = useState({}) //object
  const [statePrecursor, setStatePrecursor] = useState(axiosState.init())
  const apiPrecursorResult = () => {
    const config = apiConfig.peptide.id_precursor_result(input) //77
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePrecursor(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStatePrecursor(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        // setOutput(output)
        // console.log('output', output)
        
        let obj = {}
        let pps = output[jsonsPrecursor.output[0]] //"precursor_protein_search",
        // console.log('precursor_protein_search', pps)
        obj.precursor_protein_search = pps
        setOutputPrecursorObj(obj)
        // console.log(obj)
    }
    }).catch(err => {
      setStatePrecursor(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      let obj = {}
      obj.precursor_protein_search = {}
      setOutputPrecursorObj(obj)
    })
  }
  
  //---------------------------------------------------------------------------
  // Specific Target Protein(s)
  // const [outputTargetObj, setOutputTargetObj] = useState({}) //object
  const [outputSpecificObj, setOutputSpecificObj] = useState({}) //object
  const [outputPecificArr, setOutputPecificArr] = useState([]) //array object
  const [stateTarget, setStateTarget] = useState(axiosState.init())
  const apiTargetResult = () => {
    const config = apiConfig.peptide.id_target_protein(input) //88
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTarget(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTarget(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        // setOutputTargetObj(output)
        // console.log('output', output)
        
        let stp = output[jsonsTarget.output[0]]
        if(stp === undefined)
          stp = {}
        setOutputSpecificObj(stp)
        // console.log('outputSpecificObj', stp)
        let ptp = output[jsonsTarget.output[1]]
        if(ptp === undefined)
          ptp = []
        setOutputPecificArr(ptp)
        // console.log('outputPecificArr', ptp)
    }
    }).catch(err => {
      setStateTarget(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
    if (axiosState.keepRest(statePrecursor))
      apiPrecursorResult()
    if (axiosState.keepRest(stateTarget))
      apiTargetResult()
  }, [outputPrecursorObj])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    const isCross = (tab.json === tabs._8database.json)
    let indexRef = 0 //bug D number always being from 1
    const title = tab.title
    const is3DStructure = (title === '3D Structure')
    const peptideSeq = output["peptide_sequence"]
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{title}
          {is3DStructure
          ? <>
              <IconButton onClick={handleClickOpen}>
                <AddIcon style={{color: 'var(--green-alpha-alpha-40)'}} />
              </IconButton>
              <DialogMolstar seq={peptideSeq} />
            </>
          : null}
        </Text>
        {tab.head.map((item, index) => {
          // console.log(index)
          const json = tab.json[index]
          let isHide = false
          let isAddButton = false
          let link = ""
          if (isCross) {
            const label = jsons.cross_references[index]
            const val = outputObj.cross_references[label]
            // console.log(val)
            if (val === null) //"SPP",
              isHide = true

            link = getLinkFromArray(jsons.cross_references_link ,item)
          }
          if (item === 'Peptide Calculator'
            || item === "Precursor Protein(s) Search"
            || item === 'Link-research') {
            isAddButton = true
          }
          if( item === 'Activity' ) {
            item = output[jsons.output[4]] //"main_bioactivity", //[4]
          }
          if( item === 'Molstar' ) {
            let seq = output["peptide_sequence"]
            // console.log('seq', seq);
            
            return (
              <div key={`div-${item}`} className={`${scss.cell_text} ${scss.right_cell_line}`}>
                <iframe
                  src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}`}
                  width="100%"
                  height="540px" //40px + height(molstart index.html #app)
                  title="MolstarViewer"
                  style={{ border: 'none' }}
                />
              </div>)
          }
          
          return (isHide
            ? null
            : <div key={index} className={scss[getScssNameCell(json, tab, index)]}>
              <div key={`div-${item}`} className={scss.cell_label}>
                <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                  {link !== ""
                    ? <A href={link} className={getClassNameTitle(item)} >
                      <TagText html={item} />
                    </A>
                    : <TagText html={item} />}
                </Text>
                {isAddButton ? <CellButton name={item} /> : null}
                {/* {isAddButton ? <GreenButton name={item} /> : null} */}
              </div>
              <div key={`div-${index}`} className={scss.cell_value}>
                <Text key={`text-${index}`} className={getClassName(json, output)}>
                  {statePrecursor.isLoading && json === "precursor_protein_search"
                    ? <LoadingAnime />
                    : getValue(output, outputObj, tab.json, index, indexRef)}
                </Text>
              </div>
            </div>)
        })}
      </>
    )
  }

  const CellButton = (props) => {
    const { name, children } = props
    let text = ''
    if (name === 'Peptide Calculator')
      text = tabs._1calculated.button_peptide //'Link-research'
    else if (name === "Precursor Protein(s) Search")
      text = tabs._2calculated.button_view //'View HotSpot'
    else if (name === 'Link-research')
      text = tabs._2calculated.button_inductive //'Inductive Analysis' //unused
    return (
      <div>
        {children}
        <GreenButton onClick={() => handelClickCellButton(text)}>{text}</GreenButton>
      </div>
    )
  }
  const handelClickCellButton = async (text) => {
    // console.log('text', text);
    let keyword = output["peptide_sequence"] //'Single-letter Amino Acid'
    if (text === tabs._1calculated.button_peptide) { //'Link-research'
      navigate(paths.spp.tool.peptide_calculator_result(keyword))
    } else if (text === tabs._2calculated.button_view) { //'View HotSpot'
      navigate(paths.spp.tool.hotspot_result_chart(keyword, {all: 1, id: nameDFBPID}))
    } else if (text === tabs._2calculated.button_inductive) { //'Inductive Analysis'
      let display_name = output["main_bioactivity"]
      display_name = display_name.removeHTMLTags()
      // console.log(display_name);
      await cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, display_name)
      navigate(paths.spp.peptide.inductive_analysis(keyword, jsonClassify))
    }
  };
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log('index', index)
    let idx = index
    if (json === tabs._8database.json)
      idx = 0
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)

      if (Array.isArray(value)) {
        // console.log('name', name, 'value', value)
        let string = ''
        switch (name) { //spp.peptide.search.id.output
          case "primary_literature":
            for (let i = 0; i < value.length; i++) {
              string += tabs._9reference.primary_title[i]
              if (i === 1) {
                string += links.outside.htmlPMID(value[i]) + '\n'
              } else if (i === 2) {
                string += links.outside.htmlDOI(value[i]) + '\n'
              } else
                string += value[i] + '\n'
            }
            valueReturn = string
            break
          default:
            valueReturn = name
        }
      }

      if (Object.isObject(value)) {
        // console.log('name', name, 'value', value)
        let string = '', label, val
        switch (name) { //spp.peptide.search.id.output
          case "peptide_mass":
            string = ''
            for (const i of Array(jsons.peptide_mass_text.length).keys()) {
              const txt = jsons.peptide_mass_text[i]
              const label = jsons.peptide_mass[i]
              const val = outputObj.peptide_mass[label]
              string += `${txt}${val}\n`
            }
            valueReturn = string
            break
          case "bitterness":
            string = ''
            // console.log(jsons.bitterness_text);
            let txt
            return jsons.bitterness_text.map((item, i) => {
              txt = jsons.bitterness_text[i]
              label = jsons.bitterness[i]
              val = outputObj.bitterness[label]
              val = val.removeHTMLTags()
              // console.log('val=', val);
              if( i === 1 ) //Bitter Prediction Tools:
                return (<>
                  <Text>{`${txt}${val} `}</Text>
                  <sup>
                    <RouterLink newTab to={paths.spp.tool.bpp_tool_result()}
                      onClick={() => handleClickBitness(output)}>
                      {tabs._4taste.bitness}</RouterLink></sup>
                </>)
              else
                return <TagText html={`${txt}${val}\n`} />
            })
          case "peptide_stability":
              const text = jsons.peptide_stability_text
              label = jsons.peptide_stability[0]
              val = outputObj.peptide_stability[label]
              return (
                <>
                  <TagText html={`${text[0]}${val}\n`} />
                  {text[1]}
                  <RouterLink newTab to={paths.spp.tool.ehp_tool_search()}
                    onClick={() => handleClickEHPtool(output)}>
                    {tabs._6stability.ehp_tool_link}
                  </RouterLink>
                </>)
          case "peptide_cytotoxicity": //"Peptide Safety"
            for (let i of Array(jsons.peptide_cytotoxicity_text.length).keys()) {
              const txt = jsons.peptide_cytotoxicity_text[i]
              const label = jsons.peptide_cytotoxicity[i]
              const val = outputObj.peptide_cytotoxicity[label]
              if (i === 1)
                string += `${txt}<u>${links.outside.htmlToxinPredSite()}</u>\n`
              else
                string += `${txt}${val}\n`
            }
            // valueReturn = string
            valueReturn = string
            break
          case "cross_references":
            label = jsons.cross_references[index]
            val = value[label]
            switch (index) {
              case 0: //SPP
                if (Object.isObject(val)) {
                  // console.log(val);
                  let cls, isMulti, link
                  let arr = Object.keys(val) //key array
                  return (
                    arr.map((x) => {
                      indexRef += 1
                      cls = val[x]
                      // console.log('val=', val, 'x=', x);
                      // console.log('cls', cls);
                      isMulti = (String(x) === 'multifunctionalpeptides')
                      return (
                        <>
                          <Text>{tabs._8database.text_d(indexRef)}</Text>
                          {cls.map((id, idx) => {
                            if( isMulti )
                              link = paths.spp.peptide.id_multi_result(id)
                            else
                              link = paths.spp.peptide.id_result(id, x)
                            return (
                              <>
                                <RouterLink newTab to={link}>
                                  {id}</RouterLink>
                                {idx !== (cls.length - 1) ? ', ' : '\n'}
                              </>)
                            })}
                        </>)
                  }))
                } else
                  string = val
                break
              case 1: //BIOPEP-UWM
                indexRef += 1
                let txt = ''
                let ary = val.split(',')
                // console.log('val=', val, 'ary=', ary)
                if (ary.length > 1) {
                  for (let i of Array(ary.length).keys()) {
                    txt += links.outside.htmlBIOPEP_UWM(ary[i])
                    if (i !== ary.length - 1)
                      txt += ','
                  }
                } else if( val !== '-' )
                  txt = links.outside.htmlBIOPEP_UWM(val)
                else
                  txt = val
                string = tabs._8database.text_d(indexRef) + txt
                break
              // case 2: //APD
              // case 3: //BioPepDB
              // case 4: //MBPDB
              default:
                indexRef += 1
                string = tabs._8database.text_d(indexRef) + val
            }
            // console.log('indexRef', indexRef)
            valueReturn = string
            break
          default:
            valueReturn = name
        }
      }

      if(value === null) {
        // console.log('name=', name, 'value=null');
        switch (name) { //spp.peptide.search.id.output
          case "precursor_protein_search": //object content move to api 77
            let string = '', line, label, val
            let fragment = output["peptide_sequence"]
            // Source.1: DFBPPR0921 ---- Plant proteins ---- Very-long-chain aldehyde decarbonylase GL1-5
            let ary_label = jsonsPrecursor.precursor_protein_search[0]
            let ary = outputPrecursorObj.precursor_protein_search[ary_label]
            // console.log('precursor_protein_search', ary);
            
            if( ary !== undefined && Array.isArray(ary) && ary.length > 0 ) {
              // console.log('precursor_protein_search size', ary.length);
              return (
                <div style={{display: 'block',
                  maxHeight: '450px',
                  overflowY: 'auto',
                  paddingRight: '12px',
                  minHeight: '36px'}}
                >
                  {ary.map((item, i) => {
                    line = ''
                    for (let j = 0; j < jsonsPrecursor.precursor_protein_search.length; j++) {
                      label = jsonsPrecursor.precursor_protein_search[j]
                      val = outputPrecursorObj.precursor_protein_search[label]
                      // console.log(val)
                      if (j === 0)
                        line += tabs._2calculated.precursor_first(i) + item
                      else
                        line += ' - ' + val[i]
                    }
                    string = line + '\n'
                    // console.log(string);
                    return (
                      <RouterLink newTab
                        to={paths.spp.tool.hotspot_result_precursor(nameDFBPID, item, fragment)}>
                        {string}</RouterLink>)
                })}
              </div>)
            } else {
              valueReturn = tabs._2calculated.no_precursor
            }
            break
          default:
            valueReturn = ''
            break
        }
      } else {
        if(!Array.isArray(value) && !Object.isObject(value)) {
          switch (name) { //spp.peptide.search.id.output
            case "net_charge":
            case "isoelectric_point":
            case "gravy":
              let valueFix = Number(value).toFixed(2)
              valueReturn = (tabs._1calculated.unit_c(valueFix))
              break
            case "activity":
              return <TagTextWithScrollBar html={value.replaceHTML()} />
            case "specific_target_protein": //always "N.D" in api 21
              if(!Object.isObject(outputSpecificObj)) { //"N.D"
                return <TagText html={outputSpecificObj} />
              } else {
                let name = '', id = ''
                id = outputSpecificObj["target_id"]
                name = outputSpecificObj["target_name"]
                let isPotential = (outputPecificArr.length > 0)
                // console.log('isAddPotential', isAddPotential);
                return (<>
                  <TagText html={tabs._3biological.specific} /><br />
                  <RouterLink newTab to={paths.spp.peptide.id_target_protein_page(id)}>
                    {name}</RouterLink><br />
                  {isPotential
                  ? (<>
                      <TagText html={tabs._3biological.potential} /><br />
                      {outputPecificArr.map((obj, index) => {
                        // console.log(index, obj);
                        id = obj["target_id"]
                        return (<>
                          <RouterLink newTab key={index}
                            to={paths.spp.peptide.id_target_protein_page(id)}>
                            {obj["target_name"]}
                          </RouterLink><br />
                        </>)
                      })}
                    </>)
                  : null}
                </>)
              }
            default:
              // console.log('value', value);
              valueReturn = value
          }
        }
      }
    } else {
      // console.log('name', name);
      valueReturn = name
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell from text
  const TabCellText = (props) => { //no table key&value just show one line text
    const { tab, output } = props
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>
        {tab.json.map((name, index) => {
          const value = output[name]
          return (
            <div key={`div-${name}`} className={scss[getScssNameCell('', tab, index)]}>
              <div key={`div2-${name}`} className={scss.cell_text}>
                <Text key={`text-${name}`} className='b1-grey100'>
                  <TagText html={value} />
                </Text>
              </div>
            </div>)
        })}
      </>)
  }
  
  //------------------------------------------------------------------------------
  const [openMolstar, setOpenMolstar] = React.useState(false);

  const handleClickOpen = () => {
    setOpenMolstar(true);
  };

  const handleClose = () => {
    setOpenMolstar(false);
  };
  
  const DialogMolstar = (props) => {
    const { seq } = props
    return (
      <Dialog
        fullWidth={true}
        maxWidth={false}
        // fullScreen={true}
        open={openMolstar}
        onClose={handleClose}
      >
        <DialogContent style={{ height: '90vh' }}>
          <iframe
            src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}`}
            width="100%"
            height="100%" //40px + height(molstart index.html #app)
            title="MolstarViewer"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    )
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);

  /********************************************
 * Initialize activeTab & Listen scroll - Start
 ********************************************/
  /* useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
 
  const ContentObjList = [
    <TabCell tab={tabs._0function} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1calculated} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._2calculated} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._30cytotoxicity} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._3biological} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._4taste} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._41structure} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._5preparation} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._6stability} output={output} outputObj={outputObj}></TabCell>,
    <TabCellText tab={tabs._7additional} output={output} outputObj={outputObj}></TabCellText>,
    <TabCell tab={tabs._8database} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._9reference} output={output} outputObj={outputObj}></TabCell>
  ]

  return (
    <LayoutPage2V2 bread={words.bread} card={card} output={output}>
      <div className={scss.layout}>
      <ResultSidebar className={scss.frame_left}>
          <StyledTabs value={tabValue} style={{ padding:'20px'}}
            onChange={(e, value) => handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} index={index} label={item}/>
            ))}
          </StyledTabs>
        </ResultSidebar>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scss.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2V2>
  )
}

export default PeptideDFBPIDResult;