import React from 'react';
// import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

//---------------------------------------------------------------------------
const NoneButton = (props) => (
  <Button variant="text"
  sx={{
    display: 'flex',
    height: '33px',
    padding: '2px 8px',
    alignItems: 'center',
    marginRight: '8px',
    fontSize: '16px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    gap: '2px',
    borderRadius: '2px',
    border: '1px solid transparent',
    
    '&:hover': {
      backgroundColor: 'rgba(207, 255, 244, 1)',
      border: '2px solid rgba(100, 255, 218, 0.75)',
      boxShadow: '0px 0px 8px 0px rgba(100, 255, 218, 1), 0px 0px 24px 0px rgba(100, 255, 218, 0.08)',
    },
    boxShadow: 'none',
    textTransform: 'none',
    }}
    
    {...props}>
    {props.children}
  </Button>
)
export default NoneButton;
