import React from 'react'
// import { useState } from "react";

// import scss from './Purpose.module.scss';
import scss from './PurposeV2.module.scss';
import imgs from 'utils/imgs';
import Text from 'components/tag/Text';
import RefImg from 'components/tag/Img';

//---------------------------------------------------------------------------
function purposeData(index, heading, text) {
  return { index, heading, text };
}

const texts = {
  purpose: 'Purpose of the Database',
  what: 'What is this database?',

  head: [
    purposeData('01', 'Data Retrieval',
      'A comprehensive database for foodborne protein & peptide.'),
    purposeData('02', 'Discovery drug design & functional Food',
      'An integrated database of sequence, function, source, prediction, and other information.'),
    purposeData('03', 'Modeling Analysis',
      'A versatile database to look up nutritional and functional values of protein & peptide, as well as to be used as target product design.'),
  ],
}

const Img = (props) => (
  <RefImg className={scss.center_image} {...props} />
)

const TextHeading = (props) => {
  const item = props.item ? props.item : null
  const scssText = (item === texts.head[1]) ? scss.center_text2 : scss.center_text1
  return (
  <div className={scssText}>
    <div className={scss.text_frame}>
      <Text className='Title-Audiowide28px-Regular-Title'>{props.item ? props.item.heading : ''}</Text>
      <Text className='Contents-QuanticoCaption12px-Medium-Alpha_75'>{props.item ? props.item.text : ''}</Text>
    </div>
  </div>)
}
//---------------------------------------------------------------------------
export default function PurposeV2() {
  //styleName: Contents: Quantico/Body/16px: Medium;
  //styleName: Title: Audiowide/38px: Regular;
  
  return (
    <div className={scss.layout}>
      <div className={scss.frame_top}>
        <div className={scss.top_purpose}>
          <Text className='Contents-QuanticoBody16px-Medium-White_75'>{texts.purpose}</Text>
        </div>
        <div className={scss.top_what}>
          <Text className='Title-Audiowide38px-Regular-Title'>{texts.what}</Text>
        </div>
      </div>
      <div className={scss.frame_center}>
        <div className={scss.center_image}>
          <Img imgName={imgs.siteV2.home.rectangle1}></Img>
          <TextHeading item={texts.head[0]}></TextHeading>
        </div>
        <div className={scss.center_image}>
          <Img imgName={imgs.siteV2.home.rectangle2}></Img>
          <TextHeading item={texts.head[1]}></TextHeading>
        </div>
        <div className={scss.center_image}>
          <Img imgName={imgs.siteV2.home.rectangle3}></Img>
          <TextHeading item={texts.head[2]}></TextHeading>
        </div>
      </div>
    </div>
  );
};
