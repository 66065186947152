import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, TableHead, TableRow, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
// import scssTools from 'views/tool/Tools.module.scss';
import scss from './ToolHotSpotSearch.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TableRow2Body } from 'components/table/MuiTable';
import { snackInfo, snackType } from 'components/SnackBar';
import GreenButton from 'components/button/GreenButton';
import WhiteButton from 'components/button/WhiteButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import NoneButton from 'components/button/NoneButton';
import { TagText } from 'components/tag/Text';
import { getFullPath, isEmptyObjOrArray } from 'utils/general';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'CRS-Tool' button -> this page
// path: paths.spp.tool.css_tool,
// http://localhost:3000/tool_css
const texts = {
  bread: breadData.create('Tools', 'Cross Source Search Tool', 'CSS-Tool'),
  text: '',

  step_title: [
    'Step 01',
    'Step 02',
  ],

  step_text: [
    'Select protein source(s)\nMaximum 3 choices',
    'Select peptide type(s)',
  ],

  button: {
    reset: "Reset",
    search: "Search",
    checked: [
      'Check All',
      'Uncheck All',
    ],
  }
}

function indexData(start, end, label) {
  return { start, end, label }
}

function optionData(label, value) {
  return { label, value }
}

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

const MuiTableHead = (props) => (
  <TableHead className={props.className ? props.className : "bgc-dark-green"} >
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead className={props.className} sx={{ border: 0 }}>
    <TableRow sx={{ border: 0 }}>
      <StyledTableCell style={{ color: v.darkGreen, fontSize: '24px' }} sx={{ border: 0 }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiButton = (props) => (
  <NoneButton
    className={scss.button_checked}
    variant="contained"
    size="small"
    {...props}>
  {props.children}
  </NoneButton>
)

export function cleanCSSToolState() {
  cookie.setCookie(cookie.keys.tool.crsSelectSourceId, '')
  cookie.setCookie(cookie.keys.tool.crsSelectClsId, '')
  cookie.setCookie(cookie.keys.tool.crsSelectClsTable, '')
}

//---------------------------------------------------------------------------
const ToolCSSToolSearch = ({ setInfo }) => {
  const selSrcId = cookie.getCookie(cookie.keys.tool.crsSelectSourceId)
  const selClsId = cookie.getCookie(cookie.keys.tool.crsSelectClsId)
  const selClsTable = cookie.getCookie(cookie.keys.tool.crsSelectClsTable)
  // console.log('selSrcId', selSrcId);
  // console.log('selClsId', selClsId);
  // console.log('selClsTable', selClsTable);
  const selSrcIdArr = selSrcId ? selSrcId.split(',') : ''
  const selClsIdArr = selClsId ? selClsId.split(',') : ''
  const selClsTableArr = selClsTable ? selClsTable.split(',') : ''
  // console.log('selSrcIdArr:', selSrcIdArr, 'selClsIdArr:', selClsIdArr, 'selClsTableArr:', selClsTableArr);
  
  let location = useLocation()
  let bread = linkData('CSS-Tool', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)

  const colSize = 3
  const sppList = spp.tool.css_search_input
  // const [output, setOutput] = useState({})
  const [arrProteinSourceList, setProteinSourceList] = useState([])
  const [arrProteinClassifyList, setProteinClassifyList] = useState([])
  const [arrProteinClassifyIndex, setProteinClassifyIndex] = useState([])
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        // setOutput(result.data)
        let item = result.data[spp.common.output.items]
        // console.log('items', item);
        
        let source = item
        if( !isEmptyObjOrArray(item) ) {
          source = item.map((item) => ({...item, isCheck: false, isDisable: false}))
        }
        setProteinSourceList(source)
        // console.log('protein source', source);
        let classes = getOptions(source, sppList.protein_source.class, sppList.protein_source.class_id)
        setProteinClassifyList(classes)
        // console.log('classify', classes);

        let classIndex = []
        let className = ''
        let startIndex = 0
        let input = source
        input.forEach((obj, index) => {
          let name = obj[sppList.protein_source.class]
          // console.log('class name', name);
          if (className === '')
            className = name
          if (className !== name && className !== '') {
            // console.log('startIndex', startIndex, 'index', index);
            classIndex.push(indexData(startIndex, index - 1, className))
            className = name
            startIndex = index
          }

          if (className === name && (index - startIndex + 1) === colSize) {
            classIndex.push(indexData(startIndex, index, className))
            startIndex = index + 1
          }

          if (index === input.length - 1) {
            classIndex.push(indexData(startIndex, index, className))
          }
        })
        setProteinClassifyIndex(classIndex)
        // console.log('classIndex', classIndex);
        
        if( selSrcIdArr.length > 0 ) {
          selSrcIdArr.forEach((id) => {
            handleChangeSource(id, source)
            
            if( selSrcIdArr.length === (2+1) ) {
              setProteinSourceList(prevItems => prevItems.map((item) => {
                return { ...item, isDisable: !item.isCheck };
              }))
            }
          })
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function getOptions(items, db_label, db_value) {
    let tmp = []
    tmp = items.map(a => a[db_label]);
    let labels = [...new Set(tmp)];
    tmp = items.map(a => a[db_value]);
    let values = [...new Set(tmp)];
    let options = []
    labels.forEach((label, index) => (
      options.push(optionData(label, values[index]))
    ))
    return options
  }
  
  //---------------------------------------------------------------------------
  const [arrPeptideClass, setPeptideClass] = useState([])
  const [arrPeptideClassIndex, setPeptideClassIndex] = useState([])
  const [statePeptide, setStatePeptide] = useState(axiosState.init())
  const apiPeptideList = () => {
    const config = apiConfig.peptide.list() //8
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePeptide(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStatePeptide(axiosState.resultCode200())
        // console.log(result.data);
        
        // setOutput(result.data)
        let item = result.data[spp.common.output.items]
        // console.log('items', item);
        
        let input = item
        input.sort(compareProperty)
        let list = input.map((item, index) => {
          return {
            no: index+1,
            id: item[sppList.peptide_class.class_id], //"classification_id"
            tableName: item[sppList.peptide_class.class_col], //"classification_col"
            displayName: (item[sppList.peptide_class.class]).trimClassifyName(), //"classification"
            isCheck: false,
            isDisable: false,
        }})
        setPeptideClass(list)
        setPeptideIndex(list.length)
        
        if( selClsIdArr.length > 0 ) {
          selClsIdArr.forEach((id, index) => {
            handleChangeClass(id, selClsTableArr[index], list)
          })
        }
      }
    }).catch(err => {
      setStatePeptide(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function compareProperty(a, b) { //for object
    return a.classification.localeCompare(b.classification)
  }
  function setPeptideIndex(length) {
    if (length > 0) {
      let index = []
      for (const i of Array(Math.ceil(length / colSize)).keys())
        index.push(indexData(i * colSize, (i * colSize) + colSize - 1))
      setPeptideClassIndex(index)
      // console.log('index', index);
    }
  }
  //---------------------------------------------------------------------------
  // Step 01
  const handleChangeSource = (id, prevSource) => {
    let prevIsCheckSize = (prevSource.filter(item => item.isCheck)).length
    // console.log('src prevIsCheckSize', prevIsCheckSize);
    let bCheck
    setProteinSourceList(prevItems => prevItems.map((item) => {
        if(item[sppList.protein_source.source_id] === id) {
          bCheck = !item.isCheck
          return { ...item, isCheck: bCheck };
        }
        return item
      })
    )
    
    if( prevIsCheckSize >= (3 - 1) ) { //max 3
      setProteinSourceList(prevItems => prevItems.map((item) => {
        if(item[sppList.protein_source.source_id] !== id && item.isCheck !== true) {
          return { ...item, isDisable: bCheck };
        }
        return item
      }))
    }
  }
  
  const handleClickProteinOption = (value) => {
    switch (value) {
      /* case texts.button.checked[0]: //'Check All',
        setProteinSourceList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: true,
          isDisable: false
        })))
        break */
      case texts.button.checked[1]: //'Uncheck All',
        setProteinSourceList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: false,
          isDisable: false
        })))
        break
      default:
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 02
  const handleChangeClass = (id, tableName, prevPeptideClass) => {
    // console.log('id', id);
    // console.log('tableName', tableName);
    // console.log('prevPeptideClass', prevPeptideClass);
    let prevIsCheckSize = (prevPeptideClass.filter(item => item.isCheck)).map(item => item.tableName).length
    // console.log('cls prevIsCheckSize', prevIsCheckSize);
    let bCheck
    setPeptideClass(prevItems => prevItems.map((item) => {
        if(Number(item.id) === Number(id)) {
          bCheck = !item.isCheck
          return { ...item, isCheck: bCheck };
        }
        return item
      })
    )
    
    if(tableName === 'multifunctionalpeptides') {
      setPeptideClass(prevItems => prevItems.map((item) => {
        if (item.tableName !== 'multifunctionalpeptides')
          return { ...item, isDisable: bCheck };
        return item
      }))
    } else {
      if( prevIsCheckSize <= 1 ) {
        setPeptideClass(prevItems => prevItems.map((item) => {
          if (item.tableName === 'multifunctionalpeptides')
            return { ...item, isDisable: bCheck };
          return item
        }))
    }}
  }
  
  const handleClickPeptideOption = (value) => {
    switch (value) {
      case texts.button.checked[0]: //'Check All',
        setPeptideClass(prevItems => prevItems.map((item) => ({...item,
          isCheck: (item.tableName !== 'multifunctionalpeptides' ? true : false),
          isDisable: (item.tableName === 'multifunctionalpeptides' ? true : false)
        })))
        break
      case texts.button.checked[1]: //'Uncheck All',
        setPeptideClass(prevItems => prevItems.map((item) => ({...item, isCheck: false, isDisable: false})))
        break
      default:
    }
  }
  
  //---------------------------------------------------------------------------
  // Bottom
  const handleClickReset = () => {
    setProteinSourceList(prevItems => prevItems.map((item) => {
      return { ...item, isCheck: false, isDisable: false }
    }))
    setPeptideClass(prevItems => prevItems.map((item) => {
      return { ...item, isCheck: false, isDisable: false }
    }))
    
    cleanCSSToolState()
  }
  
  const handleClickSearch = () => {
    let src = (arrProteinSourceList.filter(item => item.isCheck))
    // console.log('ProteinSource', src);
    let cls = (arrPeptideClass.filter(item => item.isCheck))
    // console.log('peptideClass', cls);
    
    let srcLength = src.length
    let clsLength = cls.length
    if (srcLength > 0 && srcLength <= 3 && clsLength > 0) {
      let clsName = cls.map(item => item.displayName)
      let srcName = src.map(item => item[sppList.protein_source.source])
      // console.log('clsName', clsName);
      // console.log('srcName', srcName);
      let clsNameText = clsName.join(', ')
      let srcNameText = srcName.join(', ')
      cookie.setCookie(cookie.keys.tool.crsSelectCls, clsNameText)
      cookie.setCookie(cookie.keys.tool.crsSelectSource, srcNameText) //bread title
      // console.log('clsNameText:', clsNameText);
      // console.log('srcNameText:', srcNameText);
      
      let srcId = src.map(item => item[sppList.protein_source.source_id])
      let srcIdStr = srcId.toString()
      // console.log('sourceIdStr', srcIdStr);
      let clsTable = cls.map(item => item.tableName)
      let clsTableStr = clsTable.toString()
      // console.log('clsTableStr', clsTableStr);
      let clsId = cls.map(item => item.id)
      let clsIdStr = clsId.toString()
      // console.log('clsIdStr', clsIdStr);
      cookie.setCookie(cookie.keys.tool.crsSelectSourceId, srcIdStr)
      cookie.setCookie(cookie.keys.tool.crsSelectClsId, clsIdStr) //save state
      cookie.setCookie(cookie.keys.tool.crsSelectClsTable, clsTableStr)
      
      navigate(paths.spp.tool.css_tool_result(srcIdStr, clsTableStr))
    } else {
      if( srcLength > 3 )
        setInfo(snackInfo.openError("Maximum of 3 choices in step1"))
      else if( srcLength === 0 && clsLength !== 0)
        setInfo(snackInfo.openError("Please finish step1"))
      else if( srcLength !== 0 && clsLength === 0)
        setInfo(snackInfo.openError("Please finish step2"))
      else if( srcLength === 0 && clsLength === 0)
        setInfo(snackInfo.openError("Please finish step1 & step2"))
    }
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (axiosState.keepRest(state))
      apiList()
    if( axiosState.keepRest(statePeptide))
      apiPeptideList()
    // eslint-disable-next-line
  }, [])

  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.text}>
      
        {/* Step 01 */}
        <ToolSingleBlock stepText={texts.step_title[0]} title={texts.step_text[0]} mainBlockContent={
          state.isLoading ? <LoadingAnime />
          : arrProteinClassifyList.map((option) => (
              <MuiTableContainer key={option.value}>
                <MuiTableHeadCell key={option.value} className={'bgc-white'} colSpan={colSize}>{option.label}</MuiTableHeadCell>

                <TableBody key={option.label} sx={{ border: 0 }}>
                  {arrProteinClassifyIndex.map((value, count) => {
                    // console.log('value:', value, 'count:', count);
                    if (value.label === option.label) {
                      return (
                        <TableRow2Body key={`body-${count}`} style={{ border: 0 }} sx={{ border: 0 }}>
                          { // slice end position start index from 1
                            arrProteinSourceList.slice(value.start, value.end + 1).map((item, index) => {
                              // console.log('start:', value.start, 'index:', index);
                              let idx = value.start + index
                              return (
                                <StyledTableCell key={`cell-${count}-${index}`}
                                  sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                  <FormControlLabel
                                    key={`form-${count}-${index}`}
                                    style={{ whiteSpace: 'pre-line' }}
                                    control={
                                      <MuiCheckbox
                                        key={item.source_id}
                                        name={item.source}
                                        value={idx}
                                        checked={item.isCheck}
                                        disabled={item.isDisable}
                                        onChange={() => handleChangeSource(item.source_id, arrProteinSourceList)}
                                        inputProps={{ 'aria-label': 'controlled' }} />}
                                    label={`${(item.source).trimClassifyName()}`}
                                  />
                                </StyledTableCell>
                              )
                            })}
                        </TableRow2Body>
                      )
                    }
                    return null
                  })}
                </TableBody>
              </MuiTableContainer>
            ))
          }
          anotherBlockContent={
            <div className={scss.selectItem_button}>
              {/* <MuiButton //Check All
                onClick={() => handleClickProteinOption(texts.button.checked[0])}
              >{texts.button.checked[0]}</MuiButton> */}
              <MuiButton //Uncheck All
                onClick={() => handleClickProteinOption(texts.button.checked[1])}
              >{texts.button.checked[1]}</MuiButton>
            </div>
          }>
        </ToolSingleBlock>
        
        {/* Step 02 */}
        <ToolSingleBlock stepText={texts.step_title[1]} title={texts.step_text[1]} mainBlockContent={
          <MuiTableContainer>
            <TableBody sx={{ border: 0 }}>
              <TableRow2Body sx={{ border: 0 }}>
                <StyledTableCell sx={{ border: 0 }}>
                  <div className={scss.row_select} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {arrPeptideClassIndex.map((value, count) => {
                      return (
                        <TableRow2Body key={`body-${count}`} style={{ border: 0 }}>
                          {statePeptide.isLoading ? <LoadingAnime /> :
                            arrPeptideClass.slice(value.start, value.end + 1).map((item, index) => {
                              // console.log(item);
                              let idx = (value.start + index)
                              return (
                                <StyledTableCell key={`cell-${count}-${index}`}
                                  sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                  <FormControlLabel
                                    className={scss.checkbox_text}
                                    control={
                                      <MuiCheckbox //x γ-Glutamyl, Blood-brain barrier
                                        key={item.id}
                                        name={item.tableName}
                                        value={idx}
                                        checked={item.isCheck}
                                        disabled={item.isDisable}
                                        onChange={() => {handleChangeClass(item.id, item.tableName, arrPeptideClass)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />}
                                    label={<TagText html={`(${item.no}) ${item.displayName}`} />}/>
                                </StyledTableCell>
                              )
                            })}
                        </TableRow2Body>
                      )
                    })}
                  </div>
                </StyledTableCell>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer >
          }
          anotherBlockContent={
            <div className={scss.selectItem_button}>
              <MuiButton //Check All
                onClick={() => handleClickPeptideOption(texts.button.checked[0])}
              >{texts.button.checked[0]}</MuiButton>
              <MuiButton //Uncheck All
                onClick={() => handleClickPeptideOption(texts.button.checked[1])}
              >{texts.button.checked[1]}</MuiButton>
            </div>
          }>
        </ToolSingleBlock >

        <div className={scss.row_button}>
          <WhiteButton handleClick={handleClickReset} text={texts.button.reset} />
          <GreenButton handleClick={handleClickSearch} text={texts.button.search} />
        </div>
        
    </LayoutPageV2 >
  )
}
export default ToolCSSToolSearch