import React from 'react';
import scss from './GradientSidebar.module.scss';

const GradientSidebar = ({ children, onClick }) => {
  return (
    <div className={scss.sidebar_container} onClick={onClick}>
      <svg
        className={scss.sidebar_background}
        width="240"
        height="800"
        viewBox="0 0 240 800"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >

        <g clip-path="url(#clip0_44_2920)">
          <path
            d="M53.9618 795.927C54.7445 795.039 55.3389 794.363 56.2492 793.323H183.754C184.664 794.363 185.258 795.039 186.041 795.927H228.832L233.978 790.072V770.599L227.328 763.027V36.9826L227.516 36.7681L230.966 32.8371L233.978 29.4079V9.93464L228.832 4.07617H186.038L183.754 6.6803H56.2492L53.9647 4.07617H11.1704L6.02441 9.93464V29.4079L12.675 36.9793V763.014L9.08303 767.107L6.02441 770.589V790.062L11.1704 795.917L53.9618 795.927Z"
            fill="url(#paint0_linear_44_2920)"
          />
          <path
            d="M240.003 51.7857C238.13 49.6569 237.011 48.3795 235.269 46.3959V9.33076L229.369 2.60755H186.038L183.754 0.00341797H56.2492L53.9647 2.60755H10.6369L4.73433 9.32417V46.3893L0 51.7857V748.234L4.73433 753.624V790.689L10.6369 797.406H53.9647L56.2492 800.01H183.754L186.038 797.406H229.369L235.269 790.689V753.614L240.003 748.224V51.7857ZM6.02443 31.4939L11.3762 37.5934V762.394L8.16107 766.054L6.01573 768.5L6.02443 31.4939ZM233.978 790.082L228.832 795.937H186.041L183.754 793.333H56.2492C55.3389 794.369 54.7445 795.049 53.9618 795.937H11.1704L6.02443 790.082V770.609L9.08305 767.127L12.6751 763.034V36.9795L6.02443 29.408V9.93477L11.1704 4.07629H53.9647L56.2492 6.68043H183.754L186.038 4.07629H228.832L233.978 9.93477V29.408L230.966 32.8373L227.516 36.7682L227.328 36.9828V763.017L233.978 770.589V790.082ZM233.978 31.4939V768.513L228.618 762.41V37.5934L231.877 33.8802C232.596 33.0551 233.296 32.2564 233.978 31.484V31.4939Z"
            fill="url(#paint1_linear_44_2920)"
          />
        </g>

        <defs>
          <linearGradient id="paint0_linear_44_2920" x1="120.001" y1="4.07617" x2="120.001" y2="795.927" gradientUnits="userSpaceOnUse">
            <stop stop-color="#64FFDA" stop-opacity="0.08" />
            <stop offset="1" stop-color="#64FFDA" stop-opacity="0" />
          </linearGradient>

          <linearGradient id="paint1_linear_44_2920" x1="0" y1="400.007" x2="240.003" y2="400.007" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2BFFFF" stop-opacity="0" />
            <stop offset="0.5" stop-color="#2BFFFF" />
            <stop offset="1" stop-color="#2BFFFF" stop-opacity="0" />
          </linearGradient>
          <clipPath id="clip0_44_2920">
            <rect width="240" height="800" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div className={scss.sidebar_content}>
        {children}
      </div>
  </div>
);
};

export default GradientSidebar;


