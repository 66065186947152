import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, TableHead, TableRow, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolHotSpotSearch.module.scss';
// import scssTools from 'views/tool/Tools.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TableRow2Body } from 'components/table/MuiTable';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import GreenButton from 'components/button/GreenButton';
import WhiteButton from 'components/button/WhiteButton';
import StyledTextField from 'components/tool/StyledTextField';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { checkInvalidSeq, getFullPath, isEmptyObjOrArray, isOnlySpacesOrNewlines } from 'utils/general';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import NoneButton from 'components/button/NoneButton';
import Text, { TagText } from 'components/tag/Text';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import { cleanHotSpotState } from './ToolHotSpotSearch';
import { cleanCSSToolState } from './ToolCSSToolSearch';
import MuiSelect from 'components/mui/MuiSelect';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'HotSpot Search' button -> this page
// path: paths.spp.tool.hot_spot_search,
// http://localhost:3000/tool_hc
const texts = {
  bread: breadData.create('Tools', 'HotSpot & CSS-Tool', 'HotSpot & CSS'),

  text: 'HotSpot Search Tool is to find the position distribution of the peptides in different proteins.',
  searchHint: 'Please enter single letter sequences',
  required: 'Required',

  step_title: [
    'Step 01',
    'Step 01-2 (Optional)',
    'Step 02-1',
    'Step 02-2 (Optional)',
  ],

  step_text: [
    'Select protein source(s)\nMaximum 3 choices',
    'Select a single protein',
    'Enter single letter sequence',
    'Select peptide type(s)',
  ],

  select: {
    class: 'Classification:',
    source: 'Source:',
    protein: 'Protein name:',
  },

  button: {
    reset: "Reset",
    search: "Search",
    checked: [
      'Check All',
      'Uncheck All',
    ],
  }
}

function indexData(start, end, label) {
  return { start, end, label }
}

function optionData(label, value) {
  return { label, value }
}

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

const MuiTableHead = (props) => (
  <TableHead className={props.className ? props.className : "bgc-dark-green"} >
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead className={props.className} sx={{ border: 0 }}>
    <TableRow sx={{ border: 0 }}>
      <StyledTableCell style={{ color: v.darkGreen, fontSize: '24px' }} sx={{ border: 0 }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiButton = (props) => (
  <NoneButton
    className={scss.button_checked}
    variant="contained"
    size="small"
    {...props}>
  {props.children}
  </NoneButton>
)

/*
export function cleanHotSpotState() {
  cookie.setCookie(cookie.keys.tool.hotspotUserClass, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserSource, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserName, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserProtein, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, '')
}

export function cleanCSSToolState() {
  cookie.setCookie(cookie.keys.tool.crsSelectSourceId, '')
  cookie.setCookie(cookie.keys.tool.crsSelectClsId, '')
  cookie.setCookie(cookie.keys.tool.crsSelectClsTable, '')
}
*/

//---------------------------------------------------------------------------
const ToolHotSpotCSSToolSearch = ({ setInfo }) => {  
  const userProteinId = cookie.getCookie(cookie.keys.tool.hotspotUserProtein)
  const userProteinIdArr = userProteinId ? userProteinId.split(',') : ''
  
  const selSrcId = cookie.getCookie(cookie.keys.tool.crsSelectSourceId)
  const selClsId = cookie.getCookie(cookie.keys.tool.crsSelectClsId)
  const selClsTable = cookie.getCookie(cookie.keys.tool.crsSelectClsTable)
  // console.log('selSrcId', selSrcId);
  // console.log('selClsId', selClsId);
  // console.log('selClsTable', selClsTable);
  const selSrcIdArr = selSrcId ? selSrcId.split(',') : ''
  const selClsIdArr = selClsId ? selClsId.split(',') : ''
  const selClsTableArr = selClsTable ? selClsTable.split(',') : ''
  // console.log('selSrcIdArr:', selSrcIdArr, 'selClsIdArr:', selClsIdArr, 'selClsTableArr:', selClsTableArr);
  
  let location = useLocation()
  let bread = linkData('HotSpot & CSS', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  //---------------------------------------------------------------------------
  const selSrcMaxCount = 3
  const colSize = 3
  const sppList = spp.protein.list
  const sppCSSList = spp.tool.css_search_input
  const [outputList, setOutputList] = useState({})
  const [itemsList, setItemsList] = useState([])
  const [classifyList, setClassifyList] = useState([])
  const [classifyIndex, setClassifyIndex] = useState([])
  const [stateList, setStateList] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        // console.log(result.data);

        setOutputList(result.data)
        let item = result.data[spp.common.output.items]
        if( !isEmptyObjOrArray(item) )
          item = item.map((item) => ({...item, isCheck: false, isDisable: false}))
        setItemsList(item)
        // console.log('items', item);

        let classes = getOptions(item, sppList.items.class, sppList.items.class_id)
        setClassifyList(classes)
        // console.log('classify', classes);

        let classIndex = []
        // for (const i of Array(Math.ceil(item.length/6)).keys())
        //   index.push(indexData(i * 6, (i * 6) + 6))
        let className = ''
        let startIndex = 0
        item.forEach((obj, index) => {
          let name = obj[sppList.items.class]
          // console.log('class name', name);
          if (className === '')
            className = name
          if (className !== name && className !== '') {
            // console.log('startIndex', startIndex, 'index', index);
            classIndex.push(indexData(startIndex, index - 1, className))
            className = name
            startIndex = index
          }

          if (className === name && (index - startIndex + 1) === colSize) {
            classIndex.push(indexData(startIndex, index, className))
            startIndex = index + 1
          }

          if (index === item.length - 1)
            classIndex.push(indexData(startIndex, index, className))
        })

        setClassifyIndex(classIndex)
        // console.log('classIndex', classIndex);
        
        if( selSrcIdArr.length > 0 ) {
          selSrcIdArr.forEach((id) => {
            handleChangeSource(id, item)
            
            if( selSrcIdArr.length === (selSrcMaxCount) ) {
              setItemsList(prevItems => prevItems.map((item) => {
                return { ...item, isDisable: !item.isCheck };
              }))
            }
          })
        }
        
        // loadUserState(item)
        
        // if( userProteinIdArr.length > 0 ) {
        //   userProteinIdArr.forEach((id) => handleChangeSource(id, item))
        // }
        
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function getOptions(items, db_label, db_value) {
    let tmp = []
    tmp = items.map(a => a[db_label]);
    let labels = [...new Set(tmp)];
    tmp = items.map(a => a[db_value]);
    let values = [...new Set(tmp)];
    let options = []
    labels.forEach((label, index) => (
      options.push(optionData(label, values[index]))
    ))
    return options
  }
  
  //---------------------------------------------------------------------------
  const [isGetting, setIsGetting] = useState(false)
  const [proteinList, setProteinList] = useState([])
  const [proteinNameStartIdx, setProteinNameStartIdx] = useState(0)
  const [state, setState] = useState(axiosState.init())
  const jsonsHot = spp.tool.hot_spot
  const apiProteinName = () => {
    setIsGetting(false)
    let input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
    setInput(input)
    
    const config = apiConfig.tool.hotspot_protein_name(input) //38
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
      setInput(input)

      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let items = result.data[spp.common.output.items]
        let itemsFix = items.map((item) => ({...item,
          "protein_name": String(item['protein_name']).removeHTMLTags() }))
        // console.log('items', items, 'itemsFix', itemsFix);
        setProteinList(oldList => [...oldList, ...itemsFix]);
        setProteinNameStartIdx(result.data.end_index)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const sppCssList = spp.tool.css_search_input
  const [arrPeptideClass, setPeptideClass] = useState([])
  const [arrPeptideClassIndex, setPeptideClassIndex] = useState([])
  const [statePeptide, setStatePeptide] = useState(axiosState.init())
  const apiPeptideList = () => {
    const config = apiConfig.peptide.list() //8
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePeptide(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStatePeptide(axiosState.resultCode200())
        // console.log(result.data);
        
        // setOutput(result.data)
        let item = result.data[spp.common.output.items]
        // setItems(item)
        // console.log('items', item);
        
        let input = item
        input.sort(compareProperty)
        let list = input.map((item, index) => {
          return {
            no: index+1,
            id: item[sppCssList.peptide_class.class_id], //"classification_id"
            tableName: item[sppCssList.peptide_class.class_col], //"classification_col"
            displayName: (item[sppCssList.peptide_class.class]).trimClassifyName(), //"classification"
            isCheck: false,
            isDisable: false,
        }})
        setPeptideClass(list)
        setPeptideIndex(list.length)
                
        if( selClsIdArr.length > 0 ) {
          selClsIdArr.forEach((id, index) => {
            handleChangeClass(id, selClsTableArr[index], list)
          })
          setDisableKeyword(true)
        } else {
          setPeptideArea(userKeyword)
          setDisableKeyword(false)
        }
      }
    }).catch(err => {
      setStatePeptide(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function compareProperty(a, b) { //for object
    return a.classification.localeCompare(b.classification)
  }
  function setPeptideIndex(length) {
    if (length > 0) {
      let index = []
      for (const i of Array(Math.ceil(length / colSize)).keys())
        index.push(indexData(i * colSize, (i * colSize) + colSize - 1))
      setPeptideClassIndex(index)
      // console.log('index', index);
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 01-1
  const handleChangeSource = (id, prevSource) => {
    let prevIsCheckSize = (prevSource.filter(item => item.isCheck)).length
    // console.log('src prevIsCheckSize', prevIsCheckSize);
    let bCheck
    setItemsList(prevItems => prevItems.map((item) => {
        if(item.source_id === id) {
          bCheck = !item.isCheck
          return { ...item, isCheck: bCheck };
        }
        return item
      })
    )
    if( prevIsCheckSize >= (selSrcMaxCount - 1) ) { //max 3
      setItemsList(prevItems => prevItems.map((item) => {
        if(item.source_id !== id && item.isCheck !== true) {
          return { ...item, isDisable: bCheck };
        }
        return item
      }))
    }
    if (bCheck)
      setProteinId('')
  }
  
  const [isDisableUncheckBtn, setDisableUncheckBtn] = useState(false)
  const handleClickOption = (value) => {
    switch (value) {
      case texts.button.checked[0]: //'Check All',
        setItemsList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: true,
          isDisable: false,
        })))
        setProteinId('')
        setDisableUncheckBtn(false)
        break
      case texts.button.checked[1]: //'Uncheck All',
        setItemsList(prevItems => prevItems.map((item) => ({...item, 
          isCheck: false,
          isDisable: false,
        })))
        break
      default:
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 01-2 (Optional)
  const [classifyId, setClassifyId] = useState('')
  const handleChangeClassifyId = (value) => {
    setClassify(value, itemsList)
    setSourceId('')
    setProteinId('')
  }
  function setClassify(value, itemsList) {
    setClassifyId(value);
    // console.log('classify id:', value);
    let list = itemsList.filter(e => e[sppList.items.class_id] === value)
    let source = getOptions(list, sppList.items.source, sppList.items.source_id)
    setSourceList(source)
    // console.log('source list', list);
    // console.log('source option', source);
  }
  const [input, setInput] = useState({})
  const [sourceId, setSourceId] = useState('')
  const [sourceList, setSourceList] = useState([])
  const handleChangeSourceId = (value) => {
    // console.log('value', value, 'sourceId', sourceId);
    // console.log('source id:', value);
    setSourceId(value);
    setProteinId('')

    setProteinNameStartIdx(0)
    setProteinList([])

    if (value !== '') {
      setState(axiosState.init())
      setIsGetting(true)
    }
  }
  const [proteinId, setProteinId] = useState('')
  const [sppId, setSppId] = useState('')
  const handleChangeProteinId = (value) => {
    setProteinId(value);
    setSppId(value)
    // console.log('protein name:', value);
    
    let bDisable = (value !== '')
    setItemsList(prevItems => prevItems.map((item) => ({...item, 
        // isCheck: false,
        isDisable: bDisable,
      })))
    setDisableUncheckBtn(bDisable)
  }
  
  function loadUserState(itemsList) {
    let userClass = cookie.getCookie(cookie.keys.tool.hotspotUserClass)
    let userSource = cookie.getCookie(cookie.keys.tool.hotspotUserSource)
    let userName = cookie.getCookie(cookie.keys.tool.hotspotUserName)
    if( userClass !== undefined ) {
      setClassify(userClass, itemsList)
      
      if( userSource !== undefined ) {
        handleChangeSourceId(userSource)
        if( userName !== undefined ) {
          handleChangeProteinId(userName)
        }}
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 02-1
  let userKeyword = cookie.getCookie(cookie.keys.tool.hotspotUserKeyword)
  if( userKeyword === undefined )
    userKeyword = ''
  const [isDisableKeyword, setDisableKeyword] = useState(false)
  const [isSearchError, setSearchError] = useState(false)
  const [isSeqInputError, setIsSeqInputError] = useState(false)
  const [seqInputErrMsg, setSeqInputErrMsg] = useState("")
  const [keyword, setKeyword] = useState(userKeyword)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
    if (value === "") {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
    }
    setSearchError(false)
    
    setPeptideArea(value)
  }
  const setPeptideArea = (value) => {
    let bDisable = (value !== '')
    setPeptideClass(prevItems => prevItems.map((item) => ({...item, 
        isCheck: false,
        isDisable: bDisable,
      })))
    setDisablePeptideUncheckBtn(bDisable)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }
  
  //---------------------------------------------------------------------------
  // Step 02-2
  const [isDisablePeptideUncheckBtn, setDisablePeptideUncheckBtn] = useState(false)
  const handleChangeClass = (id, tableName, prevPeptideClass) => {
    // console.log('id', id);
    // console.log('tableName', tableName);
    // console.log('prevPeptideClass', prevPeptideClass);
    let prevIsCheckSize = (prevPeptideClass.filter(item => item.isCheck)).map(item => item.tableName).length
    // console.log('cls prevIsCheckSize', prevIsCheckSize);
    let bCheck
    setPeptideClass(prevItems => prevItems.map((item) => {
        if(Number(item.id) === Number(id)) {
          bCheck = !item.isCheck
          return { ...item, isCheck: bCheck };
        }
        return item
      })
    )
    
    if(tableName === 'multifunctionalpeptides') {
      setPeptideClass(prevItems => prevItems.map((item) => {
        if (item.tableName !== 'multifunctionalpeptides')
          return { ...item, isDisable: bCheck };
        return item
      }))
      setDisableKeyword(bCheck)
      if( bCheck )
        setKeyword('')
    } else {
      if( prevIsCheckSize <= 1 ) {
        setDisableKeyword(bCheck)
        setKeyword('')
        setPeptideClass(prevItems => prevItems.map((item) => {
          if (item.tableName === 'multifunctionalpeptides')
            return { ...item, isDisable: bCheck };
          return item
        }))}
    }
  }
  
  const handleClickPeptideOption = (value) => {
    switch (value) {
      case texts.button.checked[0]: //'Check All',
        setPeptideClass(prevItems => prevItems.map((item) => ({...item,
          isCheck: (item.tableName !== 'multifunctionalpeptides' ? true : false),
          isDisable: (item.tableName === 'multifunctionalpeptides' ? true : false)
        })))
        setDisablePeptideUncheckBtn(false)
        setDisableKeyword(true)
        setKeyword('')
        break
      case texts.button.checked[1]: //'Uncheck All',
        setPeptideClass(prevItems => prevItems.map((item) => ({...item, isCheck: false, isDisable: false})))
        setDisableKeyword(false)
        break
      default:
    }
  }

  //---------------------------------------------------------------------------
  // Bottom
  const handleClickReset = () => {
    setClassifyId('')
    setSourceId('')
    setProteinId('')
    setKeyword('')
    setDisableKeyword(false)
    
    setDisableUncheckBtn(false)
    setItemsList(prevItems => prevItems.map((item) => {
      return { ...item, isCheck: false, isDisable: false }
    }))
    cleanHotSpotState()
    
    setDisablePeptideUncheckBtn(false)
    setPeptideClass(prevItems => prevItems.map((item) => {
      return { ...item, isCheck: false, isDisable: false }
    }))
    cleanCSSToolState()
  }
  
  const handleClickSearch = () => {
    // Step 01
    let srcProtein = (itemsList.filter(item => item.isCheck))
    let srcLength = srcProtein.length
    // console.log('itemsList', itemsList);
    // console.log('proteinSource', src);
    // console.log('srcLength', srcLength);
    
    const isChoseProteinList1 = (srcLength > 0)
    const isChoseProteinId1 = (classifyId !== '' && sourceId !== '' && proteinId !== '')
    
    // Step 02
    let isChoseKeyword2 = false
    let isErrorKeyword = false
    if (keyword !== '') {
      const isInvalidCharObj = checkInvalidSeq(keyword);
      const onlySpaceNewlines = isOnlySpacesOrNewlines(keyword)
      if (isInvalidCharObj.isInvalid) {
        isErrorKeyword = true
        setSeqInputErrMsg(isInvalidCharObj.errMsg)
        setInfo(snackInfo.openError(isInvalidCharObj.errMsg))
      } else if (onlySpaceNewlines.isInvalid) {
        isErrorKeyword = true
        setSeqInputErrMsg(onlySpaceNewlines.errMsg)
        setInfo(snackInfo.openError(onlySpaceNewlines.errMsg))
      } else {
        isErrorKeyword = false
        setSeqInputErrMsg("")
      }
      setIsSeqInputError(isErrorKeyword)
      isChoseKeyword2 = !isErrorKeyword
    }
    
    let clsPeptide = (arrPeptideClass.filter(item => item.isCheck))
    let clsLength = clsPeptide.length
    // console.log('arrPeptideClass', arrPeptideClass);
    // console.log('peptideClass', clsPeptide);
    // console.log('clsLength', clsLength);
    
    const isChosePeptideList2 = (clsLength > 0)
    
    if (isChoseProteinList1 || isChoseProteinId1) {
      let srcId = srcProtein.map(item => item[sppCSSList.protein_source.source_id])
      let srcIdStr = srcId.toString()
      // console.log('sourceIdStr', srcIdStr);
      // console.log('proteinId', proteinId);
      let srcName = srcProtein.map(item => item[sppCSSList.protein_source.source])
      let srcNameText = srcName.join(', ')
      // console.log('srcName', srcName);
      cookie.setCookie(cookie.keys.tool.crsSelectSource, srcNameText) //bread title
      
      if( isChoseProteinList1 ) {
        cookie.setCookie(cookie.keys.tool.hotspotUserProtein, srcIdStr)
        cookie.setCookie(cookie.keys.tool.hotspotUserClass, '')
        cookie.setCookie(cookie.keys.tool.hotspotUserSource, '')
        cookie.setCookie(cookie.keys.tool.hotspotUserName, '')
      }
      else if( isChoseProteinId1 ) {
        cookie.setCookie(cookie.keys.tool.hotspotUserProtein, '')
        cookie.setCookie(cookie.keys.tool.hotspotUserClass, classifyId)
        cookie.setCookie(cookie.keys.tool.hotspotUserSource, sourceId)
        cookie.setCookie(cookie.keys.tool.hotspotUserName, proteinId)
      }
      
      if( isChoseKeyword2 ) {
        // console.log('keyword', keyword);
        
        cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, keyword)
        cookie.setCookie(cookie.keys.tool.crsSelectClsTable, '')
        cookie.setCookie(cookie.keys.tool.crsSelectClsId, '') //save state
        
        if( isChoseProteinList1 ) {
          cookie.setCookie(cookie.keys.tool.crsSelectSourceId, srcIdStr)
          navigate(paths.spp.tool.hotspot_css_tool_result({source: srcIdStr}, {keyword: keyword}))
        } else if( isChoseProteinId1 ) {
          // cookie.setCookie(cookie.keys.tool.crsSelectSourceId, '')
          navigate(paths.spp.tool.hotspot_css_tool_result({id: proteinId}, {keyword: keyword}))
        }
      } else if ( isChosePeptideList2 ) {
        let clsTable = clsPeptide.map(item => item.tableName)
        let clsTableStr = clsTable.toString()
        // console.log('clsTableStr', clsTableStr);
        let clsId = clsPeptide.map(item => item.id)
        let clsIdStr = clsId.toString()
        // console.log('clsIdStr', clsIdStr);
        
        cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, '')
        cookie.setCookie(cookie.keys.tool.crsSelectSourceId, srcIdStr)
        cookie.setCookie(cookie.keys.tool.crsSelectClsTable, clsTableStr)
        cookie.setCookie(cookie.keys.tool.crsSelectClsId, clsIdStr) //save state
        
        if( isChoseProteinList1 )
          navigate(paths.spp.tool.hotspot_css_tool_result({source: srcIdStr}, {cls: clsTableStr} ))
        else if( isChoseProteinId1 )
          navigate(paths.spp.tool.hotspot_css_tool_result({id: proteinId}, {cls: clsTableStr}))
      } else {
        setInfo(snackInfo.openError("Please finish step2"))
        setSearchError(true)
      }
    } else {
      if( isChoseKeyword2 || isChosePeptideList2 ) {
        setInfo(snackInfo.openError("Please finish step1"))
      } else {
        setInfo(snackInfo.openError("Please finish step1 & step2"))
      }
      setSearchError(true)
    }
  }

  //---------------------------------------------------------------------------
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (proteinNameStartIdx !== -1) {
        apiProteinName();
      }
    }
  };
  
  useEffect(() => {
    if (axiosState.keepRest(stateList))
      apiList()
    // if (axiosState.keepRest(state) && isGetting === true)
    //   apiProteinName()
    if( axiosState.keepRest(statePeptide))
      apiPeptideList()
    
    // eslint-disable-next-line
  }, [isGetting])
  
  const css = {
    text_select: 'b1-darkGreen',
  }
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.text}>
      
        {/* Step 01-1 */}
        <ToolSingleBlock stepText={texts.step_title[0]} title={texts.step_text[0]}
          mainBlockContent={
            stateList.isLoading ? <LoadingAnime />
              :
              classifyList.map((option) => (
                <MuiTableContainer key={option.value}>
                  <MuiTableHeadCell key={option.value} className={'bgc-white'} colSpan={colSize}>{option.label}</MuiTableHeadCell>

                  <TableBody key={option.label} sx={{ border: 0 }}>
                    {classifyIndex.map((value, count) => {
                      //console.log('value:', value, 'count:', count);
                      if (value.label === option.label) {
                        return (
                          <TableRow2Body key={`body-${count}`} style={{ border: 0 }} sx={{ border: 0 }}>
                            { // slice end position start index from 1
                              itemsList.slice(value.start, value.end + 1).map((item, index) => {
                                // console.log('start:', value.start, 'index:', index);
                                let idx = value.start + index
                                return (
                                  <StyledTableCell key={`cell-${count}-${index}`} sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                    <FormControlLabel
                                      key={`form-${count}-${index}`}
                                      style={{ whiteSpace: 'pre-line' }}
                                      control={
                                        <MuiCheckbox
                                          key={item.source_id}
                                          name={item.source}
                                          value={idx}
                                          checked={item.isCheck}
                                          disabled={item.isDisable}
                                          onChange={() => handleChangeSource(item.source_id, itemsList)}
                                          inputProps={{ 'aria-label': 'controlled' }} />}
                                      label={`${(item.source).trimClassifyName()}`}
                                    />
                                  </StyledTableCell>
                                )
                              })}
                          </TableRow2Body>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </MuiTableContainer>
              ))
          }
          anotherBlockContent={
            <div className={scss.selectItem_button}>
              {/* <NoneButton //Check All
                className={scss.button_checked}
                variant="contained"
                size="small"
                onClick={() => handleClickOption(texts.button.checked[0])}
              >{texts.button.checked[0]}</NoneButton> */}
              <NoneButton //Uncheck All
                className={scss.button_checked}
                variant="contained"
                size="small"
                disabled={isDisableUncheckBtn}
                onClick={() => handleClickOption(texts.button.checked[1])}
              >{texts.button.checked[1]}</NoneButton>
            </div>
            // texts.button.checked.map((item) => (
            //   selectOption(item)
            // ))
          }>
        </ToolSingleBlock>
        
        {/* Step 01-2 (Optional) */}
        {/* <ToolSingleBlock stepText={texts.step_title[1]} title={texts.step_text[1]} mainBlockContent={
          <MuiTableContainer>
            <TableBody sx={{ border: 0 }}>
              <TableRow2Body sx={{ border: 0 }}>
                <StyledTableCell sx={{ border: 0 }}>
                  <div className={scss.row_select} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className={scss.select_each_block} >
                      <Text className={css.text_select}>{texts.select.class}</Text>
                      <MuiSelect id="standard-select-class"
                        className={scss.select_class}
                        value={classifyId}
                        onChange={(event) => handleChangeClassifyId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                      >
                        {stateList.isLoading ? <LoadingAnime /> :
                          classifyList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                          ))}
                      </MuiSelect>
                    </div>

                    <div className={scss.select_each_block}>
                      <Text className={css.text_select}>{texts.select.source}</Text>
                      <MuiSelect id="standard-select-source"
                        className={scss.select_source}
                        value={sourceId}
                        onChange={(event) => handleChangeSourceId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                      >
                        {sourceList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </MuiSelect>
                    </div>

                    <div className={scss.select_each_block}>
                      <Text className={css.text_select}>{texts.select.protein}</Text>
                      {<MuiSelect
                        id="standard-select-protein"
                        className={scss.select_protein}
                        value={proteinId}
                        onChange={(event) => handleChangeProteinId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                        MenuProps={{
                          PaperProps: {
                            onScroll: handleScroll,
                          }
                        }}
                      >
                        {proteinList.map((item, index) => {
                          let protein_name = item[jsonsHot.items[0]]
                          let SPP_ID = item[jsonsHot.items[1]]
                          return <MenuItem key={index} value={SPP_ID}>
                            {protein_name.length > 100 ? <Tooltip title={protein_name}>
                              {protein_name}
                            </Tooltip> : protein_name}
                          </MenuItem>
                        })}
                      </MuiSelect>}
                    </div>
                  </div>
                </StyledTableCell>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer >}>
        </ToolSingleBlock > */}
        
        {/* Step 02-1 */}
        <ToolSingleBlock stepText={texts.step_title[2]} title={texts.step_text[2]} mainBlockContent={
          <div className={scss.third_textfield}>
            <StyledTextField
              style={{ borderRadius: '8px' }}
              placeholder={texts.searchHint}
              // label={texts.required}
              label={texts.searchHint}
              required
              value={keyword}
              disabled={isDisableKeyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              // inputProps={{ style: {borderColor: '#DFDFDF' } }}
              error={isSeqInputError}
              helperText={seqInputErrMsg}
            />
          </div>
        }>
        </ToolSingleBlock>
        
        {/* Step 02-2 (Optional) */}
        <ToolSingleBlock stepText={texts.step_title[3]} title={texts.step_text[3]} mainBlockContent={
          <MuiTableContainer>
            <TableBody sx={{ border: 0 }}>
              <TableRow2Body sx={{ border: 0 }}>
                <StyledTableCell sx={{ border: 0 }}>
                  <div className={scss.row_select} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {arrPeptideClassIndex.map((value, count) => {
                      return (
                        <TableRow2Body key={`body-${count}`} style={{ border: 0 }}>
                          {statePeptide.isLoading ? <LoadingAnime /> :
                            arrPeptideClass.slice(value.start, value.end + 1).map((item, index) => {
                              // console.log(item);
                              let idx = (value.start + index)
                              return (
                                <StyledTableCell key={`cell-${count}-${index}`}
                                  sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                  <FormControlLabel
                                    className={scss.checkbox_text}
                                    control={
                                      <MuiCheckbox //x γ-Glutamyl, Blood-brain barrier
                                        key={item.id}
                                        name={item.tableName}
                                        value={idx}
                                        checked={item.isCheck}
                                        disabled={item.isDisable}
                                        onChange={() => {handleChangeClass(item.id, item.tableName, arrPeptideClass)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />}
                                    label={<TagText html={`(${item.no}) ${item.displayName}`} />}/>
                                </StyledTableCell>
                              )
                            })}
                        </TableRow2Body>
                      )
                    })}
                  </div>
                </StyledTableCell>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer >
          }
          anotherBlockContent={
            <div className={scss.selectItem_button}>
              <MuiButton //Check All
                disabled={isDisablePeptideUncheckBtn}
                onClick={() => handleClickPeptideOption(texts.button.checked[0])}
              >{texts.button.checked[0]}</MuiButton>
              <MuiButton //Uncheck All
                disabled={isDisablePeptideUncheckBtn}
                onClick={() => handleClickPeptideOption(texts.button.checked[1])}
              >{texts.button.checked[1]}</MuiButton>
            </div>
          }>
        </ToolSingleBlock>

        <div className={scss.row_button}>
          <WhiteButton handleClick={handleClickReset} text={texts.button.reset} />
          <GreenButton handleClick={handleClickSearch} text={texts.button.search} />
        </div>
        
    </LayoutPageV2 >
  )
}
export default ToolHotSpotCSSToolSearch