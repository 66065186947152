import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import { snackInfo, snackType } from "components/SnackBar";
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';


//---------------------------------------------------------------------------
// Go in: homepage -> 'Peptide' tab -> 'Multifunctional Peptide' -> this page
// path: paths.spp.peptide.multi_search(),
const PeptideMultiSearch = ({ setInfo }) => {
  let location = useLocation()
  let bread = linkData('Multifunctional', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  let texts = {
    bread: breadData.create('Peptide', 'Multifunctional peptides', 'Multifunctional'),

    searchHint: 'Search by SPP ID, AA sequence, AA length, Function counts, Multifunctional activities',
    tableHeadCell: spp.peptide.search.multi.output_text,
    tableBodyCell: spp.peptide.search.multi.output,
  }

  const [peptideIdList, setPeptideIdList] = useState([])
  const [peptideClsNameList, setPeptideClsNameList] = useState([])
  const [peptideClsNameListStr, setPeptideClsNameListStr] = useState("")

  function truncateString(str, num) {
    return str.length > num ? str.slice(0, num) + "..." : str;
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const getPeptideList = (selectedMultiPeptideIdList) => {
    const config = apiConfig.peptide.list()
    // console.log(config);

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let list = result.data[spp.common.output.items]

        const selectedPeptideNameList = list.filter(item => selectedMultiPeptideIdList.includes(item.classification_id)).map(item => item.classification);


        setPeptideClsNameList(selectedPeptideNameList)
        let tmpString = truncateString(selectedPeptideNameList.toString(), 250)
        setPeptideClsNameListStr(tmpString)

      }
    }).catch(err => {
      console.error("List error:", err)
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {

    const selectedMultiPeptideList = cookie.getCookie(cookie.keys.peptide.selectedMultiPeptideList)
    getPeptideList(selectedMultiPeptideList)
    setPeptideIdList(selectedMultiPeptideList)

    return () => {
    };
  }, []);

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      desc={peptideIdList.length !== 0 ? `Multifunctional peptide analysis for ${peptideClsNameListStr}` : ''}
      tooltipOfDesc={`Multifunctional peptide analysis for ${peptideClsNameList}`}
      configSearch={apiConfig.peptide.multi_search} //7
      jsonInput={spp.peptide.search.multi.input}
      inputProp={peptideIdList.length !== 0 ? { class_list: peptideIdList } : ''}
      jsonInputKeyword={spp.peptide.search.multi.input_keyword}
    />
  )
}
export default PeptideMultiSearch