import React from 'react'

import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
// import LayoutPage from 'layouts/LayoutPage';
// import LayoutPage_2 from 'layouts/LayoutPage_2';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
const texts = {
  bread:  breadData.create('About us', 'About us'),
}

//---------------------------------------------------------------------------
const AboutUs = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
    </LayoutPageV2>
  )
}

export default AboutUs;