import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import { snackInfo, snackType } from "components/SnackBar";
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';


//---------------------------------------------------------------------------
// Go in: 'Peptide' page -> 'Counts' number(>0) -> this page
// path: paths.spp.peptide.classify_search,
const PeptideClassifySearch = ({ setInfo }) => {
  let settings = {
    tableHeadCell: spp.peptide.search.classify.output_text,
    tableHeadCell50: spp.peptide.search.classify.output_ic50_text,
    tableBodyCell: spp.peptide.search.classify.output,
    tableBodyCell50: spp.peptide.search.classify.output_ic50,
  }

  const [searchParams] = useSearchParams()
  const queryClassify = searchParams.get(paths.params.class)
  const queryCrossClassify = searchParams.get(paths.params.cross)

  const [displayName, setDisplayName] = useState("")
  const [crossDisplayName, setCrossDisplayName] = useState("")

  const isPeptideCross = () => {
    return queryCrossClassify !== null && queryCrossClassify !== "" && queryClassify !== null && queryClassify !== ""
  }

  const handleDisplayName = () => {
    const peptideStr = " peptides"

    if (isPeptideCross()) {
      const tmp1 = queryClassify.split(peptideStr)
      setDisplayName(tmp1[0])

      const tmp2 = queryCrossClassify.split(peptideStr)
      setCrossDisplayName(tmp2[0])
    }
  }

  // const classifyName = queryClassify
  // ACE-inhibitory peptides, Renin-inhibitory_peptides ...
  const [breadTitle, setBreadTitle] = useState("")

  const [list, setList] = useState([])
  const [classifyName, setClassifyName] = useState("")

  const [tableSelect, setTableSelect] = useState({})
  // let tableSelect = {}
  // switch (classifyName)
  // {
  //   case "ace_inhibitory_peptides": //no.1
  //   case "vasorelaxation_peptides": //no.27
  //   case "vasoconstriction_peptides": //no.28
  //     tableSelect.tableHeadCell = settings.tableHeadCell50
  //     tableSelect.tableBodyCell = settings.tableBodyCell50
  //     break
  //   default:
  //     tableSelect.tableHeadCell = settings.tableHeadCell
  //     tableSelect.tableBodyCell = settings.tableBodyCell
  // }
  // console.log('queryClassify', queryClassify);
  // console.log('classifyName', classifyName);
  // console.log('tableSelect', tableSelect);

  let location = useLocation()
  let breadText = breadTitle.trimClassifyName()
  let bread = linkData(breadText, getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  cookie.setCookie(cookie.keys.protein.bread4th, '')
  let texts = {
    bread: breadData.create('Peptide', breadTitle, breadText),

    searchHint: 'Search by SPP ID, AA sequence, Peptide name, AA length, Precursor protein, Pubdate',
    tableHeadCell: tableSelect.tableHeadCell,
    tableBodyCell: tableSelect.tableBodyCell,
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()  
  const apiList = () => {
    const config = apiConfig.peptide.list()
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let list = result.data[spp.common.output.items]
        // list.multi_count = data[30].counts
        setList(list)

        let selectedPeptideCls = [];
        if (isPeptideCross()) {
          selectedPeptideCls = list.filter(item => item.classification_col === queryCrossClassify)
        } else {
          selectedPeptideCls = list.filter(item => item.classification_col === queryClassify)
        }

        setBreadTitle(selectedPeptideCls[0].classification)

        if (selectedPeptideCls.length !== 0) {
          setClassifyName(selectedPeptideCls[0].classification_col)

          switch (selectedPeptideCls[0].classification_col) {
            case "ace_inhibitory_peptides": //no.1
            case "vasorelaxation_peptides": //no.3
            case "vasoconstriction_peptides": //no.5

              setTableSelect(
                {
                  tableHeadCell: settings.tableHeadCell50,
                  tableBodyCell: settings.tableBodyCell50
                }
              )
              break
            default:
              setTableSelect(
                {
                  tableHeadCell: settings.tableHeadCell,
                  tableBodyCell: settings.tableBodyCell
                }
              )
          }
        }
      }
    }).catch(err => {
      console.error("List error:", err)
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }


  useEffect(() => {
    apiList()
    handleDisplayName()
  }, [queryClassify, queryCrossClassify])

  return (

    classifyName !== "" &&
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={isPeptideCross() ? apiConfig.peptide.fun_relationship_cross_search : apiConfig.peptide.class_search} //9
      jsonInput={isPeptideCross() ? spp.peptide.search.cross_classify.input : spp.peptide.search.classify.input}
      inputProp={isPeptideCross() ? [displayName, crossDisplayName] : [classifyName]}
      jsonInputKeyword={isPeptideCross() ? spp.peptide.search.cross_classify.input_keyword : spp.peptide.search.classify.input_keyword}
    />
  )
}
export default PeptideClassifySearch