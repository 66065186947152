import React from 'react'
import CountUp from 'react-countup';

// import scss from './Summary.module.scss';
import scss from './TotalV2.module.scss';
import imgs from 'utils/imgs';
import Img from 'components/tag/Img';

//---------------------------------------------------------------------------
// const texts = {
// }

function countData(number, text) {
  return { number, text };
}

const Card = (props) => (
  <div className={scss.card} {...props}>
    <span className={scss.card_number} style={{ whiteSpace: 'pre-line' }}>
      <CountUp
        start={0}
        end={(props.item ? Number(props.item.number) : 0)}
        duration={1}
      />
    </span>
    <span className={scss.card_text} style={{ whiteSpace: 'pre-line' }}>
      {(props.item ? props.item.text : '')}
    </span>
  </div>
)

//---------------------------------------------------------------------------
export default  function TotalV2(props) {
  const {output} = props
  
  if( output === undefined || output === null || output.length === 0 ) { //jsons.site.home_number.output
    output["peptide_classification"] = 31
    output["peptide_sequence"] = 40652
    output["peptide_entries"] = 6983
    output["peptide_attributes"] = 30
    output["multifunctional_peptides"] = 763
    
    output["food_borne_proteins"] = 70372
    output["literatures"] = 1065
    output["application_tools"] = 6
    output["sequence_features"] = 6
  }
  const words = {
    items: [
      countData(output["peptide_classification"], 'Peptide Classification'),
      countData(output["peptide_sequence"], 'Peptide Sequences'),
      countData(output["peptide_entries"], 'Peptide Entries'),
      countData(output["peptide_attributes"], 'Peptide Attributes'),
      countData(output["multifunctional_peptides"], 'Multifunctional Peptides'),

      countData(output["food_borne_proteins"], 'Food-borne Proteins'),
      countData(output["literatures"], 'Literatures'),
      countData(output["application_tools"], 'Application Tools'),
      countData(output["sequence_features"], 'Sequence Features'),
    ],
  }
  
  return (
    <div className={scss.layout}>
      {words.items.map((item) => (
        <Card item={item} key={item.text}></Card>
      ))}
    </div>
  );
};
