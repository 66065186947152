import { Button } from "@mui/material";

import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------
// const GreenButton = (props) => {
//   return <Button
//     className={scss.primary16_button}
//     onClick={props.handleClick}
//     sx={{
//       boxShadow: "none",
//       textTransform: "none",
//     }}
// >
//   {props.text}
// </Button>
// }


// export default GreenButton;

// components/button/GreenButton.jsx


const GreenButton = (props) => {
  const { text, handleClick, children, className, ...rest } = props;
  
  return (
    <Button
      className={`${scss.primary16_button} ${className || ''}`}
      onClick={handleClick}
      sx={{
        boxShadow: "none",
        textTransform: "none",
      }}
      {...rest}
    >
      {text || children}
    </Button>
  );
};

export default GreenButton;