/* 
Bar | react-chartjs-2
https://react-chartjs-2.js.org/components/bar
https://react-chartjs-2.js.org/examples/stacked-bar-chart
Bar Chart | Chart.js
https://www.chartjs.org/docs/latest/charts/bar.html
 */
import { Box, Slider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import Chart, { scales } from 'chart.js/auto'

// import v from 'assets/scss/_variables.scss';
// import "assets/scss/common.scss";
import v from 'assets/scss/_variablesV2.scss';

import Text from 'components/tag/Text';
import { spp } from 'utils/network/jsons';

//---------------------------------------------------------------------------
/* 
// Append '4d' to the colors (alpha channel), except for the hovered index
function handleHover(evt, item, legend) {
    // legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => { //default location
    legend.chart.options.backgroundColor.forEach((color, index, colors) => {
        // console.log('color.length', color.length); //#000000 length=7, #0000004D length=9
        colors[index] = index === item.index || color.length === (7+2) ? color : color + '4D';
    });
    legend.chart.update();
}

// Removes the alpha channel from background colors
function handleLeave(evt, item, legend) {
    // legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => { //default location
    legend.chart.options.backgroundColor.forEach((color, index, colors) => {
        colors[index] = color.length === (7+2) ? color.slice(0, -2) : color;
    });
    legend.chart.update();
}
 */

Chart.register(annotationPlugin);
//---------------------------------------------------------------------------
const BarChart = ({ data, options,
  showTableLabel = false, indexAxis = 'x', hasXRangeSlider = false,
  yAxisLabel, needHoverStyle = false,
  showAvgLine = false,
  tooltipCRSresult = undefined,
  ...props }) => {
  console.log("00option",options)
  console.log("01data",data)  
  console.log("02yAxisLabel",yAxisLabel)  

  const chartRef = useRef(null);

  if (data === undefined) {
    data.labels = []
    data.datasets = []
  }

//TODO:
  const yAxisTitlePlugin = {
    id: 'yAxisTitlePlugin',
    afterDraw(chart, args, options) {
      const ctx = chart.ctx;
      const yScale = chart.scales['y'];
      const x = yScale.left - 10;
      const y = yScale.top - 10 - 10;

      ctx.save();
      ctx.font = '14px';
      ctx.textAlign = 'left';
      ctx.fillStyle = 'white';
      ctx.fillText(yAxisLabel ?? "", x, y);
      ctx.restore();
    },
  };
  
  let newData = data;
  let dataSet = []

  // Data Slider - start
  if (hasXRangeSlider) {
    dataSet = data.datasets[0].data;
  }

  const [value, setValue] = useState([0, dataSet.length]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const filteredLabels = data.labels.slice(value[0], value[1]);
  const filteredData = data.datasets.map(dataset => {
    // console.log('dataset.data', dataset.data);
    return {
    ...dataset,
    data: dataset.data.slice(value[0], value[1]),
  }});

  if (hasXRangeSlider) {
    newData = {
      ...data,
      labels: filteredLabels,
      datasets: filteredData,
    };
  }

  useEffect(() => {
    if (hasXRangeSlider) {
      setValue([0, data.datasets[0].data.length]);
    }
    return () => {
    };
  }, [data]);
  // Data Slider - end

  let _options = {
    indexAxis: indexAxis,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    barPercentage: 0.4,
    // animation: {
    //   duration: 2000, // general animation time
    //   easing: 'easeOutBounce', // easing function to use
    //   from: 0, // start value based on scale
    //   to: 1, // end value based on scale
    // },
    plugins: {
      legend: {
        display: showTableLabel, //hide labels
        // position: 'top', //top, bottom, left, right
        // align: 'center', //start, center, end
        // onHover: handleHover,
        // onLeave: handleLeave,
        // onClick: handleClick,
      },
      /* title: {
          display: true,
          // align: 'center', //start, center, end
          text: chartData.title,
      },
      /* subtitle: {
          display: true,
          text: chartData.text,
          color: 'blue',
          font: {
              size: 12,
              family: 'tahoma',
              weight: 'normal',
              // style: 'italic'
          },
          padding: {
              bottom: 10
          }
      }, */
    },
    /* borderWidth: 1,
    borderColor: [
        '#0061A2',
    ], */
    backgroundColor: [
      '#3398db',
      //'#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'
    ],
  }
  if (options !== undefined)
    Object.assign(_options, options)
  
  /*
  Tooltip | Chart.js
  https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
   */
  // console.log('tooltipCRSresult', tooltipCRSresult);
  const jsonsChart = spp.tool.css_tool_result_lv1_bar_chart
  const tooltipCRS = (context) => {
    // console.log(context);
    let label = context.dataset.label || '';

    if (label) {
      label += ': ';
      label += context.formattedValue
    }
    if (context.parsed.y !== null) {
      // label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
      
      let idx = context.dataIndex
      // console.log('idx', idx);
      let text = '=';
      if( tooltipCRSresult !== undefined && Array.isArray(tooltipCRSresult) ) {
        let item = tooltipCRSresult[idx]
        let count = item[jsonsChart.items[3]] //"count_list",
        // let list = item[jsonsChart.items[4]] //"label_list",
        count.forEach((item, index) => {
          text += `${item}`;
          if(index !== count.length -1)
            text += '+'
        });
        label += text
      }
    }
    return label;
  };
  /* const footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(function(tooltipItem) {
      sum += tooltipItem.parsed.y;
    });
    return 'Sum: ' + sum;
  }; */
  if( tooltipCRSresult !== undefined ) {
    let plugins = {
      tooltip: {
        callbacks: {
          title: function() {
            return 'Total'
          },
          label: tooltipCRS,
          // footer: footer,
        }},
    }
    Object.assign(_options.plugins, plugins)
  }
  
  
  /* 
  Average | chartjs-plugin-annotation
  https://www.chartjs.org/chartjs-plugin-annotation/3.0.1/samples/line/average.html
   */
  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }
  const annotationAvg = {
    type: 'line',
    borderColor: 'black',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 1,
    label: {
      display: true,
      content: (ctx) => 'Average: ' + average(ctx).toFixed(2),
      position: 'end',
    },
    font: {
      size: 13
    },
    scaleID: 'y',
    value: (ctx) => average(ctx)
  };
  if( showAvgLine ) {
    let plugins = {
       annotation: {
        annotations: {
          annotationAvg
        }},
    }
    Object.assign(_options.plugins, plugins)
  }

  const handleMouseMove = (event) => {
    if (chartRef && chartRef.current) {
      const canvas = chartRef.current.canvas;
      canvas.style.cursor = 'pointer';
    }
  };


  return (
    <div>
      <Text center><h2>{data.title}</h2></Text>
      <Text center style={{ color: 'gray' }}><h4>{data.text}</h4></Text>
      <Bar
        data={newData}
        options={_options}
        plugins={[yAxisTitlePlugin]}
        style={{ marginLeft: hasXRangeSlider && "50px", marginRight: hasXRangeSlider && "50px" }}
        onMouseMove={needHoverStyle ? handleMouseMove : null}
        ref={chartRef}
        {...props} />

      <div style={{ display: "flex", pr: 5, pl: 5, justifyContent: "center" }}>
        {hasXRangeSlider &&
          <Box sx={{ width: "100%", display: "flex", pr: 5, pl: 5, pb: 4, justifyContent: "center" }}>
            <Slider
              value={value}
              onChange={handleChange}
              min={0}
              max={dataSet.length}
              valueLabelDisplay="auto"
            />
          </Box>}
      </div>


    </div>
  )
}
export default BarChart