// import { useState } from "react";

import scss2 from 'layouts/LayoutPageV2.module.scss'
import BreadBarV2 from 'components/bread/BreadBarV2';

//---------------------------------------------------------------------------
export default function LayoutPageV2({ children, ...props }) {
  // console.log('Layout2 props', props);
  // console.log('Layout2', props.bread);

  return (
    <div className={scss2.layout}>
      <BreadBarV2 bread={props.bread}
        searchInput={props.searchInput} jsonInput={props.jsonInput}
        searchOutput={props.searchOutput} desc={props.desc} tooltipOfDesc={props.tooltipOfDesc}
      />
      <main className={scss2.frame_bottom}>
        {children}
      </main>
    </div>
  )
}
