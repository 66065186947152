// -----official tools & third tools-------------------------------------------------------
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// -----custom tools-------------------------------------------------------
import v2 from 'assets/scss/_variablesV2.scss';
import scss from './SearchV2.module.scss';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import TextList, { listDate } from 'components/TextList';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import NoneButton from 'components/button/NoneButton';
import { filters, setFocus, types } from './Search';
import { StyledTab } from './SearchV2';
import NoneTextField from 'components/NoneTextField';

//---------------------------------------------------------------------------
const bExampleOnValue = true
const texts = {
  tabs: [
    'Peptide',
    'Protein',
  ],
  search: 'Search',
}
//---------------------------------------------------------------------------
export default function SearchBarV2(props) {
  const { setInfo } = props
  // -----variables-------------------------------------------------------
  const cookieTabName = cookie.getCookie(cookie.keys.quickSearch.tabName)
  const cookieTypeIndex = cookie.getCookie(cookie.keys.quickSearch.typeIndex)
  const cookieTypeName = cookie.getCookie(cookie.keys.quickSearch.typeName)

  const initTabName = (cookieTabName === '' ? texts.tabs[0] : cookieTabName)
  const initTabOption = getTypeOption(initTabName)
  const initTypeIndex = (cookieTypeIndex === '' ? 0 : cookieTypeIndex)

  const initTypeName = (cookieTypeName === '' ? types.peptide[0].label : cookieTypeName)
  const initSearch = ''

  const [tabValue, setTab] = useState(initTabName); //tab name
  const [tabOption, setTabOption] = useState(getTabOption(texts.tabs)); //tab name
  const [tabIndex, setTabIndex] = useState(0); //index of type

  const [typeOption, setTypeOption] = useState(initTabOption);
  const [typeIndex, setTypeIndex] = useState(initTypeIndex); //index of type
  const [typeValue, setType] = useState(initTypeName); //type name
  const [searchValue, setSearch] = useState(initSearch); //search keyword
  const [isExampleValue, setIsExampleValue] = useState(true)//search keyword is example
  const searchRef = useRef(null)
  
  const [state, setState] = useState(axiosState.init())
  const [multiPeptideObj, setMultiPeptideObj] = useState(snackInfo.init())

  const [proteinClsObj, setProteinClsObj] = useState({})
  const navigate = useNavigate()
  let location = useLocation()

  const [isHovered, setIsHovered] = useState(false);

  // -----functions-------------------------------------------------------
  function getTypeOption(tabName) {
    if (tabName === texts.tabs[0]) //Peptide
      return types.peptide
    else if (tabName === texts.tabs[1]) //Protein
      return types.protein
  }

  function getTabOption(tabName) {
    const newArray = [];
    tabName.forEach((item, index) => {
      const newObj = {
        label: item
      };
      newArray.push(newObj);
    })
    return newArray
  }
  const handleChangeTab = (event, newValue) => {
    // console.debug(newValue)
    setTab(newValue)

    let type = ''
    if (newValue === texts.tabs[0]) //Peptide
    {
      type = types.peptide[0].label
      setTypeOption(types.peptide)
      setTypeIndex(0)
      setType(type)

    }
    else if (newValue === texts.tabs[1]) //Protein
    {
      type = types.protein[0].label
      setTypeOption(types.protein)
      setTypeIndex(0)
      setType(type)
    }
    cookie.setCookie(cookie.keys.quickSearch.tabName, newValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, 0)
    cookie.setCookie(cookie.keys.quickSearch.typeName, type)
  };

  const handleChangeType = (event) => {
    // console.debug(event.target.value)
    let newValue = event.target.value
    // console.debug(newValue)
    setType(newValue)

    // console.debug(tabValue)
    var type = undefined;
    type = getTypeOption(tabValue)

    if (type !== undefined) {
      setTypeOption(type)

      let index = type.findIndex(object => { return object.label === newValue; })
      if (index !== -1) {
        setTypeIndex(index)
        if(isExampleValue) {
          setSearch(type[index].init)
          setSearchDisabled(false)
        }
      }
      else {
        setSearch('')
        setSearchDisabled(true)
      }
      setFocus(searchRef)
    }
  }

  const [searchDisabled, setSearchDisabled] = useState(true)
  const handleChangeKeyword = (event) => {
    setSearch(event.target.value);
    // console.log(event.target.value);
    
    const disabled = event.target.value ? false : true
    setSearchDisabled(disabled)
    // console.log(disabled);
  }
  const handleKeyDown = (e) => {
    if( e.key === 'Enter')
      handleClickSearch()
    else
      setIsExampleValue(false)
  }

  const handleClickSearch = () => {
    // console.log('tabValue:', tabValue, 'typeValue:', typeValue);
    cookie.setCookie(cookie.keys.quickSearch.tabName, tabValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, typeIndex)
    cookie.setCookie(cookie.keys.quickSearch.typeName, typeValue)
    cookie.setCookie(cookie.keys.quickSearch.filterValue, searchValue)

    if (searchValue !== "") {

      if (tabValue === texts.tabs[0]) //Peptide
      {
        let filterValue = filters.peptide[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.peptide.quick_search())
      }
      else if (tabValue === texts.tabs[1]) //Protein
      {
        let filterValue = filters.protein[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.protein.quick_search())
      }
    } else {

      setInfo(snackInfo.openError("Search field cannot be empty"))
    }
  }

  const apiList = () => {
    const config = apiConfig.peptide.listWithDefaultToken()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        const multiPeptideData = data.filter(item => item.classification_col === "multifunctionalpeptides")

        setMultiPeptideObj(multiPeptideData[0])

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const getProteinClsList = () => {
    const config = apiConfig.protein.list_class_default_token()
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        setProteinClsObj(data)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //-----------------------------------------------------------------------
  useEffect(() => {
    apiList()
    getProteinClsList()
  }, []);
  // -----render-------------------------------------------------------
  //styleName: Contents: Quantico/Caption/12px: Medium;
  const css = {
    text_option: 'Contents-QuanticoCaption12px-Medium-Alpha_80 link',
  }
  
  //styleName: Title: Audiowide/38px: Regular;
  //styleName: Contents: Quantico/Body/16px: Medium;
  return (
    <div className={scss.search_input}>
      <div className={scss.input_center}>
        <Tabs value={tabValue} onChange={handleChangeTab} TabIndicatorProps={{ hidden: true }}
          className={scss.tabs}>
          <StyledTab value={texts.tabs[0]} className={scss.tab_left} />
          <StyledTab value={texts.tabs[1]} className={scss.tab_right} />
        </Tabs>
        <div className={scss.center_type}>
          <TextList option={typeOption} index={typeIndex} value={typeValue} className={scss.search_text}
            onChange={handleChangeType}></TextList>
        </div>
        <div className={scss.center_word}>
          {bExampleOnValue
          ? (<NoneTextField
              type="search"
              inputRef={searchRef}
              value={searchValue} placeholder={texts.search}
              onChange={handleChangeKeyword}
              onKeyDown={handleKeyDown}
            />)
          : (<NoneTextField
              type="search"
              inputRef={searchRef}
              placeholder={searchValue}
              onChange={handleChangeKeyword}
              onKeyDown={handleKeyDown}
            />)
          }
          <IconButton aria-label="search" disabled={searchDisabled}
            className={scss.button_search} onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
    </div >
  );
};
