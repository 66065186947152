import { Box, Button, Checkbox, FormControlLabel, IconButton, Input, MenuItem, Radio, Select, TableHead, TableRow, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
// import scssTools from 'views/tool/Tools.module.scss';
import scss from './ToolPDCAAS.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import GreenButton from 'components/button/GreenButton';
import WhiteButton from 'components/button/WhiteButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import NoneButton from 'components/button/NoneButton';
import Text, { TagText } from 'components/tag/Text';
import { getFullPath, isEmptyObjOrArray } from 'utils/general';
import { RouterLink } from 'components/router/RouterLink';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import MuiSelect from 'components/mui/MuiSelect';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'PDCAAS' button -> this page
// path: paths.spp.tool.pdcaas
// http://localhost:3000/tool_pdcaas
export const methodAll = 0
export const methods = [
  methodData('PDCAAS', 1),
  methodData('DIAAS', 2),
]
function methodData(label, method, isCheck = true, isDisable = false) {
  return { label, method, isCheck, isDisable }
}

const texts = {
  bread: breadData.create('Tools', 'PDCAAS', 'PDCAAS'),
  text: '',

  step_title: [
    'Step 01',
    'Step 02',
  ],

  step_text: [
    'Select organism(s)',
    'Please choose one or two scoring methods',
  ],
  
  step_list: 'Organism List',
  
  step1: {
    select: function(no) {
      return `Organism ${no}:`
    },
    weight: 'Weight:',
    weightHint: 'unit = g',
  },
  
  step2: {
    methods: methods,
  },
  
  table: {
    title: [
      ' ',
      '#',
      'Organism',
      'Weight(g)',
      'Weight(%)',
    ],
  },

  button: {
    reset: "Reset",
    search: "Search",
  },
}

class keywordErrorData {
  static init() {
    return {
      isError: true,
      msg: '',
  }}
  static error( msg ) {
    return {
      isError: true,
      msg: msg,
  }}
  static ok() {
    return {
      isError: false,
      msg: '',
  }}
}

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

const MuiTableHead = (props) => (
  <TableHead className={props.className ? props.className : "bgc-dark-green"} >
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead className={props.className} sx={{ border: 0 }}>
    <TableRow sx={{ border: 0 }}>
      <StyledTableCell style={{ color: v.darkGreen, fontSize: '24px' }} sx={{ border: 0 }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiButton = (props) => (
  <NoneButton
    className={scss["button-checked"]}
    variant="contained"
    size="small"
    {...props}>
  {props.children}
  </NoneButton>
)

export function cleanPDCAASState() {
  cookie.setCookie(cookie.keys.tool.pdcaasSelectOrganism, '')
  cookie.setCookie(cookie.keys.tool.pdcaasSelectWeight, '')
  cookie.setCookie(cookie.keys.tool.pdcaasSelectMethod, '')
}

//---------------------------------------------------------------------------
const ToolPDCAAS = ({ setInfo }) => {
  let location = useLocation()
  let bread = linkData('PDCAAS', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  const sppList = spp.tool.pdcaas_list
  // const [output, setOutput] = useState({})
  const [items, setItems] = useState([])
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiList = () => {
    const config = apiConfig.tool.pdcaas_list() //98
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        // setOutput(result.data)
        let item = result.data[spp.common.output.items]
        setItems(item)
        // console.log('items', item);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //---------------------------------------------------------------------------
  // Step 01
  class oData {
    static create(organismNum, organism,
      weight, weightPercent, weightDisable, weightError) {
      return {organismNum, organism
      , weight, weightPercent, weightDisable, weightError}
    }
    static init() {
      return this.create(0, '', '', 0, true, keywordErrorData.init())
    }
  }
  
  const oMaxCount = 7
  const oMinCount = 1
  const [organisms, setOrganisms] = useState(initOrganisms(oMaxCount))
  const [oDisplayCount, setODisplayCount] = useState(oMinCount)
  function initOrganisms(count) {
    let arr = []
    for (const i of Array(count).keys()) {
      arr.push(oData.init())
    }
    // console.log('organisms', arr);
    return arr
  }
  
  const [oAddDisable, setAddDisable] = useState(true)
  const rowChildren = (item, index) => {
    // console.log(index, item);
    return (
      <TableRow2Body key={index}
        sx={{ '&:nth-of-type(odd)': {
          backgroundColor: v.grey25,
        }, }}>
        <StyledTableCell key={index+'Cell0'}>
          {index > 0
          ? <IconButton className={scss.delete_button}
              onClick={() => handleClickDelete(index)}><RemoveIcon fontSize="small"/></IconButton>
          : null }
        </StyledTableCell>
        
        <StyledTableCell key={index+'Cell1'}>
          <Text key={index+'Text1'} className='b1-darkGreen'>{index+1}</Text>
        </StyledTableCell>
        
        <StyledTableCell key={index+'Cell2'}>
          <MuiSelect key={index+'MuiSelect'}
            id="standard-select-class"
            className={scss.select_organism}
            value={item.organismNum}
            // disabled={item.isDisable}
            onChange={(event) => handleChangeOrganism(event.target.value, index)}
          >
            {state.isLoading ? <LoadingAnime /> :
              organismMenu(index)
            }
          </MuiSelect>
        </StyledTableCell>
        
        <StyledTableCell key={index+'Cell3'}>
          <Input key={index+'Input'}
            className='b2-grey100'
            sx={{ m: 0, flex: 1 }}
            placeholder={texts.step1.weightHint}
            value={item.weight}
            disabled={item.weightDisable}
            error={item.weightError.isError}
            onChange={(event) => handleChangeWeight(event.target.value, index)}
          />
        </StyledTableCell>
        
        <StyledTableCell key={index+'Cell3'} align='right'>
          <Text key={index+'Text1'} className='b1-darkGreen'>
            {`${item.weightPercent}%`}</Text>
        </StyledTableCell>
      </TableRow2Body>)
  }
  
  const organismMenu = (index) => {   
    // console.log('index', index);
    let oNum = organisms.map(item => item.organismNum)
    let oNumSplice = [...oNum] //hide items
    oNumSplice[index] = 0 //show self item
    // console.log('oNum', oNum);
    // console.log('oNumS', oNumSplice);
    
    return items.map((option2, index2) => {
      // console.log('num', option2.num);
      if( !oNumSplice.includes(option2.num) )
        return (
          <MenuItem key={index2+'MenuItem'} value={option2.num}>
            {option2.Description}</MenuItem>)
      
      return null
    })
  }
  
  function handleChangeOrganism(value, index) {
    // console.log('organism', index, 'value', value);
    setOrganisms(prevItems => prevItems.map((item, idx) => {
      if(idx === index) {
        let o = items.filter(item => (item[sppList.items[0]] === value)) //"num",
        // console.log('select o:', o);
        let label = ''
        if( o.length > 0 ) {
          label = o[0][sppList.items[1]] //"Description",
          // console.log('select label:', label);
        }
        return { ...item,
          organismNum: Number(value),
          organism: label,
          weightDisable: (value === '') };
      }
      return item
    }))
  }
  
  function getAddDisable() {
    let o = organisms.filter((item) => item.organismNum && item.weight && !item.weightError.isError)
    let bDis = (o.length < oDisplayCount)
    // console.log('o', organisms);
    // console.log("o'", o);
    // console.log('oDisplayCount', oDisplayCount);
    return bDis
  }
  
  function handleChangeWeight(value, index) {
    // console.log('weight', index, 'value', value);
    let sum = 0
    let keywordError = (keywordErrorData.ok())
    setOrganisms(prevItems => prevItems.map((item, idx) => {
      if(idx === index) {
        // 匹配正整數（如1、100、999）以及正浮點數（如1.23、45.67、0.123）
        if( !value.match(/^([0-9]\d*)(\.\d+)?$/) )
          keywordError = (keywordErrorData.error('invalid input'))
        if( Number(value) === 0 )
          keywordError = (keywordErrorData.error('invalid input'))
        if( value === '' )
          keywordError = (keywordErrorData.error('invalid input'))
        
        let num = Number(value)
        if( typeof num === 'number' && isFinite(num) ) {
          sum += num
        }
        setAddDisable(keywordError.isError)
        
        return { ...item,
          weight: value,
          weightError: keywordError };
      } else {
        sum += Number(item.weight)
      }
      return item
    }))
    // console.log('sum', sum);
    setOrganisms(prevItems => prevItems.map((item, index) => {
      let percent = 0
      if( sum !== 0)
        percent = ((Number(item.weight) / sum)*100).toFixed(1)
      // console.log(index, 'per', percent);
      return { ...item,
        weightPercent: percent,
      }
    }))
    // console.log('%', organisms.map((item) => Number(item.weight)));
  }
  
  const handleClickAdd = () => {
    setODisplayCount(prevItem => {
      if(prevItem < oMaxCount)
        return prevItem+1
      else
        return prevItem
    })
    setAddDisable(true)
    // console.log('count', oDisplayCount);
  }
  
  const handleClickDelete = (index) => {
    // console.log('index', index);
    organisms.splice(index, 1)
    setOrganisms(organisms)
    // console.log(organisms);
    
    setODisplayCount(prevItem => {
      if(prevItem > oMinCount)
        return prevItem-1
    })
    // console.log('count', oDisplayCount);
    // console.log('oDisplayCount', oDisplayCount);
    if(index === (oDisplayCount-1))
      setAddDisable(false)
  }
  
  //---------------------------------------------------------------------------
  // Step 02
  const [methods, setMethods] = useState(texts.step2.methods)
  function handleChangeMethods(method) {
    let bCheck
    setMethods((precItems) => precItems.map((item) => {
      if(item.method === method) {
          bCheck = !item.isCheck
          return { ...item, isCheck: bCheck };
        }
        return item
    }))
  }
  
  function initSelect() {
    let oNumStr = cookie.getCookie(cookie.keys.tool.pdcaasSelectOrganism)
    let oWeightStr = cookie.getCookie(cookie.keys.tool.pdcaasSelectWeight)
    let mMethod = cookie.getCookie(cookie.keys.tool.pdcaasSelectMethod)
    // console.log('pdcaasSelectOrganism', oNumStr);
    // console.log('pdcaasSelectWeight', oWeightStr);
    // console.log('pdcaasSelectMethod', mMethod);
    let oNum = oNumStr ? oNumStr.split(',') : []
    let oWeight = oWeightStr ? oWeightStr.split(',') : []
    const oNumL = oNum.length
    const oWeightL = oWeight.length
    
    if( oNumL > 0 && items.length > 0 ) {
      oNum.forEach((value, index) => {
        // console.log('oNum=', index, 'value=', value);
        handleChangeOrganism(value, index)
        
        if( oWeightL > 0 )
          handleChangeWeight(oWeight[index], index)
        
        if( index !== oNumL-1 )
          handleClickAdd()
      })
      
      if( oNumL >= oWeightL )
        setODisplayCount(oNumL)
    }
    
    if( mMethod !== methodAll.toString() && mMethod !== '' ) {
      setMethods((precItems) => precItems.map((item) => {
        if(item.method !== Number(mMethod))
            return { ...item, isCheck: false };
          return item
      }))
    }
    
  }
  
  //---------------------------------------------------------------------------
  // Bottom
  const handleClickReset = () => {
    if( oDisplayCount > 1 ) {
      for(let i = oDisplayCount-1; i--; i >= oMinCount )
        handleClickDelete()
    }
    
    setOrganisms(initOrganisms(oMaxCount))
    setMethods(texts.step2.methods)
    setAddDisable(true)
    
    cleanPDCAASState()
  }
  
  const handleClickSearch = () => {
    let oError = (organisms.filter(item => (item.organismNum) && (item.weightError.isError)))
    let o = (organisms.filter(item => (item.organismNum) && (item.weight) && !(item.weightError.isError)))
    let m = (methods.filter(item => item.isCheck))
    // console.log('organisms error', oError);
    console.log('organisms', o);
    // console.log('methods', m);
    let oErrorLength = oError.length
    let oLength = o.length
    let mLength = m.length
    if( oErrorLength === 0 && oLength > 0 && mLength > 0 ) {
      let oNum = o.map(item => item.organismNum).toString()
      let oWeight = o.map(item => item.weight).toString()
      let mMethod = m.map(item => item.method).toString()
      if( mLength > 1 )
        mMethod = methodAll.toString()
      // console.log('mMethod', mMethod);
      // console.log('oNum', oNum);
      let oNumStr = oNum.toString()
      let oWeightStr = oWeight.toString()
      // console.log('oNumStr', oNumStr);
      // console.log('oWeightStr', oWeightStr);
      
      cookie.setCookie(cookie.keys.tool.pdcaasSelectOrganism, oNumStr)
      cookie.setCookie(cookie.keys.tool.pdcaasSelectWeight, oWeightStr)
      cookie.setCookie(cookie.keys.tool.pdcaasSelectMethod, mMethod)
      
      navigate(paths.spp.tool.pdcaas_result(oNumStr, oWeightStr, mMethod))
    } else {
      if( oErrorLength > 0 )
        setInfo(snackInfo.openError("Please enter the weight value in step1"))
      else if( oLength === 0 && mLength > 0)
        setInfo(snackInfo.openError("Please finish step1"))
      else if( oLength > 0 && mLength === 0)
        setInfo(snackInfo.openError("Please finish step2"))
      else if( oLength === 0 && mLength === 0)
        setInfo(snackInfo.openError("Please finish step1 & step2"))
    }
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (axiosState.keepRest(state))
      apiList()
    
    initSelect()
    // eslint-disable-next-line
  }, [items]) //update save state
  
  useEffect(() => {
    let bDis = getAddDisable()
    setAddDisable(bDis)
  }, [organisms])

  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.text}>
      
        {/* Enzymes List */}
        <ToolSingleBlock title={texts.step_list} mainBlockContent={
          <MuiTableContainer size='small'>
            <RouterLink style={{ textDecoration: 'underline' }} to={paths.spp.tool.pdcaas_table()}>
              {texts.step_list}</RouterLink>
          </MuiTableContainer>}>
        </ToolSingleBlock>
        
        {/* Step 01 */}
        <ToolSingleBlock stepText={texts.step_title[0]} title={texts.step_text[0]} mainBlockContent={
          <div className={scss.frame_row}>
            <MuiTableContainer size="small">
              <TableHeadRow className="bgc-dark-green">
                {texts.table.title.map((title, index) => (
                  <StyledTableCell key={index}
                    align={'center'}
                    style={{color: v.white}}
                  >
                    {title}</StyledTableCell>
                ))}
              </TableHeadRow>
              <TableBody>
                {organisms.slice(0, oDisplayCount).map((item, index) => (
                  rowChildren(item, index)
                ))}
                {oDisplayCount < oMaxCount
                ? <TableRow2Body >
                    <StyledTableCell key={'Cell0'}>
                      <IconButton className={scss.add_button}
                        disabled={oAddDisable}
                        onClick={handleClickAdd}
                        sx={{
                          background: v.green,
                          '&.Mui-disabled': {background: v.grey25,}
                        }}
                        ><AddIcon fontSize="small"/></IconButton>
                    </StyledTableCell>
                  </TableRow2Body>
                : null }
              </TableBody>
            </MuiTableContainer>
          </div>
          }>
        </ToolSingleBlock>
        
        {/* Step 02 */}
        <ToolSingleBlock stepText={texts.step_title[1]} title={texts.step_text[1]} mainBlockContent={
          <MuiTableContainer>
            <TableBody sx={{ border: 0 }}>
              <TableRow2Body sx={{ border: 0 }}>
                <StyledTableCell sx={{ border: 0 }}>
                  <div className={scss.row_select} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {methods.map((item, index) => (
                      <FormControlLabel
                        key={index+'FormControlLabel'}
                        control={
                          <MuiCheckbox
                            key={index+'Checkbox'}
                            name={item.label}
                            value={index}
                            checked={item.isCheck}
                            // disabled={item.isDisable}
                            onChange={() => handleChangeMethods(item.method)}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                        label={item.label}
                      />
                    ))}
                  </div>
                </StyledTableCell>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer>
          }>
        </ToolSingleBlock >

        <div className={scss.row_button}>
          <WhiteButton handleClick={handleClickReset} text={texts.button.reset} />
          <GreenButton handleClick={handleClickSearch} text={texts.button.search} />
        </div>
        
    </LayoutPageV2 >
  )
}
export default ToolPDCAAS