// import React from 'react'
import { Link } from "react-router-dom"

import { imgData } from "utils/imgs"

//---------------------------------------------------------------------------
const Img = (props) => {
  const {src, alt, imgName, ...other} = props
  return (
    <img
      src={imgName ? imgName.src : src}
      alt={imgName ? imgName.alt : alt}
      {...other} />)
}

const ImgLink = (props) => {
  const {link, ...other} = props
  const isLink = (link !== undefined) && (link.url !== null) && (link.url !== '')
  // console.log(isLink, link);
  if(isLink)
    return (
      <Link to={link ? link.url : ''}>
        <Img {...other} />
      </Link>)
  else
    return (<Img {...other} />)
}

export { ImgLink }
export default Img