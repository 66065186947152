// -----official tools & third tools-------------------------------------------------------
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';


// -----custom tools-------------------------------------------------------
import scss from './Search.module.scss';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import TextList, { listDate } from 'components/TextList';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import NoneButton from 'components/button/NoneButton';
import { filters, setFocus, types } from './Search';

//---------------------------------------------------------------------------
const bExampleOnValue = true
const texts = {
  tabs: [
    'Peptide',
    'Protein',
  ],
  search: 'Search',
}

const StyledTab = styled((props) => <Tab
  value={props.value} label={props.value} id={props.value}
  {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    color: scss.unselectColor,
    background: scss.unselectBg,
    fontSize: '12px',
    padding:'4px 0 14px 0',
    '&.Mui-selected': {
      color: scss.selectColor,
      background: scss.selectBg,
    },
    /* CSS 'Global class' in MUI xxx component docs */
    // '&.Mui-focusVisible': {},
    // '&.Mui-disabled': {}
  }),
);

function NoneTextField(props) {
  return (
    <TextField
      variant="standard"
      InputProps={{
        disableUnderline: true,
        style: { fontSize: '14px' ,width:'300px'}
      }}
      {...props}>
    </TextField>
  )
}
//---------------------------------------------------------------------------
const Search2 = (props) => {
  const { setInfo } = props
  // -----variables-------------------------------------------------------
  const cookieTabName = cookie.getCookie(cookie.keys.quickSearch.tabName)
  const cookieTypeIndex = cookie.getCookie(cookie.keys.quickSearch.typeIndex)
  const cookieTypeName = cookie.getCookie(cookie.keys.quickSearch.typeName)

  const initTabName = (cookieTabName === '' ? texts.tabs[0] : cookieTabName)
  const initTabOption = getTypeOption(initTabName)
  const initTypeIndex = (cookieTypeIndex === '' ? 0 : cookieTypeIndex)

  const initTypeName = (cookieTypeName === '' ? types.peptide[0].label : cookieTypeName)
  const initSearch = ''

  const [tabValue, setTab] = useState(initTabName); //tab name
  const [tabOption, setTabOption] = useState(getTabOption(texts.tabs)); //tab name
  const [tabIndex, setTabIndex] = useState(0); //index of type

  const [typeOption, setTypeOption] = useState(initTabOption);
  const [typeIndex, setTypeIndex] = useState(initTypeIndex); //index of type
  const [typeValue, setType] = useState(initTypeName); //type name
  const [searchValue, setSearch] = useState(initSearch); //search keyword
  const [isExampleValue, setIsExampleValue] = useState(true)//search keyword is example
  const searchRef = useRef(null)
  
  const [state, setState] = useState(axiosState.init())
  const [multiPeptideObj, setMultiPeptideObj] = useState(snackInfo.init())

  const [proteinClsObj, setProteinClsObj] = useState({})
  const navigate = useNavigate()
  let location = useLocation()

  const [isHovered, setIsHovered] = useState(false);

  // -----functions-------------------------------------------------------
  function getTypeOption(tabName) {
    if (tabName === texts.tabs[0]) //Peptide
      return types.peptide
    else if (tabName === texts.tabs[1]) //Protein
      return types.protein
  }

  function getTabOption(tabName) {
    const newArray = [];
    tabName.forEach((item, index) => {
      const newObj = {
        label: item
      };
      newArray.push(newObj);
    })
    return newArray
  }
  const handleChangeTab = (event, newValue) => {
    // console.debug(newValue)
    setTab(newValue)

    let type = ''
    if (newValue === texts.tabs[0]) //Peptide
    {
      type = types.peptide[0].label
      setTypeOption(types.peptide)
      setTypeIndex(0)
      setType(type)

    }
    else if (newValue === texts.tabs[1]) //Protein
    {
      type = types.protein[0].label
      setTypeOption(types.protein)
      setTypeIndex(0)
      setType(type)
    }
    cookie.setCookie(cookie.keys.quickSearch.tabName, newValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, 0)
    cookie.setCookie(cookie.keys.quickSearch.typeName, type)
  };

  const handleChangeType = (event) => {
    // console.debug(event.target.value)
    let newValue = event.target.value
    // console.debug(newValue)
    setType(newValue)

    // console.debug(tabValue)
    var type = undefined;
    type = getTypeOption(tabValue)

    if (type !== undefined) {
      setTypeOption(type)

      let index = type.findIndex(object => { return object.label === newValue; })
      if (index !== -1) {
        setTypeIndex(index)
        if(isExampleValue)
          setSearch(type[index].init)
      }
      else {
        setSearch('')
      }
      setFocus(searchRef)
    }
  }

  const handleChangeKeyword = (event) => {
    setSearch(event.target.value);
  }
  const handleKeyDown = (e) => {
    if( e.key === 'Enter')
      handleClickSearch()
    else
      setIsExampleValue(false)
  }

  const handleClickSearch = () => {
    // console.log('tabValue:', tabValue, 'typeValue:', typeValue);
    cookie.setCookie(cookie.keys.quickSearch.tabName, tabValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, typeIndex)
    cookie.setCookie(cookie.keys.quickSearch.typeName, typeValue)
    cookie.setCookie(cookie.keys.quickSearch.filterValue, searchValue)

    if (searchValue !== "") {

      if (tabValue === texts.tabs[0]) //Peptide
      {
        let filterValue = filters.peptide[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.peptide.quick_search())
      }
      else if (tabValue === texts.tabs[1]) //Protein
      {
        let filterValue = filters.protein[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.protein.quick_search())
      }
    } else {

      setInfo(snackInfo.openError("Search field cannot be empty"))
    }
  }

  const apiList = () => {
    const config = apiConfig.peptide.listWithDefaultToken()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        const multiPeptideData = data.filter(item => item.classification_col === "multifunctionalpeptides")

        setMultiPeptideObj(multiPeptideData[0])

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const getProteinClsList = () => {
    const config = apiConfig.protein.list_class_default_token()
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        setProteinClsObj(data)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //-----------------------------------------------------------------------
  useEffect(() => {
    apiList()
    getProteinClsList()
  }, []);
  // -----render-------------------------------------------------------
  return (
    <div >
      <div className={scss.frame_search3}>
        <div className={scss.search_input3}>
          <div className={scss.input_top}>
            <div className={scss.top_tab}>
              <Tabs value={tabValue} onChange={handleChangeTab}>
                <StyledTab value={texts.tabs[0]} className={scss.tab_left} />
                <StyledTab value={texts.tabs[1]} className={scss.tab_right} />
              </Tabs>
            </div>
          </div>
          <div className={scss.input_center}>
            <div className={scss.center_type}>
              <TextList option={typeOption} index={typeIndex} value={typeValue} onChange={handleChangeType} fontSize='14px'></TextList>
            </div>
            <div className={scss.center_word}>
              {bExampleOnValue ?
                (<NoneTextField
                  type="search"
                  inputRef={searchRef}
                  value={searchValue} placeholder={texts.search}
                  onChange={handleChangeKeyword}
                  onKeyDown={handleKeyDown}
                />)
                :
                (<NoneTextField
                  type="search"
                  inputRef={searchRef}
                  placeholder={searchValue}
                  onChange={handleChangeKeyword}
                  onKeyDown={handleKeyDown}
                />)
              }
            </div>
            <NoneButton className={scss.button_search} variant='contained' onClick={handleClickSearch}>
              {texts.search}
            </NoneButton>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Search2;
