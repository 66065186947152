import { spp } from "utils/network/jsons"

//------------------------------------------------------------------------------
//initial
const inits = {
  ItemNum: 10,
}

class itemNoData {
  static init() {
    return {
      start: 1,
      end: inits.ItemNum,
    }
  }
  static create(start, end) {
    return { start, end }
  }
}

class pageNoData {
  static init() {
    return {
      now: 1,
      last: 1,
    }
  }
  static create(now, last) {
    return { now, last }
  }
}

function getNewPageNo(pageNo, buttonText) {
  if(!Object.isObject(pageNo) || !buttonText)
    return null;
  
  let pageNew = pageNo.now
  const value = buttonText
  switch (value) {
    case 'Next':
      if ((pageNew + 1) > pageNo.last)
        return null;
      pageNew += 1
      break
    case 'Previous':
      if ((pageNew - 1) < 1)
        return null;
      pageNew -= 1
      break
    case '...':
      return null;
    default:
      let num = Number(value)
      if (num !== undefined && num > 0)
        pageNew = num
      else
        return null;
  }
  return pageNew
}
//-----------------------------------------------------------------------------

class tableSource {
  isProtein = false
  isProteinQuick = false
  isProteinCount = false
  
  isPeptide = false
  isPeptideList = false
  isPeptideMulti = false
  
  isToolManualPeptide = false
  isACEiPPAnOxPPMutation = false
  
  constructor(tableBodyCell) {
    // console.log('tableBodyCell', tableBodyCell);
    //------------------------------------------------------------------------------
    this.isProtein = 
         (tableBodyCell === spp.protein.search.quick.output)
      || (tableBodyCell === spp.protein.search.classify.output)
      || (tableBodyCell === spp.protein.search.source.output)
      || (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)
    this.isProteinQuick = 
         (tableBodyCell === spp.protein.search.quick.output)
    this.isProteinCount = 
         (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)
    //------------------------------------------------------------------------------
    this.isPeptide = 
         (tableBodyCell === spp.peptide.search.advanced.output)
      || (tableBodyCell === spp.peptide.search.classify.output)
      || (tableBodyCell === spp.peptide.search.classify.output_ic50)
      || (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.peptide.search.quick_classify.output)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items_ic50)
      || (tableBodyCell === spp.peptide.search.classification_advanced_search.output)
      || (tableBodyCell === spp.tool.css_tool_result.items)
      || (tableBodyCell === spp.tool.css_tool_result.items_multi)
    this.isPeptideList = 
         (tableBodyCell === spp.protein.search.peptide_list.output)
    this.isPeptideMulti = 
         (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.tool.css_tool_result.items_multi)
      || (tableBodyCell === spp.peptide.search.advanced_multi.output)
    //------------------------------------------------------------------------------
    this.isToolManualPeptide = 
         (tableBodyCell === spp.tool.hot_spot_result_chart.items_adv)
    this.isACEiPPAnOxPPMutation = 
         (tableBodyCell === spp.tool.aceipp_anoxpp_manual_mutation.items)
  }
}

export { inits, itemNoData, pageNoData, getNewPageNo, tableSource }