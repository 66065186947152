import { TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { CategoryScale } from 'chart.js';
import Chart from "chart.js/auto";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// import v from 'assets/scss/_variables.scss';
import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';
import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableContainer as TableContainer } from "components/table/MuiTable";
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray } from 'utils/general';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import paths from 'utils/network/apiPath';
import { getRow } from 'components/table/table';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'HotSpot Search' button -> checked 'Milk', 'Plant', 'Animal', 'Marine', 'Microorganism'
// -> type char in 'Step 2' -> click 'search' button -> this page
// path: paths.spp.tool.hot_spot_result_chart,
// http://localhost:3000/tool_hs_rc?keyword=aa&cls=150010
const jsons = spp.tool.hot_spot_result_chart //42
const texts = {
  table: {
    head: jsons.output_text,
    json: jsons.output,
    total: ' result(s)',
  },
}

function rowData(name, value) {
  return { name, value };
}

const MuiTableContainer = (props) => (
  <TableContainer size='small'>
    {props.children}
  </TableContainer>
)

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='right'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

Chart.register(CategoryScale);
Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const ToolHotSpotResultChart = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get(paths.params.keyword)
  const source = searchParams.get(paths.params.class)
  const searchAll = searchParams.get(paths.params.all)
  const peptideID = searchParams.get(paths.params.id)
  // console.log('source=', source);
  // console.log('searchAll=', searchAll);
  // console.log('peptideID=', peptideID);
  let input = {}
  let sourceId = []
  if (source) //NOT undefined, null, ""
    sourceId = source.split(',')
  // console.log('sourceId=', sourceId);
  input = jsons.input(sourceId, keyword) //42
  if(searchAll !== null)
    input = jsons.input_all(keyword) //42
  // console.log(input);

  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'HotSpot result data output', bread3rd, 'Result'),
    
    chart_sequence: {
      title: 'Number of fragment distribution information of peptide sequence in protein(s)',
      //'Total protein: 30 result(s)'
      text: `Total protein result(s): `,
      x: 'Protein source',
      y: 'Count',
    },
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [items, setItems] = useState([]) //items data 
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.hotspot_result_chart(input) //42
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        Object.assign(output, {[jsons.output[0]]: peptideID})
        setOutput(output)
        // console.log(output);

        let item = output[jsons.output[3]] //'Count result'
        if (isEmptyObjOrArray(item)) {
          setInfo(snackInfo.openInfo(emptyResult))
        }

        if (!item)
          item = []
        setItems(item)
        // console.log("item:",item)

        setRows(getRows(texts, output))
      }
    }).catch(err => {
      let msg = axiosCatch.getMsg(err)
      let code = err.response.data[spp.common.response.code]
      if (code === 920) {
        msg = err.response.data[spp.common.response.reason]
        // console.log(msg);
        let output = { [jsons.output[2]]: 0 } //"Total protein",
        setOutput(output)
        // console.log(output);
        setRows(getRows(texts, output))
      }

      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(msg))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  const [rows, setRows] = useState(getRows(texts, ''))
  function getRows(texts, output) {
    return ([
      getRow(texts.table, output),
    ])
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
    // eslint-disable-next-line
  }, [])
  
  //---------------------------------------------------------------------------
  // Table
  const rowChildren = (row, index) => {
    let value = row.value
    // console.log(value);
    if (index === 1) //"Peptide sequence"
      value = keyword.toUpperCase()
    else if (index === 2) //"Total protein"
      value = row.value + texts.table.total

    if( (index === 0 && (value === null || value === '')) === false ) //"Peptide SPP ID"
      return (
        <TableRow key={row.name}>
          <MuiTableHeadCell1st>{row.name}</MuiTableHeadCell1st>
          <MuiTableCell>{value}</MuiTableCell>
        </TableRow>
      )
  }
  
  //---------------------------------------------------------------------------
  // BarChart
  const chartSequenceData = chartProp.data.carate(
    "",
    "",
    '',
    items.map((data) => (data[jsons.items[0]]).removeHTMLTags()), //"Term"
    items.map((data) => data[jsons.items[1]]), //"Count results"
  )
  const chartSequenceOptions = chartProp.options.hotSpotResultClickable(words.chart_sequence.x, words.chart_sequence.y, chartSequenceData.labels, navigate, keyword)
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={words.bread}>
      
        <MuiTableContainer>
          {state.isLoading ? <LoadingAnime /> :
            <TableBody>
              {rows[0].map((row, index) => (
                rowChildren(row, index)
              ))}
            </TableBody>
          }
        </MuiTableContainer>

        <ChartSection
          chartBlockTitle={words.chart_sequence.title}
          chartBlockSubTitle={words.chart_sequence.text}
          chartBlockSubTitleNumber={output[jsons.output[2]]}
          mainChart={
            <BarChart
              data={chartSequenceData}
              options={chartSequenceOptions}
              hasXRangeSlider={true}
              yAxisLabel={words.chart_sequence.y}
              needHoverStyle={true} />
          } />
      
    </LayoutPageV2>
  )
}
export default ToolHotSpotResultChart