import { Button } from "@mui/material";

import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------
const WhiteButton = (props) => {
  return <Button
    className={scss.secondary16_button}
    onClick={props.handleClick}
    sx={{
      boxShadow: "none",
      textTransform: "none",
    }}
  >{props.text}</Button>
}

export default WhiteButton;