import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import scss from 'views/protein/ProteinSelection.module.scss';
import scss from './SearchResultLayout.module.scss';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import { inits, itemNoData, pageNoData, getNewPageNo, tableSource } from 'layouts/search/tableData';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import SearchResultTable from './SearchResultTable';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';
import paths from 'utils/network/apiPath';
import useLocationSPP from 'utils/location';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
const SearchResultLayout = (props) => {
  let { tableBodyCell, setInfo } = props
  let texts = props.texts
  let dbItems = props.jsonItems ? props.jsonItems : spp.common.output.items
  // console.log(dbItems);
  if ( tableBodyCell === undefined )
    tableBodyCell = texts.tableBodyCell
  // console.log('tableBodyCell', tableBodyCell);
  const source = new tableSource(tableBodyCell)
  // console.log('source', source);
  
  const [inputProp, setInputProp] = useState(props.inputProp)
  // console.log('inputProp', inputProp);
  
  let location = useLocationSPP()
  let fullPath = getFullPath(location)
  // console.log('location', location);
  if (source.isProtein) {
    let breadTitle = texts.bread.title
    let bread = linkData(breadTitle.trimClassifyName(), fullPath)
    if (source.isProteinQuick) {
      bread = linkData('Search', fullPath)
    }
    let json3rd = JSON.stringify(bread)
    cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
    // console.log('bread3rd', bread);
  }
  
  let isSearchFragment = props.isSearchFragment
  let hasVenn = props.hasVenn
  // console.log('isSearchFragment', isSearchFragment);
  const isCssResultLv3Multi = isSearchFragment && location.isCssResultLv3 && (tableBodyCell === spp.tool.css_tool_result.items_multi)
  
  const [output, setOutput] = useState({})
  const [items, setItems] = useState([])
  const [itemNo, setItemNo] = useState(itemNoData.init()) //item index 1-10
  const [itemsPerPage, setItemsPerPage] = useState(inits.ItemNum) //item number per page
  const eventChangeItemsPerPage = (value) => {
    if (value !== undefined) {
      setItemsPerPage(value)
      setPageNo(pageNoData.init())
      if (keyword !== '') {
        setInput(props.jsonInputKeyword(...inputProp, keyword, value, 1))
      } else {
        setInput(props.jsonInput(...inputProp, value, 1))
      }
      setState(axiosState.init())
    }
  }

  let words = {
    index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
    page: ['Previous', '...', 'Next'],
    goto: 'Go to',
  }
  if(isCssResultLv3Multi) {
    words.index[1] = `of ${output.multi_total}`
    isSearchFragment = false //hide others block
  }
  if(location.isCssResultLv3 && !hasVenn)
    isSearchFragment = false //hide 'of xx + 0'
  if(isSearchFragment)
    words.index[1] = `of ${output.total} + ${output.multi_total}`
  
  const [pageNo, setPageNo] = useState(pageNoData.init())
  const [pages, setPages] = useState([`${pageNo.now}`, ...words.page])
  const handleClickPage = (value) => {
    // console.log(value);
    let pageNew = getNewPageNo(pageNo, value)
    if(!pageNew)
      return;
    
    // console.log('pageNew', pageNew)
    setPageNo(pageNoData.create(pageNew, pageNo.last))

    if (keyword !== '')
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage, pageNew))
    else
      setInput(props.jsonInput(...inputProp, itemsPerPage, pageNew))
    setState(axiosState.init())
  }

  const [keyword, setKeyword] = useState('')
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
  }
  const handleClickSearch = () => {
    if (keyword !== '') {
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage))
    }
    else {
      setInput(props.jsonInput(...inputProp, itemsPerPage))
    }
    setState(axiosState.init())
  }

  const [input, setInput] = useState(props.jsonInput(...inputProp, itemsPerPage))

  function removeEmptyStringKeys(obj) {
    // console.log("obj:", obj)
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== "" && value !== 0)
    );
  }
  
  const [others, setOthers] = useState([])
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiResult = () => {
    // console.log('input', input);
    let newInput = removeEmptyStringKeys(input)

    if (props.configSearch === apiConfig.peptide.multi_search) {
      const selectedMultiPeptideList = cookie.getCookie(cookie.keys.peptide.selectedMultiPeptideList)

      if (selectedMultiPeptideList.length !== 0 && !("classification" in newInput) && !("source" in newInput)) {
        newInput["class_list"] = selectedMultiPeptideList
      }
    }

    const config = props.configSearch(newInput)
    // console.log('config', config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data
        setOutput(data)
        // console.log(data)
        
        let item = result.data[dbItems]
        let count = item.length
        if (source.isProteinCount) {
          count = data[spp.common.total]
          // console.log('count', count);
        }
        let total = data.total
        
        if(isCssResultLv3Multi) {
          let others = data[spp.tool.css_tool_result.fragment_multi[0]]
          item = others
          // console.log('others', others);
          let multi_total = data[spp.tool.css_tool_result.fragment_multi[1]]
          count = multi_total
          total = multi_total
        }
        setItems(item)
        // console.log('item', item)
        // console.log('count', count);
        // console.log('pageNo.now', pageNo.now);
        
        if( isSearchFragment ) {
          let key = spp.tool.css_tool_result.fragment_multi[0]
          let others = data[key]
          setOthers(others)
          // console.log(key, others);
        }

        let itemStart = ((pageNo.now - 1) * itemsPerPage)
        if (count > 0 && count < itemsPerPage)
          setItemNo(itemNoData.create(itemStart + 1, itemStart + count))
        else if (count === 0)
          setItemNo(itemNoData.create(0, 0))
        else
          setItemNo(itemNoData.create(itemStart + 1, itemStart + itemsPerPage))

        let pageTotal = Math.ceil(total / itemsPerPage)
        setPageNo(1, pageTotal)

        let pages = []

        if (pageTotal <= 5) {
          if (pageNo.now > 1)
            pages.push(words.page[0]) //Previous

          for (let index = 1; index <= pageTotal; index++) {
            pages.push(`${index}`)
          }

          if (pageNo.now < pageTotal)
            pages.push(words.page[2]) //Next
        } else {
          let pageStart = ((pageNo.now + 2) > pageTotal ? pageTotal - 2 : pageNo.now - 1) //start index on 3 button of center
          if (pageStart < 1)
            pageStart = 1
          if (pageNo.now > 1)
            pages.push(words.page[0]) //Previous
          if (pageNo.now >= 3) {
            pages.push(1)
            if (pageNo.now > 3) //1 ... 3 4 5
              pages.push(words.page[1]) //...
          }
          let pageFirst3 = (pageTotal > 3 ? 3 : pageTotal)
          for (var i = pageStart; i < (pageStart + pageFirst3); i++)
            pages.push(String(i))
          if (pageNo.now <= (pageTotal - 2)) {
            if (pageNo.now < (pageTotal - 2)) //12 13 14 ... 16
              pages.push(words.page[1]) //...
            pages.push(pageTotal)
          }
          if (pageNo.now < pageTotal)
            pages.push(words.page[2]) //Next
        }

        setPages(pages)
        setPageNo(pageNoData.create(pageNo.now, pageTotal))
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
    // eslint-disable-next-line
  }, [input, pageNo.now])
  
  let style = {}
  // http://localhost:3000/peptide_multi
  // if( props.desc !== undefined ) {
  //   style = { marginTop: "200px" }
  // }
  // http://localhost:3000/tool_hc_r?&source=150050&keyword=aa
  // if( location.isHotspotCssResult )
  //   style = { marginTop: '0px' }
    
  return (
    <LayoutPageV2 bread={texts.bread}
      desc={props.desc !== undefined ? props.desc : ""}
      tooltipOfDesc={props.tooltipOfDesc}
      searchInput={inputProp} jsonInput={props.jsonInput}
      searchOutput={output}>
      <div className={scss.layout} style={style}>
        {props.barChart !== null && props.barChart}
        {props.vennDiagram !== null && props.vennDiagram}

        <SearchResultTable
          tableHeadCell={texts.tableHeadCell}
          tableBodyCell={texts.tableBodyCell}
          isLoading={state.isLoading}
          output={output}
          inputProp={props.configSearch === apiConfig.peptide.fun_relationship_cross_search ? [props.inputProp[1]] : inputProp}
          info={props.jsonInfo}
          items={items}
          itemNo={itemNo}
          eventChangeItemsPerPage={eventChangeItemsPerPage}
          texts={texts}
          words={words}
          pages={pages}
          pageNo={pageNo}
          handleClickPage={handleClickPage}
          keyword={keyword}
          handleChangeSearch={handleChangeSearch}
          handleClickSearch={handleClickSearch}
          others={others}
          isSearchFragment={isSearchFragment}
        />
      </div>
    </LayoutPageV2>
  )
}
export default SearchResultLayout