// components/card/GradientCard.jsx
import React from 'react';
import scss from './GradientCard.module.scss';

// const GradientCard = ({ children, onClick }) => {
//   return (
//     <div className={scss.gradient_card} onClick={onClick}>
//       {children}
//     </div>
//   );
// };

// export default GradientCard;

// components/card/GradientCard.jsx

const GradientCard = ({ children, onClick }) => {
  return (
    <div className={scss.gradient_card} onClick={onClick}>
      {/* <svg 
        className={scss.card_background} 
        width="200" 
        height="240" 
        viewBox="0 0 200 240" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M0.500244 0.5H199.5V220.775L178.311 239.5H0.500244V0.5Z" 
          fill="url(#paint0_linear_4006_2821)"
        />
        <path 
          d="M0.500244 0.5H199.5V220.775L178.311 239.5H0.500244V0.5Z" 
          stroke="url(#paint1_linear_4006_2821)"
        />
        <path 
          d="M0.500244 0.5H199.5V220.775L178.311 239.5H0.500244V0.5Z" 
          stroke="#64FFDA" 
          strokeOpacity="0.16"
        />
        <defs>
          <linearGradient 
            id="paint0_linear_4006_2821" 
            x1="100" 
            y1="0" 
            x2="100" 
            y2="240" 
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#64FFDA" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#64FFDA" stopOpacity="0"/>
          </linearGradient>
          <linearGradient 
            id="paint1_linear_4006_2821" 
            x1="0.000244141" 
            y1="120" 
            x2="200" 
            y2="120" 
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2BFFFF" stopOpacity="0"/>
            <stop offset="0.5" stopColor="#2BFFFF"/>
            <stop offset="1" stopColor="#2BFFFF" stopOpacity="0"/>
          </linearGradient>
        </defs>
      </svg> */}
      <div className={scss.card_content}>
        {children}
      </div>
    </div>
  );
};

export default GradientCard;