import { TextField } from '@mui/material';
import styled from 'styled-components';

// import v from "assets/scss/_variables.scss"
import v2 from "assets/scss/_variablesV2.scss"
import 'assets/scss/commonV2.scss'

//---------------------------------------------------------------------------
const StyledTextField = styled((props) => 
  <TextField
    variant="outlined"
    fullWidth
    multiline maxRows={4}
    
    // InputProps={{
    //   // styleName: Contents: Quantico/Body/16px: Regular;
    //   className: 'Contents-QuanticoBody16px-Regular',
    // }}
   {...props} />)({
  //for variant='standard' StyledTextField in http://localhost:3000/tool_ehp_s
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#f0f !important',
    // Green Alpha/Alpha_16
    borderBottom: '1px solid rgba(100, 255, 218, 0.16)',
  },
  //for variant='filled'
  "& .MuiFilledInput-underline:after": {
    // borderBottomColor: '#0ff !important',
    // Green Alpha/Alpha_16
    borderBottom: '1px solid rgba(100, 255, 218, 0.16)',
  },
  //for variant='outlined'(default) StyledTextField in http://localhost:3000/tool_hs_s
  '& label.Mui-focused': {
    // color: '#ff0 !important',
    // color: v.darkGreen,
    // White/White_50
    color: 'rgba(255, 255, 255, 0.5)',
  },
  '& .MuiOutlinedInput-root': {
    // background: '#f0f',
    // White/White_75
    color: 'rgba(255, 255, 255, 0.75)',
    
    '& fieldset': { //select
      // borderColor: '#f00 !important',
      // Green Alpha/Alpha_16
      border: '1px solid rgba(100, 255, 218, 0.16)',
    },
    '&:hover fieldset': { //hover
      // borderColor: '#0f0 !important',
      // Green Alpha/Alpha_8
      background: 'rgba(100, 255, 218, 0.08)',
      border: '1px solid rgba(100, 255, 218, 0.16)',
    },
    '&.Mui-focused fieldset': { //focused
      // borderColor: '#00f !important',
      // Green Alpha/Alpha_8
      background: 'rgba(100, 255, 218, 0.08)',
      border: '1px solid rgba(100, 255, 218, 0.16)',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      // borderColor: '#0ff !important',
      color: 'rgba(255, 255, 255, 0.5)',
      border: '1px solid rgba(100, 255, 218, 0.16)',
    },
  },
});

export default StyledTextField;