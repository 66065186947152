import cookie from "utils/cookie";
import { tokenDefault } from "./config";

// property for Backend API & DB
function json() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    // "Access-Control-Allow-Methods": "GET, POST"
    "X-Auth-Token": cookie.getCookie(cookie.keys.auth.token),
  };
}

function token(token) {
  let tokenUser =
    token !== undefined ? token : cookie.getCookie(cookie.keys.auth.token);
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenUser,
  };
}

const headers = {
  jsonDefault: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenDefault,
  },

  json,
  token,
};

//---------------------------------------------------------------------------
// authentication
const auth = {
  // 1 login
  login: class {
    static input(email, password) {
      return {
        email,
        password,
      };
    }
    static output = {
      nickname: "nickname",
    };
  },

  //4 register
  register: class {
    static input(email, password, name, tnc_version) {
      return {
        email,
        password,
        name,
        tnc_version,
      };
    }
  },

  //3 verify_email
  verifyEmail: class {
    static input(e_token, p_token) {
      return {
        e_token,
        p_token,
      };
    }
  },

  //16 get_tnc
  tnc: {
    output: {
      content: "content",
      version: "version",
    },
    response: {
      version: "version",
      code: "result_code",
    },
  },

  response: {
    token: "X-Auth-Token",
    id: "user_id",
  },
};

//---------------------------------------------------------------------------
// site
const site = {
  //78 portal_summary 7.6
  home_number: class {
    static output = [
      "peptide_classification",
      "peptide_sequence",
      "peptide_entries",
      "peptide_attributes",
      "multifunctional_peptides",
      "food_borne_proteins",
      "literatures",
      "application_tools",
      "sequence_features",
    ];
  },
};

//---------------------------------------------------------------------------
// protein
const protein = {
  //10 proteins_classification_list
  list: {
    output: ["item(s)"],
    items: {
      class: "classification",
      class_id: "classification_id",
      source: "source",
      source_id: "source_id",
      counts: "counts",
    },
  },

  //23 proteins_all_class
  class_list: {
    output: {
      classes: "classes",
    },

    items: {
      is_show: "IsShow",
      code: "code",
      name: "name",
    },
  },

  search: {
    /* //11
    "classification": str(optional)
    "source": str(optional)
    "filterSelection":str(optional)
    "filterValue":str(optional)
    "keyWord": str(optional)
    "lineSize": int(optionaldefault=10)
    "currentPage": int(optionaldefault=1)
    */
    //11 proteins_search N17
    quick: class {
      static input(
        filterSelection,
        filterValue,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          filterSelection,
          filterValue,
          lineSize,
          currentPage,
        };
      }

      static input_keyword(
        filterSelection,
        filterValue,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          filterSelection,
          filterValue,
          keyWord,
          lineSize,
          currentPage,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "organism",
        "protein_name",
        "length",
        "peptides",
        "fragments",
        "uni_prot_KB",
      ];
      static output_text = [
        "#",
        "SPP ID",
        "Organism",
        "Protein name",
        "Length",
        "Peptides",
        "Fragments",
        "UniProtKB",
      ];

      static response = {
        search_category: "search_category",
        total: "total",
        /* 
                "message": "Success"
                "result_code": 200
                "search_category": "Food-borne proteins"
                "total": 5756
                 */
      };
    },

    //11 proteins_search classification O17
    classify: class {
      static input(classification, lineSize = 10, currentPage = 1) {
        return {
          classification,
          lineSize,
          currentPage,
        };
      }
      static input_keyword(
        classification,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          keyWord,
          lineSize,
          currentPage,
        };
      }
      static output = [
        //only "item(s)" array
        "SPP_ID",
        "organism",
        "protein_name",
        "length",
        "peptides",
        "fragments",
        "uni_prot_KB",
      ];
      static output_text = [
        "#",
        "SPP ID",
        "Organism",
        "Protein name",
        "Length",
        "Peptides",
        "Fragments",
        "UniProtKB",
      ];
      static response = {
        search_category: "search_category",
        total: "total",
      };
    },

    //11 proteins_search source P17
    source: class {
      static input(classification, source, lineSize = 10, currentPage = 1) {
        return {
          classification,
          source,
          lineSize,
          currentPage,
        };
      }
      static input_keyword(
        classification,
        source,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          source,
          keyWord,
          lineSize,
          currentPage,
        };
      }
      static output = [
        //only "item(s)" array
        "SPP_ID",
        "organism",
        "protein_name",
        "length",
        "peptides",
        "fragments",
        "uni_prot_KB",
      ];
      static output_text = [
        "#",
        "SPP ID",
        "Organism",
        "Protein name",
        "Length",
        "Peptides",
        "Fragments",
        "UniProtKB",
      ];
      static response = {
        search_category: "search_category",
        total: "total",
      };
    },

    //13 protein_properties
    id: class {
      static input(SPP_ID) {
        return {
          SPP_ID,
        };
      }
      static output = [
        //_00card
        "SPP_ID",
        "protein_name",
        "type",
        "length",
        //_0overview
        "classification",
        "organism",
        //_1protein
        "length", //=4
        "protein_sequence",
        //_2peptide
        "peptide_list",
        "uni_prot",
      ];
      static output_text = [
        //_00card
        "SPP ID",
        "Protein name",
        "Type",
        "Length",
        //_0overview
        "Classification",
        "Organism",
        //_1protein
        "Protein length",
        "Protein sequence",
        //_2peptide
        "Peptide List",
        "UniProt",
        // 'Enzymatic Hydrolysis',
      ];
      static peptide_list = [
        "items",
        "unique_sequences_sum",
        "unique_sequences_total",
        "cumulative_fragments_sum",
        "cumulative_fragments_total",
      ];
      static items = [
        //only "items" array
        "display_name",
        "unique_sequences",
        "cumulative_fragments",
      ];
      static items_text = [
        "#",
        "Functional Classification",
        "Unique Sequences",
        "Cumulative Fragments",
      ];
    },

    //41 cumulative_result
    fragment_count: class {
      static input(
        class_code = 0,
        source_code = 0,
        line_size = 10,
        current_page = 1
      ) {
        return {
          class_code: Number(class_code),
          source_code: Number(source_code),
          line_size,
          current_page,
        };
      }
      static input_keyword(
        class_code = 0,
        source_code = 0,
        keyword,
        line_size = 10,
        current_page = 1
      ) {
        return {
          class_code: Number(class_code),
          source_code: Number(source_code),
          keyword,
          line_size,
          current_page,
        };
      }
      static output = "cumulative_page_info";
      static output_text = [
        "#",
        "SPP ID",
        "Organism",
        "Protein name",
        "Length",
        "UniProtKB",
      ];
      static info = ["col_names", "value"];
      static response = {
        total: "total",
      };
    },

    //44 class_count_result
    classify_count: class {
      static input(
        class_code = 0,
        source_code = 0,
        line_size = 10,
        current_page = 1
      ) {
        return {
          class_code: Number(class_code),
          source_code: Number(source_code),
          line_size,
          current_page,
        };
      }
      static input_keyword(
        class_code = 0,
        source_code = 0,
        keyword,
        line_size = 10,
        current_page = 1
      ) {
        return {
          class_code: Number(class_code),
          source_code: Number(source_code),
          keyword,
          line_size,
          current_page,
        };
      }
      static output = "class_count_page_info";
      static output_text = [
        "#",
        "SPP ID",
        "Organism",
        "Protein name",
        "Length",
        "UniProtKB",
      ];
      static info = ["col_names", "value"];
      static response = {
        total: "total",
      };
    },
    peptide_list: class {
      static input(SPP_ID, table_name) {
        return {
          SPP_ID,
          table_name,
        };
      }
      static input_keyword(SPP_ID, table_name) {
        return {
          SPP_ID,
          table_name,
        };
      }

      static output = [
        //only "item(s)" array
        "Sequences",
        "Length",
        "Coding Position",
        "Report entries (SPP ID)",
        "Other entries (SPP ID)",
        "",
      ];
      static output_text = [
        "#",
        "Sequences",
        "Length",
        "Coding Position",
        "Report entries (SPP ID)",
        "Other entries (SPP ID)",
        "HotSpot Search",
      ];
      static response = {
        total: "total",
      };
    },
    //66 hotspot_highlight
    hotspot_result: class {
      static input(Peptide_SPP_ID, Protein_SPP_ID, search_fragment) {
        return {
          Peptide_SPP_ID,
          Protein_SPP_ID,
          search_fragment,
        };
      }
      static input_keyword(Peptide_SPP_ID, Protein_SPP_ID, search_fragment) {
        return {
          Peptide_SPP_ID,
          Protein_SPP_ID,
          search_fragment,
        };
      }

      static output = [
        //Bioactive peptide
        "Fragments", //0
        "table name",
        "Peptide_SPP_ID", //2
        //Precursor protein
        "Protein_SPP_ID",
        "Protein type", //4
        "Protein classification",
        "Protein source", //6
        "Database references",
        //Bioactive peptide coding site
        "Protein sequence", //8
        "BLAST results",
        "Position", //10
      ];
      static output_text = [
        //Bioactive peptide
        "Fragments", //0
        "Table name",
        "Peptide SPP ID", //2
        //Precursor protein
        "Protein SPP ID",
        "Protein type", //4
        "Protein classification",
        "Protein source", //6
        "Database references",
        //Bioactive peptide coding site
        "Protein sequence", //8
        "BLAST results",
        "Position", //10
      ];
      static response = {
        total: "total",
      };
    },
  },
};

//---------------------------------------------------------------------------
// peptide
const peptide = {
  //8 peptides_classification_list
  list: {
    output: {
      items: "item(s)",
      /*
      "peptides_total": int
      "records_total": int
      "classification_total": int
      */
    },
    items: [
      "classification",
      "classification_col",
      "classification_id",
      "counts",
    ],
  },

  search: {
    //5 peptides_quick_search
    quick: class {
      static input(filterSelection, filterValue) {
        return {
          filterSelection,
          filterValue,
        };
      }
      static output = [
        "total",
        // 'items', //used common
      ];
      static items = [
        //only "item(s)" array
        "classification",
        "display_name",
        "results",
      ];
      static items_text = ["#", "Classification", "Results"];
    },

    //6 peptides_advanced_search
    advanced: class {
      static input(peptidesList, filterSelection, filterValue) {
        return {
          peptidesList,
          filterSelection,
          filterValue,
        };
      }

      static output = [
        //only "item(s)" array
        "classification",
        "results",
      ];
      static output_text = ["#", "Classification", "Results"];
      static response = {
        total: "total",
      };
    },

    //9 peptide_multifunctional_search
    multi: class {
      static input(lineSize = 10, currentPage = 1) {
        return {
          lineSize,
          currentPage,
        };
      }
      static input_keyword(keyWord, lineSize = 10, currentPage = 1) {
        return {
          keyWord,
          lineSize,
          currentPage,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "AA_length",
        "peptide_mass",
        "function_counts",
        "multifunctional_activities",
        'structure',
      ];
      static output_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "AA length",
        "Peptide mass",
        "Function counts",
        "Multifunctional activities",
        'Structure',
      ];
      static response = {
        total: "total",
      };
    },

    advanced_multi: class {
      static input(
        filterSelection,
        filterValue,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          filterSelection,
          filterValue,
          lineSize,
          currentPage,
        };
      }
      static input_keyword(
        filterSelection,
        filterValue,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          filterSelection,
          filterValue,
          keyWord,
          lineSize,
          currentPage,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "AA_length",
        "peptide_mass",
        "function_counts",
        "multifunctional_activities",
        'structure',
      ];
      static output_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "AA length",
        "Peptide mass",
        "Function counts",
        "Multifunctional activities",
        'Structure',
      ];
      static response = {
        total: "total",
      };
    },

    //11 peptides_classification_search
    classify: class {
      static input(
        classification,
        lineSize = 10,
        currentPage = 1,
        orderBy = "ASC",
        sortObjects = "SPP_ID"
      ) {
        return {
          classification,
          lineSize,
          orderBy,
          currentPage,
          sortObjects,
        };
      }

      static input_keyword(
        classification,
        keyWord,
        lineSize = 10,
        currentPage = 1,
        orderBy = "ASC",
        sortObjects = "SPP_ID"
      ) {
        return {
          classification,
          lineSize,
          keyWord,
          orderBy,
          currentPage,
          sortObjects,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "AA_length",
        "peptide_mass",
        "Source",
        "precursor_protein",
        "pub_date",
        'structure',
      ];
      static output_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "Peptide/Function name",
        "AA length",
        "Peptide mass",
        "Organism/Source",
        "Precursor protein",
        "PubDate",
        'Structure',
      ];
      static output_ic50 = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "AA_length",
        "peptide_mass",
        "IC50",
        "precursor_protein",
        "pub_date",
        'structure',
      ];
      static output_ic50_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "Peptide/Function name",
        "AA length",
        "Peptide mass",
        "IC50",
        "Precursor protein",
        "PubDate",
        'Structure',
      ];
      static response = {
        search_category: "search_category",
        total: "total",
      };
    },

    cross_classify: class {
      static input(
        table_name,
        cross_table_name,
        lineSize = 10,
        currentPage = 1,
        orderBy = "ASC",
        sortObjects = "SPP_ID"
      ) {
        return {
          table_name,
          cross_table_name,
          lineSize,
          orderBy,
          currentPage,
          sortObjects,
        };
      }

      static input_keyword(
        table_name,
        cross_table_name,
        keyWord,
        lineSize = 10,
        currentPage = 1,
        orderBy = "ASC",
        sortObjects = "SPP_ID"
      ) {
        return {
          table_name,
          cross_table_name,
          lineSize,
          keyWord,
          orderBy,
          currentPage,
          sortObjects,
        };
      }

      // static output = [ //only "item(s)" array
      //   "SPP_ID",
      //   "AA_sequence",
      //   "peptide_function_name",
      //   "AA_length",
      //   "peptide_mass",
      //   "Source",
      //   "precursor_protein",
      //   "pub_date",
      // ]
      // static output_text = [
      //   '#', 'SPP ID', 'AA sequence', 'Peptide/Function name', 'AA length',
      //   'Peptide mass', 'Organism/Source', 'Precursor protein', 'PubDate',
      // ]
      // static output_ic50 = [ //only "item(s)" array
      //   "SPP_ID",
      //   "AA_sequence",
      //   "peptide_function_name",
      //   "AA_length",
      //   "peptide_mass",
      //   "IC50",
      //   "precursor_protein",
      //   "pub_date",
      // ]
      // static output_ic50_text = [
      //   '#', 'SPP ID', 'AA sequence', 'Peptide/Function name', 'AA length',
      //   'Peptide mass', 'IC50', 'Precursor protein', 'PubDate',
      // ]
      // static response = {
      //   search_category: 'search_category',
      //   total: 'total',
      // }
    },

    // 12 peptides_classification_quick_search
    quick_classify: class {
      static input(
        classification,
        filterSelection,
        filterValue,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          filterSelection,
          filterValue,
          lineSize,
          currentPage,
        };
      }

      static input_keyword(
        classification,
        filterSelection,
        filterValue,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          filterSelection,
          filterValue,
          keyWord,
          lineSize,
          currentPage,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "AA_length",
        "peptide_mass",
        "Source",
        "precursor_protein",
        "pub_date",
        'structure',
      ];
      static output_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "Peptide name",
        "AA length",
        "Mass",
        "Organism/Source",
        "Precursor protein",
        "PubDate",
        'Structure',
      ];
      static response = {
        search_category: "search_category",
        total: "total",
      };
    },

    //21 peptide_properties
    id: class {
      static input(classification, SPP_ID) {
        return {
          classification,
          SPP_ID,
        };
      }
      static output = [
        //_00card
        "SPP_ID",
        "peptide_sequence",
        "type",
        "term",
        //_0function
        "main_bioactivity", //[4]
        "otheir_bioactivity",
        //_1calculated
        "three_letter_amino_acid", //[6]
        "peptide_sequence", //=[1]
        "peptide_length",
        "peptide_mass", //[9] //object
        "net_charge",
        "isoelectric_point",
        "ic50", //[12]
        "pic50",
        "gravy",
        "hydrophilic_residue_ratio",
        //2
        "classification", //[16]
        "source",
        "precursor_protein",
        "residue_position",
        "precursor_protein_search", //[20] //object content move to api 77
        //3
        "activity", //[21]
        "specific_target_protein",
        //4
        "bitterness", //[23] //object
        //41
        "molstar",
        "smiles",
        //5
        "mode_of_preparation", //[25]
        "enzyme_culture",
        //6
        "peptide_stability", //[27] //object
        "peptide_cytotoxicity", //object
        //7
        "additional_information", //[29]
        //8
        "cross_references", //[30] //object
        //9
        "primary_literature", //[31] //array
        "other_literature",
        "pubdate",
        //2 ex
        "mol_weight", //[34]
        "log_p",
        "num_h_donors",
        "num_h_acceptors",
        "tpsa",
        "num_rotatable_bonds",
        "num_aromatic_rings",
        "num_aliphatic_rings",
        "num_saturated_rings",
        "num_heteroatoms",
        "num_valence_electrons",
        "num_radical_electrons",
        "qed",
      ];
      static output_text = [
        //_00card
        "SPP ID",
        "Peptide Sequence",
        "Type",
        "Peptide/Function Name",
        //_0function
        "Main BioactivIty",
        "Other BioactivIty",
        //_1calculated
        'Three-letter Amino Acid',
        'Single-letter Amino Acid',
        'Peptide Length',
        'Peptide Mass',
        'Net Charge',
        'Isoelectric Point (pl)',
        'IC50',
        'pIC50',
        'GRAVY',
        'Hydrophilic Residue Ratio',
        //2
        "Classification",
        "Organism/Source",
        "Precursor Protein",
        "Residue Position",
        "Precursor Protein(s) Search",
        //3
        "Activity",
        "Specific Target Protein(s)",
        //4
        "Bitness",
        //41
        "Molstar",
        "Smiles",
        //5
        "Mode of Preparation",
        "Enzyme(s)/starter Culture",
        //6
        "Peptide Stability",
        "Peptide Safety",
        // 'Literature report',
        //7
        "Additional information", //unused
        //8
        "Cross-references", //unused object
        //9
        "Primary Literature",
        "Other Literature(s)",
        "PubDate",
        //2 ex
        "Molecular weight",
        "LogP value",
        "Num H donors",
        "Num H acceptors",
        "TPSA",
        "Num rotatable bonds",
        "Num aromatic rings",
        "Num aliphatic rings",
        "Num saturated rings",
        "Num heteroatoms",
        "Num valence electrons",
        "Num radical electrons",
        "QED value",
      ];
      static peptide_mass = [
        //only object
        "experimental_mass",
        "theoretical_mass",
      ];
      static peptide_mass_text = ["Experimental Mass: ", "Theoretical Mass: "];
      static bitterness = ["literature_report", "bitter_prediction_tools"];
      static bitterness_text = [
        "Literature Report: ",
        "Bitter Prediction Tools: ",
      ];
      static peptide_stability = ["literature_report"];
      static peptide_stability_text = ["Literature Report: ", "SPP: "];
      static peptide_cytotoxicity = ["literature_report"];
      static peptide_cytotoxicity_text = [
        "Literature Report: ",
        "Prediction: ",
      ];
      static cross_references = [
        "SPP", //object
        "BIOPEP_UWM",
        "APD",
        "BioPepDB",
        "MBPDB",
      ];
      static cross_references_text = [
        "SPP",
        "BIOPEP-UWM",
        "APD",
        "BioPepDB",
        "MBPDB",
      ];
      static cross_references_link = [
        { "BIOPEP-UWM": "https://www.uwm.edu.pl/biochemia/index.php/pl/biopep" },
        { APD: "https://aps.unmc.edu/database" },
        { MBPDB: "https://mbpdb.nws.oregonstate.edu/peptide_search/" },
      ];
    },

    //21 peptide_properties
    id_multi: class {
      static input(classification, SPP_ID) {
        return {
          classification,
          SPP_ID,
        };
      }
      static output = [
        "SPP_ID",
        "peptide_sequence",
        "type",
        "term",
        //_0multifunctional +2
        "Function_Counts", //[4]
        "Multi_column_value", //object
        //_1physical +8
        "three_letter_amino_acid", //[6]
        "peptide_sequence", //=[1]
        "peptide_length",
        "theoretical_mass",
        "net_charge",
        "isoelectric_point",
        "gravy",
        "hydrophilic_residue_ratio",
        //2
        "precursor_protein_search", //[14] //object content move to api 77
        //3
        "bitterness", //[15] //object
        //31
        "molstar",
        "smiles",
        //4
        "", //[17]
        //5
        "cross_references", //[18] //object
      ];
      static output_text = [
        //dfbp
        "SPP ID",
        "Peptide Sequence",
        "Type",
        "Term",
        //_0multifunctional
        "Function Counts",
        "Multi Column Value", //unused
        //1
        "Three-letter Amino Acid",
        "Single-letter Amino Acid",
        "Peptide Length",
        "Theoretical Mass",
        "Net Charge",
        "Isoelectric Point (pl)",
        "GRAVY",
        "Hydrophilic Residue Ratio",
        //2
        "Precursor Protein(s) Search",
        //3
        "Bitness",
        //31
        "Molstar",
        "Smiles",
        //4
        "Peptide Stability",
        //5
        "Cross-references", //unused object
      ];
      static DFBPID = [
        "table_name",
        "source",
        "precursor_protein",
        "residue_position",
      ];
      static DFBPID_text = [
        "SPP ID",
        "Organism",
        "Precursor Protein",
        "Residue Position",
      ];
      static bitterness = ["literature_report", "bitter_prediction_tools"];
      static bitterness_text = [
        "Literature Report: ",
        "Bitter Prediction Tools: ",
      ];
      static cross_references = [
        "BIOPEP_UWM",
        "APD",
        "BioPepDB",
        "MBPDB",
      ];
      static cross_references_text = [
        "BIOPEP-UWM",
        "APD",
        "BioPepDB",
        "MBPDB",
      ];
      static cross_references_link = [
        { "BIOPEP-UWM": "https://www.uwm.edu.pl/biochemia/index.php/pl/biopep" },
        { APD: "https://aps.unmc.edu/database" },
        { MBPDB: "https://mbpdb.nws.oregonstate.edu/peptide_search/" },
      ];
    },

    //77 peptides_precursor_search 2.2.1
    id_precursor_result: class {
      static input(classification, SPP_ID) {
        return {
          classification,
          SPP_ID,
        };
      }
      static output = ["precursor_protein_search"];
      static precursor_protein_search = ["SPP_ID", "classification", "term"];
    },
    
    //88 peptide_target_protein
    id_target_protein: class {
      static input(classification, SPP_ID) {
        return {
          classification,
          SPP_ID,
        };
      }
      static output = [
        "specific_target_protein(s)",
        "potential_target_protein(s)",
      ]
      static target_proteins = [
        "target_name",
        "target_id",
      ]
    },
    
    //89 target_protein_page
    id_target_protein_page: class {
      static input(SPP_ID) {
        return {
          SPP_ID,
        };
      }
      static output = [
        "SPP_ID",
        "protein_name",
        "function",
        "target_gene",
        "diseases",
        "pathways",
        "additional_informations",
      ]
      static output_text = [
        "SPP ID",
        "Protein Name",
        "Function",
        "Target Gene",
        "Diseases",
        "Pathways",
      ]
    },

    //55 notion1.2.1 classification_advanced_search
    classification_advanced_search: class {
      static input(
        classification,
        filterSelection,
        filterValue,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          filterSelection,
          filterValue,
          lineSize,
          currentPage,
        };
      }
      static input_keyword(
        classification,
        filterSelection,
        filterValue,
        keyWord,
        lineSize = 10,
        currentPage = 1
      ) {
        return {
          classification,
          filterSelection,
          filterValue,
          keyWord,
          lineSize,
          currentPage,
        };
      }

      static output = [
        //only "item(s)" array
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "AA_length",
        "peptide_mass",
        "Source",
        "precursor_protein",
        "pub_date",
        'structure',
      ];
      static output_text = [
        "#",
        "SPP ID",
        "AA sequence",
        "Peptide name",
        "AA length",
        "Mass",
        "Organism/Source",
        "Precursor protein",
        "PubDate",
        'Structure',
      ];
      static response = {
        total: "total",
      };
    },

    //74 peptides_inductive_analysis 2.1.1.1
    inductive_analysis: class {
      static input(classification, sequence) {
        return {
          classification,
          sequence,
        };
      }
      static output = [
        "total",
        "search_category",
        // "item(s)", //used by common
      ];
      static output_text = [" results", "were reported for"];
      static items = [
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "Source",
        "precursor_protein",
        "pub_date",
      ];
      static items_text = [
        //items length+1
        "No.",
        "SPP ID",
        "AA sequence",
        "Peptide/Function name",
        "Organism/Source",
        "Precursor protein",
        "PubDate",
      ];
      static items_ic50 = [
        "SPP_ID",
        "AA_sequence",
        "peptide_function_name",
        "IC50",
        "precursor_protein",
        "pub_date",
      ];
      static items_text_ic50 = [
        //items length+1
        "No.",
        "SPP ID",
        "AA sequence",
        "Peptide/Function name",
        "IC50",
        "Precursor protein",
        "PubDate",
      ];
    },
  },
};

//---------------------------------------------------------------------------
// tool
const tool = {
  //38 proteins_name
  hot_spot: class {
    static input(class_code, source_code, start_index, line_size = 300) {
      return {
        class_code: Number(class_code),
        source_code: Number(source_code),
        start_index: start_index,
        line_size: line_size,
      };
    }

    static items = ["protein_name", "SPP_ID"];
  },

  //29 hotspot_result
  hot_spot_result: class {
    static input(SPP_ID, search_fragment) {
      return {
        SPP_ID,
        search_fragment,
      };
    }
    static output = [
      "Organism",
      "Protein_name",
      "SPP_ID",
      "Database_reference", //[1]
      "Peptide_sequence",
      "Highlight sequence", //[3]
      "BLAST results", //[4]
      "Sequence location",
      "The reported activity of the peptide",
      "Enzymatic Hydrolysis",
    ];
    static output_text = [
      "Organism",
      "Protein name",
      "SPP ID",
      "Database",
      "Peptide sequence",
      "Highlight sequence",
      "BLAST result",
      "Sequence Location",
      "The reported activity of the peptide",
      "Enzymatic Hydrolysis",
    ];
    static the_reported = ["display_name", "peptide_id"];
  },

  //42 multi_hotspot chart 4.1.1
  hot_spot_result_chart: class {
    static input(source_codes, search_fragment) {
      return {
        source_codes: source_codes,
        search_fragment,
      };
    }
    static input_all(search_fragment) {
      return {
        search_fragment,
        is_search_all: Boolean(true),
      };
    }
    // is_advanced=1
    static input_adv(search_fragment, source_codes, is_search_all = false, lineSize = 10, currentPage = 1) {
      return {
        is_advanced: Boolean(true),
        search_fragment,
        is_search_all: Boolean(is_search_all),
        source_codes: source_codes,
        lineSize,
        currentPage,
      };
    }
    static input_adv_keyword(search_fragment, source_codes, is_search_all, keyWord, lineSize = 10, currentPage = 1) {
      return {
        is_advanced: Boolean(true),
        search_fragment,
        is_search_all: Boolean(is_search_all),
        source_codes: source_codes,
        keyWord,
        lineSize,
        currentPage,
      };
    }
    
    static output = [
      "Peptide SPP ID", //value from ToolHotSpotResultChart.jsx
      "Peptide Sequence",
      "Total protein",
      "Count result",
    ];
    static output_text = [
      "Peptide SPP ID",
      "Peptide Sequence",
      "Total protein",
    ];
    static items = ["Term", "Count results"];
    // is_advanced=1
    static items_adv = [
      "SPP_ID",
      "Term",
      "length",
      "classification",
      "source",
    ]
    static items_adv_text = [
      "#",
      "SPP ID",
      "Term",
      "Length",
      "Classification",
      "Organism/Source",
    ]
  },
  
  //66 hotspot_highlight 2.1.1.3
  hotspot_result_precursor: class {
    static input(Peptide_SPP_ID, Protein_SPP_ID, search_fragment) {
      return {
        Peptide_SPP_ID,
        Protein_SPP_ID,
        search_fragment,
      };
    }

    static output = [
      "Peptide_SPP_ID",
      "Fragments",
      "Peptide term",
      "Peptide main activity",
      "Protein_SPP_ID",
      "Protein source",
      "Protein term",
      "", //title "Protein sequence"
      "Protein sequence", //code "Protein sequence"
      "Position",
      "Database references",
      "Enzymatic Hydrolysis",
    ];
    static output_text = [
      "Peptide SPP ID",
      "Peptide sequence",
      "Peptide term",
      "Peptide activity",
      "Protein SPP ID",
      "Organism",
      "Protein",
      "Protein sequence", //title "Protein sequence"
      "", //code "Protein sequence"
      "Search result",
      "UniPort",
      "Enzymatic Hydrolysis",
    ];
  },

  //18 properties_calculator
  peptide: class {
    static input(keyword) {
      return {
        keyword,
      };
    }

    static output = [
      "sequence",
      "threecode",
      "length",
      "weight",
      "pi",
      "netcharge",
      "gravyscore",
      "ratio",
      "extinction",
      "absorb",
      //chart
      // "statistics",
    ];
    static output_chart = ["statistics"];
    static output_text = [
      "Sequence",
      "3-Letter amino acid",
      "Length",
      "Molecular weight",
      "pI",
      "Net charge (pH=7.0)",
      "GRAVY",
      "Hydrophilic residue ratio",
      "Extinction coefficients",
      "Absorb coefficients",
    ];
    static chart_output = [
      //only "statistics" array
      "negative",
      "positive",
      "difference", //net
      "ph",
    ];
    static response = [];
  },

  //49 enzymatic_tool 4.2
  ehp_tool_enzymes_list: class {
    static output = ["SPP_ID", "Term"];
  },

  //50 enzy_page 4.2
  ehp_tool_enzyme_page: class {
    static input(SPP_ID) {
      return {
        SPP_ID,
      };
    }
    static output = ["page_info"];
    static items = [
      "SPP_ID",
      "ECname",
      "Term",
      //Introduction
      "Accepted Name",
      "Cleavage sites",
      //Cross-references
      "Database(s)",
      //Reference(s)
      "Literature(s)",

      "sites value", //?
      "Addition infor", //?
    ];
    static items_text = [
      "SPP ID",
      "EC Number",
      "Term",
      //Introduction
      "Accepted Name",
      "Cleavage sites",
      //Cross-references
      "Database(s)",
      //Reference(s)
      "Literature(s)",

      "sites value",
      "Addition infor",
    ];
  },

  //64 enzymatic_hydrolysis
  ehp_tool_result: class {
    static input(SPP_ID, enzyme) {
      return {
        SPP_ID,
        enzyme,
      };
    }
    static input_keyword(sequence, enzyme) {
      return {
        sequence,
        enzyme,
      };
    }
    static output = [
      // _0results
      "available_enzyme_count",
      "cleavage_site_count",
      "peptide_fragment_count",
      "unavailable_enzyme_count",
      //_1information
      "protein_peptide", //[4]
      "organism",
      "sequence",
      "", //'Protein/Peptide length',
      //_2enzyme
      "available", //[8] //array
      "unavailable", //array
      //_3hydrolyzed
      "cleavage_site", //[10]
      "cleavage_map",
      "", //'Filter',
    ];
    static output_text = [
      "Available enzyme(s)",
      "Cleavage site(s)",
      "Peptide fragment(s)",
      "Unavailable enzyme(s)",
      //_1information
      "Protein/Peptide",
      "Organism(s)",
      "Sequence",
      "Protein/Peptide length",
      //_2enzyme
      "Available enzyme(s)",
      "Unavailable enzyme(s)",
      //_3hydrolyzed
      "Cleavage site(s)",
      "Cleavage map",
      "Filter",
    ];
    static available = [
      "name",
      "number_of_cleavages",
      "positions_of_cleavage_sites",
      "", //'More infos',
      "DFBPID_enzyme",
    ];
    static available_text = [
      "Name of enzyme",
      "Number of cleavages",
      "Positions of cleavage sites",
      "More infos",
      "", //noshow
    ];
    static unavailable = [
      "name",
      "number_of_cleavages",
      "positions_of_cleavage_sites",
      "DFBPID_enzyme",
    ];
    static unavailable_text = [
      "Name of enzyme",
      "Number of cleavages",
      "Positions of cleavage sites",
      "", //noshow
    ];
  },

  //68 enzyme_peptide_result
  ehp_tool_result_peptide: class {
    static input(SPP_ID, enzyme) {
      return {
        SPP_ID,
        enzyme,
      };
    }
    static input_keyword(sequence, enzyme) {
      return {
        sequence,
        enzyme,
      };
    }
    static output = [
      "active_fragment_count",
      "peptide_result", //array
    ];
    static output_text = ["Active fragment(s)", ""];
    static peptide = [
      "", //'Peptide:',
      "position",
      "fragment",
      "length",
      "activity", //array
      "bitter",
      "classification", //array
    ];
    static peptide_text = [
      "Peptide:",
      "Position",
      "Fragment(s)",
      "Length",
      "Activity",
      "Bitter",
    ];
  },

  //72 statistical_double_table 4.6.1
  statistical_nc_table: class {
    static output = [
      "amino_acids", //array
      "features", //object
    ];
    static peptides = [
      "display_name",
      "n_terminal", //array
      "c_terminal", //array
    ];
  },

  //73 statistical_single_table 4.6.1
  statistical_a_table: class {
    static output = [
      "amino_acids", //array
      "features", //object
    ];
    static peptides = [
      "display_name",
      "percentage", //array
    ];
  },

  //75 statistical_double_graph 4.6.2
  statistical_nc_chart: class {
    static input(letter) {
      return {
        letter,
      };
    }
    static output = [
      "letter",
      "three_letter",
      "features", //object
    ];
    static peptides = ["display_name", "n_terminal", "c_terminal"];
  },

  //76 statistical_single_graph 4.6.2
  statistical_a_chart: class {
    static input(letter) {
      return {
        letter,
      };
    }
    static output = [
      "letter",
      "three_letter",
      "features", //object
    ];
    static peptides = ["display_name", "percentage"];
  },

  //87 unique_letter_search_input 4.9
  css_search_input: class {
    static items = {
      protein_source: "protein_source", //object array
      peptide_class: "peptide_classification", //object array
    };
    static protein_source = {
      class: "classification",
      class_id: "classification_id",
      source: "source",
      source_id: "source_id",
    };
    static peptide_class = {
      class: "classification",
      class_id: "classification_id",
      class_col: "classification_col",
    };
  },
  //86 unique_letter_search
  css_tool_result: class {
    static input(
      source_code_list,
      table_name_list,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        'table_name_list': table_name_list,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_keyword(
      source_code_list,
      table_name_list,
      keyWord,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        'table_name_list': table_name_list,
        keyWord,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment(
      source_code_list,
      search_fragment,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        search_fragment,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment_keyword(
      source_code_list,
      search_fragment,
      keyWord,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        search_fragment,
        keyWord,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment_lv3(
      source_code_list,
      specified_table,
      search_fragment,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        source_code_list,
        specified_table,
        search_fragment,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment_keyword_lv3(
      source_code_list,
      specified_table,
      search_fragment,
      keyWord,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        source_code_list,
        specified_table,
        search_fragment,
        keyWord,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_venn(
      source_code_list,
      table_name_list,
      intersection_list,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        'table_name_list': table_name_list,
        'intersection_list': intersection_list,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_venn_keyword(
      source_code_list,
      table_name_list,
      intersection_list,
      keyWord,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        'table_name_list': table_name_list,
        'intersection_list': intersection_list,
        keyWord,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment_venn(
      source_code_list,
      search_fragment,
      intersection_list,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        search_fragment,
        'intersection_list': intersection_list,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    static input_fragment_venn_keyword(
      source_code_list,
      search_fragment,
      intersection_list,
      keyWord,
      lineSize = 10,
      currentPage = 1,
      sortObject = "SPP_ID",
      orderBy = "DESC"
    ) {
      return {
        'source_code_list': source_code_list,
        search_fragment,
        'intersection_list': intersection_list,
        keyWord,
        sortObject,
        orderBy,
        lineSize,
        currentPage,
      };
    }
    
    static items = [
      "SPP_ID",
      "AA_sequence",
      "peptide_function_name",
      "AA_length",
      "peptide_mass",
      // "IC50",
      "precursor_protein",
      "pub_date",
    ];
    static items_text = [
      "#",
      "SPP ID",
      "AA sequence",
      "Peptide name",
      "AA length",
      "Mass",
      // "IC50",
      "Precursor protein",
      "PubDate",
    ];
    static items_multi = [
      "SPP_ID",
      "AA_sequence",
      "AA_length",
      "function_counts",
      "multifunctional_activities",
      "peptide_mass",
      // "table_name",
    ]
    static items_text_multi = [
      "#",
      "SPP ID",
      "AA sequence",
      "AA length",
      "Function counts",
      "Multifunctional activities",
      "Peptide mass",
    ];
    static fragment_multi =[
      "other(s)",
      "multi_total",
    ]
  },
  //91 unique_letter_chart (T23)Endpoint - Unique Letter Chart
  css_tool_result_lv1_bar_chart: class {
    static input(source_code_list, table_name_list) {
      return {
        'source_code_list': source_code_list, //array
        'table_name_list': table_name_list, //array
      };
    }
    static input_fragment(source_code_list, search_fragment) {
      return {
        'source_code_list': source_code_list, //array
        search_fragment,
      };
    }
    static output = [
      "total",
      "peptide_count", //array
    ];
    static items = [
      "source_name", 
      "source_code", 
      "count",
      "count_list", //array
      "label_list", //array
      "label_code", //array
    ];
    static peptide_count = [
      "table_name",
      "display_name",
      "source_code_list",
      "table_count",
    ]
  },
  //90 intersection_chart (T25)Endpoint - Intersection Chart
  css_tool_result_lv1_venn_diagram: class {
    static input(source_code_list, table_name_list) {
      return {
        'source_code_list': source_code_list, //array
        'table_name_list': table_name_list, //array
      };
    }
    static output = [
      "total",
      "peptide(s)",
    ]
    static items = [
      "source_name",
      "source_code",
      "peptide_sequence",
    ]
    /* static peptides = [
      "table_name",
      "display_name",
    ] */
  },
  //96 intersect_duplicate_chart
  css_tool_result_lv1_venn_diagram_all: class {
    static input(source_code_list, table_name_list) {
      return {
        'source_code_list': source_code_list, //array
        'table_name_list': table_name_list, //array
      };
    }
    static input_fragment(source_code_list, search_fragment) {
      return {
        'source_code_list': source_code_list, //array
        search_fragment,
      };
    }
    static output = [
      "total",
      "peptide(s)",
    ]
    static items = [
      "count",
      "label(s)",
      "code(s)", //array
    ]
    /* static peptides = [
      "table_name",
      "display_name",
    ] */
  },
  //92 specified_protein_chart (T24)Endpoint - Specified Protein Chart
  //95 protein_duplicate_chart
  css_tool_result_lv2_bar_chart: class {
    static input(source_code, table_name_list) {
      return {
        source_code,
        'table_name_list': table_name_list, //array
      };
    }
    static input_fragment(source_code, search_fragment) {
      return {
        source_code,
        search_fragment,
      };
    }
    static output = ["total"];
    static items = [
      "source_name",
      "source_code",
      "table_name",
      "display_name",
      "peptide_sequence", //array
      "count",
    ];
  },
  
  //98 pdcaas_drop_down
  pdcaas_list: class {
    static items = [
      "num",
      "Description",
    ]
  },
  
  //97 dpqehn_list 4.7.1
  pdcaas_table: class {
    static input(lineSize = 10, currentPage = 1) {
      return {
        lineSize,
        currentPage,
      };
    }
    static input_keyword(keyWord, lineSize = 10, currentPage = 1) {
      return {
        keyWord, 
        lineSize,
        currentPage,
      };
    }
    static items = [
      //from image
      // "num", //no show
      "Description",
      "NDBNumber",
      "Digestibility",
      "ProteinAmount",
      "Lysine",
      "Methionine",
      "Cystine",
      "Threonine",
      "Tryptophan",
      //from DB
      "Valine",
      "Arginine",
      "Alanine",
      "AsparticAcid",
      "GlutamicAcid",
      "Glycine",
      "Proline",
      "Serine",
      "Lys_digestibility",
      "SAA_digestibility",
      "Thr_digestibility",
      "Trp_digestibility",
      "more", //customize for "More",
    ]
    static items_text = [
      "#",
      //from image
      "Description",
      "NDBNumber",
      "Digestibility",
      "Amount(g/100g)",
      "Lysine",
      "Methionine",
      "Cystine",
      "Threonine",
      "Tryptophan",
      //from DB
      "Valine",
      "Arginine",
      "Alanine",
      "Aspartic Acid",
      "Glutamic Acid",
      "Glycine",
      "Proline",
      "Serine",
      "Lys Digestibility",
      "SAA Digestibility",
      "Thr Digestibility",
      "Trp Digestibility",
      "More", //link
    ]
  },
  
  //99 pdcaas_tool
  pdcaas_result: class {
    static input(pdcaas_num, weight, method) {
      return {
        pdcaas_num, //array
        weight, //array
        'method': Number(method), //(1:pdcaas, 2:diaas , else:both)
      }
    }
    static output = {
      pdcaas: "pdcaas",
      diaas: "diaas",
    }
    static method = [
      "age_group",
      "total",
      "amino_acid",
    ]
    static pdcaas_items = [
      //Analytical
      "organism",
      "weight",
      "amount", //"protein",
      "tryptophan",
      "threonine",
      "lysine",
      "saa",
      //Digsestible
      "digestibility",
      "protein_digest",
      "trp_digest",
      "thr_digest",
      "lys_digest",
      "saa_digest",
    ]
    static pdcaas_items_text = [
      //Analytical
      'Organism', 'Weight', 'Protein', 'Typ', 'Thr', 'Lys', 'SAA', 
      //Digsestible
      'Digestibility', 'Protein', 'Typ', 'Thr', 'Lys', 'SAA', 
    ]
    static pdcaas_total = [
      "protein_total",
      "trp_total",
      "thr_total",
      "lys_total",
      "saa_total",
    ]
    static pdcaas_amino_acid = [
      "protein_avg",
      "trp_avg",
      "thr_avg",
      "lys_avg",
      "saa_avg",
    ]
    static pdcaas_avg = [
      "digest_avg",
      "protein_avg", //unused
      "trp_avg", //unused
      "thr_avg", //unused
      "lys_avg", //unused
      "saa_avg", //unused
    ]
    //---------------------------------------------------------------------------
    // v2
    static pdcaas2_result = [
      "trp_digest",
      "thr_digest",
      "Iso",
      "Leu",
      "lys_digest",
      'Met_Cys',
      'Phe_Tyr',
      'Val',
      'His',
    ]
    static pdcaas2_table = [
      "organism",
      "Proportion",
      ...this.pdcaas2_result,
    ]
    static pdcaas2_result_text = [
      'Trp',
      'Thr',
      'Iso',
      'Leu',
      'Lys',
      'Met+Cys', //"Methionine" "Cystine",
      'Phe+Tyr', //"Proline",
      'Val', //"Valine",
      'His',
    ]
    static pdcaas2_table_text = [
      'Organism',
      'Proportion',
      ...this.pdcaas2_result_text,
    ]
    
    //---------------------------------------------------------------------------
    static diaas_items = [
      //Composition
      "organism",
      "weight",
      "amount", //"protein",
      "lysine",
      "saa",
      "threonine",
      "tryptophan",
      //IAA
      "lys_digest",
      "saa_digest",
      "thr_digest",
      "trp_digest",
      "protein_contain",
      //mixture
      "lys_mixture",
      "saa_mixture",
      "thr_mixture",
      "trp_mixture",
    ]
    static diaas_items_text = [
      //Composition
      'Organism', 'Weight', 'Protein', 'Lys', 'SAA', 'Thr', 'Trp', 
      //IAA
      'Lys', 'SAA', 'Thr', 'Trp', 'Protein content in mixture',
      //mixture
      'Lys', 'SAA', 'Thr', 'Trp',
    ]
    static diaas_total = [
      "weight_total",
      '', '', '',
      '', '', '',
      '', '', '',
      "protein_total",
      "lys_total",
      "saa_total",
      "thr_total",
      "trp_total",
    ]
    static diaas_amino_acid = [
      "lys_avg",
      "saa_avg",
      "thr_avg",
      "trp_avg",
    ]
    
    static age_group = [
      'years', //for 'Age group (years)',
      "trp_pattern",
      "thr_pattern",
      "lys_pattern",
      "saa_pattern",
      "trp_mix",
      "thr_mix",
      "lys_mix",
      "saa_mix",
      "aa_score",
    ]
    static age_group_text(name) {
      return [
      'Age group (years)',
      'Trp', 'Thr', 'Lys', 'SAA', 
      'Trp', 'Thr', 'Lys', 'SAA', 
      `${name} value:\nlowest score x digestibility`,
    ]}
  },
  
  //100 predictor_stat_chart
  aceipp_anoxpp_seq_chart: class {
    static input(dataset, method, ind = 0) {
      return {
        'dataset': Number(dataset), //(1:ACEiPs, 2:AnOxPs)
        'method': Number(method), //(1:Length, 2:Mass, 3:Terminal, 4:Composition)
        'ind': Number(ind), //0=all
      }
    }
    static dataset = {
      ACEiPs: 1,
      AnOxPs: 2,
    }
    static method = {
      Length: 1,
      Mass: 2,
      Terminal: 3,
      Composition: 4,
    }
    static output = [
      "pos_total",
      "neg_total",
      "x_axis",
      "pos_value",
      "neg_value",
    ]
  },
  
  //101 predictor_pool_list
  aceipp_anoxpp_pre_pool_table: class {
    static input(dataset, ind = 0) {
      return {
        'dataset': Number(dataset), //(1:ACEiPs, 2:AnOxPs)
        'ind': Number(ind), //0=all
      }
    }
    static dataset = {
      ACEiPs: 1,
      AnOxPs: 2,
    }
    static items_all = [
      "enzyme_no",
      "enzyme",
      "probability",
      "count",
      "pool_name",
      "download", //for Download
    ]
    static items_all_txt = [
      'Enzyme(s)',
      '',
      'Probability',
      'Unique Sequences',
      'Prediction Pools',
      'Download',
    ]
    static items = [
      "pool_name",
      "enzyme",
      "probability",
      "count",
      "download",
      "ind",
    ]
    static items_text = [
      'Prediction Pool',
      'Enzyme(s)',
      'Probability',
      'Unique Sequences',
      'Download',
    ]
  },
  
  //102 predictor_manual_input
  aceipp_anoxpp_manual_result: class {
    static input(dataset, input) {
      return {
        'dataset': Number(dataset), //(1:ACEiPs, 2:AnOxPs)
        input,
      }
    }
    static dataset = {
      ACEiPs: 1,
      AnOxPs: 2,
    }
    static output = [
      "start_time",
      "cost_time",
      "total",
      "error_record",
      "pos_record",
      "neg_record",
    ]
    static items = [
      "name",
      "sequence",
      "pre_score",
      "pre_activity",
      "length",
      "weight",
      "pi",
      "ratio",
      "mutation", //for link
      'peptide', //for link
    ]
    static items_text = [
      'No.',
      'Name',
      'Sequence',
      'Pre-Score',
      'Pre-Activity',
      'Length',
      'Mass (Da)',
      'pI',
      'Hydrophilicity',
      'Mutation',
      'Peptide',
    ]
    static items_error = "error_resp"
  },
  
  //103 predictor_mutation
  aceipp_anoxpp_manual_mutation: class {
    static input(dataset, search_fragment, site = 1) {
      return {
        'dataset': Number(dataset), //(1:ACEiP, 2:AnOxP)
        search_fragment,
        'site': Number(site),
      }
    }
    static dataset = {
      ACEiP: 1,
      AnOxP: 2,
    }
    static items = [
      "sequence",
      "pre_score",
      "pre_activity",
      "length",
      "weight",
      "pi",
      "ratio",
      "mutation", //for link
      'peptide', //for link
    ]
    static items_text = [
      'No.',
      'Sequence',
      'Probability',
      'Pre-Activity',
      'Length',
      'Mass (Da)',
      'pI',
      'Hydrophilicity',
      'Mutation',
      'Peptide',
    ]
  },
  
  //104 predictor_upload_file
  aceipp_anoxpp_upload_result: class {
    static input(dataset) {
      return {
        'dataset': Number(dataset), //(1:ACEiPs, 2:AnOxPs)
      }
    }
    static dataset = {
      ACEiPs: 1,
      AnOxPs: 2,
    }
    static output = [
      "start_time",
      "cost_time",
      "total",
      "error_record",
      "pos_record",
      "neg_record",
      "error_item(s)",
    ]
    static items = [
      'Data Sets',
      "name",
      "total_record",
      "right",
      "error",
      "positive",
      "negative",
      "link",
    ]
    static items_text_aceipp = [
      'Data Sets',
      'Name',
      'Total',
      'Right',
      'Error',
      'ACEiPs',
      'non-ACEiPs',
      'Download',
    ]
    static items_text_anoxpp = [
      'Data Sets',
      'Name',
      'Total',
      'Right',
      'Error',
      'AnOxPs',
      'non-AnOxPs',
      'Download',
    ]
    static error_items = [
      "error_resp",
      "name",
      "sequence",
    ]
  }
};
//---------------------------------------------------------------------------
const common = {
  output: {
    items: "item(s)",
    table_name: "table_name",
  },
  response: {
    msg: "message",
    code: "result_code",
    reason: "reason",
  },
  total: "total",
};

const spp = {
  protein,
  peptide,
  tool,

  common,
};

//---------------------------------------------------------------------------
const jsons = {
  headers,
  /* body */
  auth, //user
  site,
  spp,

  common,
};
export { headers, spp };
export default jsons;
