// -----official tools & third tools-------------------------------------------------------
import Paper from '@mui/material/Paper';
import { Table, TableContainer } from "@mui/material";

// -----custom tools-------------------------------------------------------
import tsbScss from "components/tool/ToolSingleBlock.module.scss";
import { MuiTableContainer } from "components/table/MuiTable";
import scssTable from 'components/table/TableV2.module.scss'


//---------------------------------------------------------------------------
const ToolSingleBlock = ({ stepText, title, mainBlockContent, anotherBlockContent, ...props }) => {
  // -----variables-------------------------------------------------------

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------
  return (
    <MuiTableContainer>
      <tbody style={{border:'none'}}>
        <tr >
          <td>
            <div className={tsbScss.main}>
              <div className={tsbScss.left_block}>
                <div className={tsbScss.step_text}>
                  {stepText}
                </div>
                <div className={tsbScss.title}>
                  {title}
                </div>
              </div>
              <div className={tsbScss.right_block}>
                <div className={tsbScss.main_block}>{mainBlockContent}</div>
                {anotherBlockContent && <div className={tsbScss.main_block}>{anotherBlockContent}</div>}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </MuiTableContainer >
  )
};

export default ToolSingleBlock;