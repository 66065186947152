import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Tooltip } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import BreadResult from "components/bread/BreadResult";
import scss from './BreadBar.module.scss';
import scssProteins from 'views/protein/ProteinList.module.scss';
import paths from 'utils/network/apiPath';
import Text, { TextLink } from 'components/tag/Text';
import Breadcrumb from './Breadcrumb';
import BreadText from './BreadText';
import BreadButton from './BreadButton';
import jsons, { spp } from 'utils/network/jsons';

//---------------------------------------------------------------------------
/* const texts = {
  lv1: 'Home',
  lv2: 'Proteins', //move inside content
  title: 'Food-borne proteins', //move inside content
} */

export const initBreadTextFontSize = 46
export class breadData {
  static create(text, title, text3rd = '', text4st = '', text5st = '', fontSize = initBreadTextFontSize) {
    return { text, title, text3rd, text4st, text5st, fontSize }
  }
  static getFontSize(breadText) {
    const len = breadText.length
    let breadTextFontSize = initBreadTextFontSize
    if (len > 46 && len < 56)
      breadTextFontSize = 38
    else if (len >= 56)
      breadTextFontSize = 30
    return breadTextFontSize
  }
}

const proteinResultBoxPath = [
  paths.spp.protein.class_search(),
  paths.spp.protein.class_count(),
  paths.spp.protein.source_search(),
  paths.spp.protein.quick_search(),
  paths.spp.protein.fragment_count(),
]
const peptideResultBoxPath = [
  paths.spp.peptide.multi_search(),
  paths.spp.peptide.class_search(),
  // paths.spp.peptide.fragment_count, //?
]
const pathPeptideQuickResultBox = [
  paths.spp.peptide.quick_search(),
]
const toolDescBoxPath = [
  paths.spp.tool.hotspot_search(),
  paths.spp.tool.aasd_tool(),
  paths.spp.tool.peptide_calculator(),
  paths.spp.peptide.multi_search(),
  paths.spp.tool.multi_cross_analysis(),
  paths.spp.tool.ehp_tool_search(),
]
const pathToolACEiPP = [
  paths.spp.tool.aceipp(),
  paths.spp.tool.aceipp_sf(),
  paths.spp.tool.aceipp_pl(),
]
const pathToolAnOxPP = [
  paths.spp.tool.anoxpp(),
  paths.spp.tool.anoxpp_sf(),
  paths.spp.tool.anoxpp_pl(),
]
const pathToolACEiPPAnOxPP = [ //ACEiPP and AnOxPP share together
  paths.spp.tool.aceipp_anoxpp_pp(),
]

//---------------------------------------------------------------------------
export default function BreadBar(props) {
  const { bread } = props
  //console.log('BreadBar', props);

  const [showBreadResult, setShowBreadResult] = useState(false);
  const [showBreadButton, setShowBreadButton] = useState(false)
  const [showToolsDesc, setShowToolsDes] = useState(false);
  
  const [isProteinSearch, setIsProteinSearch] = useState(false);
  const [isPeptideMultiSearch, setIsPeptideMultiSearch] = useState(false);
  const [isPeptideQuickSearch, setIsPeptideQuickSearch] = useState(false);
  const [isToolACEiPP, setIsToolACEiPP] = useState(false)
  const [isToolAnOxPP, setIsToolAnOxPP] = useState(false)

  const [searchParams] = useSearchParams()
  const dataset = searchParams.get(paths.params.dataset)
  // console.log('dataset', dataset);
  
  let location = useLocation();
  useEffect(() => {
    let pathname = location.pathname
    if ( proteinResultBoxPath.includes(location.pathname) 
      || peptideResultBoxPath.includes(location.pathname)
      || pathPeptideQuickResultBox.includes(location.pathname) ) {
      setShowBreadResult(true)
      proteinResultBoxPath.includes(location.pathname) ? setIsProteinSearch(true) : setIsProteinSearch(false)
      peptideResultBoxPath[0].includes(location.pathname) ? setIsPeptideMultiSearch(true) : setIsPeptideMultiSearch(false)
      pathPeptideQuickResultBox.includes(location.pathname) ? setIsPeptideQuickSearch(true) : setIsPeptideQuickSearch(false)
    } else {
      setShowBreadResult(false)
    }
    
    if( pathToolACEiPP.includes(pathname) ) {
      setShowBreadButton(true)
      setIsToolACEiPP(true)
    }
    if( pathToolAnOxPP.includes(pathname) ) {
      setShowBreadButton(true)
      setIsToolAnOxPP(true)
    }
    if( pathToolACEiPPAnOxPP.includes(pathname) ) {
      setShowBreadButton(true)
      const jsonsTable = spp.tool.aceipp_anoxpp_pre_pool_table
      const datasetList = jsonsTable.dataset
      const datasetNum = Number(dataset)
      if( datasetNum === datasetList.ACEiPs )
        setIsToolACEiPP(true)
      else if( datasetNum === datasetList.AnOxPs )
        setIsToolAnOxPP(true)
    }
    
    if ( toolDescBoxPath.includes(location.pathname) ) {
      setShowToolsDes(true)
    } else {
      setShowToolsDes(false)
    }

    return () => {
      // cleanup
    };
  }, [location]);

  return (
    <div className={scssProteins.frame_top}>
      <div className={scss.top_navbar}></div>
      <Breadcrumb bread={bread} />
      <BreadText bread={bread} />

      {showBreadResult
        ? <BreadResult
          searchInput={props.searchInput} jsonInput={props.jsonInput}
          searchOutput={props.searchOutput}
          isProteinSearch={isProteinSearch}
          isPeptideMultiSearch={isPeptideMultiSearch}
          isPeptideQuickSearch={isPeptideQuickSearch}
          />
        : null}
      { showBreadButton
        ? <BreadButton
            isToolACEiPP={isToolACEiPP}
            isToolAnOxPP={isToolAnOxPP}
          />
        : null}
      {showToolsDesc
        ?
        <div className={scssProteins.top_desc}>
          <Text className={'b3-grey100'} fontSize={16}>
            <Tooltip title={props.tooltipOfDesc !== undefined ? props.tooltipOfDesc : props.desc} placement="top">
              {props.desc}</Tooltip></Text>
        </div>
        : null}
    </div>
  )
}