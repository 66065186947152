import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import 'assets/scss/indexV2.scss'

//---------------------------------------------------------------------------
const StyledTab = styled((props) =>
  <Tab disableRipple
    // orientation="vertical"
    sx={{ textTransform: "none", color:"var(--white-white-75)" , display: "flex", alignItems: "flex-start", textAlign: "left",fontFamily:"Quantico" }}
    {...props} />)(
      ({ theme }) => ({
        '&.Mui-selected': {
          borderRadius: '4px',
          // backgroundColor: '#A28F9D',
          color:'var(--white-white)',
          fontWeight: 600,
          background: 'linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%)',
        },
      }),
    );


export default StyledTab
