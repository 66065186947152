import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import scss from "./ProteinClassGraph.module.scss";
import scssTable from 'components/table/TableV2.module.scss'
import LayoutPage from 'layouts/LayoutPage';
import { Box, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TagText } from 'components/tag/Text';
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray } from 'utils/general';
import axios, { apiConfig, axiosCatch, axiosState } from "utils/network/axios";
import { sortByKey } from 'utils/object';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// path: paths.spp.protein.class_graph(),
// http://localhost:3000/protein_cls_graph
const fontSizeOfLongText = 30;


//---------------------------------------------------------------------------
const ProteinClassGraph = ({ setInfo }) => {


  const [pageProteinName, setPageProteinName] = useState("")

  const [pageSelectedProteinID, setPageSelectedProteinID] = useState(0)
  const [pageSelectedProteinType, setPageSelectedProteinType] = useState("") // "cls" or "source" or "" (Means all proteins)

  const [peptideClsObj, setPeptideClsObj] = useState([]) //peptide_list data

  const [peptideClsLabels, setPeptideClsLabels] = useState([])
  const [peptideClsValue, setPeptideClsValue] = useState([])
  
  const [selectedSeqIndex, setSelectedSeqIndex] = useState(null)

  const [peptideSum, setPeptideSum] = useState(0)
  const [totalSeq, setTotalSeq] = useState(0)

  const [isSelectedTotalSeq, setIsSelectedTotalSeq] = useState(false)

  const [letterObj, setLetterObj] = useState({})
  const [selectedPeptideClsName, setSelectedPeptideClsName] = useState("")

  const totalSeqKey = "total peptides"


  let texts = {
    bread: breadData.create('Protein', `Unique sequence analysis of bioactive peptides encoded in ${pageProteinName}  proteins`, '', '', '', fontSizeOfLongText),

    blockTitle: {
      viewChart: "View chart",
      viewSequences: "View Sequences"
    },

    title: {
      peptides: "Peptide List",
      counts: "Counts",
      chartSeq: 'Chart/Sequences',

    },

    tableHeaders: ["No", "Peptide", "Counts", "Sequence"]
  }

  const words = {
    chart_fragment: {
      title: `The count of unique sequences of bioactive peptides encoded in ${pageProteinName} proteins`,
      text: `Sum results: `,
      text2: `Peptide sequences results: `,
      x: 'Classification',
      y: 'Fragment count',
    }
  }

  const chartFragmentOptions = chartProp.options.axisTitleXY(words.chart_fragment.x, words.chart_fragment.y)
  const chartFragmentData = chartProp.data.carate(
    '',
    '',
    [''],
    peptideClsLabels,
    peptideClsValue
  )

  const rowChildren = (row, index) => {
    return row === undefined ? null :
      (
        <TableRow key={index} className={scss.each_row}
          style={{ cursor: row[1] === 0 ? 'default' : "pointer" }}
          onClick={
            (event) => {
              // setIsSelectedTotalSeq(false)
              if ((index !== selectedSeqIndex || isSelectedTotalSeq) && row[1] !== 0) {
                setSelectedSeqIndex(index)
                setSelectedPeptideClsName(row[0])
                setIsSelectedTotalSeq(false)
              }
            }
          }
          data-selected={index === selectedSeqIndex}
        >
          <MuiTableCell align="center">{index + 1}</MuiTableCell>
          <MuiTableCell>
            {row[0]}
          </MuiTableCell>
          <MuiTableCell align="right">{row[1]}</MuiTableCell>
        </TableRow>
      )
  }

  const MuiTableCell = (props) => (
    <StyledTableCell style={{ fontSize: 16, whiteSpace: 'pre-line' }} {...props}>
      {props.children}
    </StyledTableCell>
  )
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const getPeptideClsList = (input) => {

    const config = apiConfig.protein.class_graph(input)
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data["items"]

        if (isEmptyObjOrArray(data)) {
          setInfo(snackInfo.openInfo(emptyResult))
          return null;
        }

        const sortedData = sortByKey(data)
        setPeptideClsObj(sortedData)

        setPeptideClsLabels(Object.keys(sortedData).map(item => item))

        let sum = 0;
        setPeptideClsValue(Object.values(sortedData).map(item => {
          sum += item;
          return item
        }))

        setPeptideSum(sum)
        setTotalSeq(result.data[totalSeqKey])

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const [stateUnique, setStateUnique] = useState(axiosState.init())
  const getUniqueLetter = () => {
    const selectedSourceID = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceID)
    const selectedClsID = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)
    let input = {};

    if (selectedSourceID !== "") {
      input["source_code"] = selectedSourceID
    }
    else if (selectedClsID !== "") {
      input["class_code"] = selectedClsID
    } else {
      // Total protein List
      input["source_code"] = "000000"
    }

    const config = apiConfig.protein.unique_letter(input)
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateUnique(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateUnique(axiosState.resultCode200())
        // console.log(result.data);
        
        const data = result.data;
        setLetterObj({ ...data["items"], "total peptides": data[totalSeqKey] })
      }
    }).catch(err => {
      setStateUnique(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    const classGraphSelectedSourceName = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceName)
    const classGraphSelectedSourceID = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceID)
    const classGraphSelectedClsName = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsName)
    const classGraphSelectedClsID = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)

    let input = {}

    // Set Name
    if (classGraphSelectedSourceName !== "" && classGraphSelectedSourceName !== undefined) {
      setPageProteinName(classGraphSelectedSourceName)
      setPageSelectedProteinType("source")
    } else if (classGraphSelectedClsName !== "" && classGraphSelectedClsName !== undefined) {
      setPageProteinName(classGraphSelectedClsName)
      setPageSelectedProteinType("cls")
    } else {
      // Total protein List
      setPageProteinName(classGraphSelectedSourceName)
      setPageSelectedProteinType("")
    }


    // Set ID
    if (classGraphSelectedSourceID !== "") {
      setPageSelectedProteinID(classGraphSelectedSourceID)
      setPageSelectedProteinType("source")

      input["source_code"] = classGraphSelectedSourceID
    } else if (classGraphSelectedClsID !== "") {
      setPageSelectedProteinID(classGraphSelectedClsID)
      setPageSelectedProteinType("cls")

      input["class_code"] = classGraphSelectedClsID
    } else {
      // Total protein List
      setPageSelectedProteinID("000000")
      input["source_code"] = "000000"

      setPageSelectedProteinType("")
    }

    getPeptideClsList(input)
    getUniqueLetter()

    return () => {
    };
  }, []);


  return (
    <LayoutPageV2 bread={texts.bread} fontSizeType={texts.fontSizeType}>
      
        {state.isLoading ? <LoadingAnime /> :
          <>
            <ChartSection
              chartBlockTitle={words.chart_fragment.title}
              mainChart={
                <BarChart
                  data={chartFragmentData}
                  options={chartFragmentOptions}
                  yAxisLabel={words.chart_fragment.y} />
              } />
              
            <Box style={{width: '100%',height: 50,}}></Box>

            <div className={scss.top_table}>
              <div className={scss.table_left}>
                <div className={scss.table_header_left}>
                  <div className={scss.header_title}>
                    <div className={scss.header_left}>
                      <div className={scss.text_header_number}>{texts.tableHeaders[0]}</div>
                      <div className={scss.text_header_name}>{texts.tableHeaders[1]}</div>
                      <div className={scss.text_header_counts}>{texts.tableHeaders[2]}</div>
                    </div>
                  </div>
                </div>

                <div className={scss.table_row_left}>
                  <div style={{ paddingTop: "110px"}} />
                  <div className={scss.top_row} >
                    <TableContainer style={{backgroundColor:'var(--black-black)'}}  component={Paper}square>
                      <Table className={`${scss.left_border}`}  stickyHeader aria-label="a dense table">
                        <TableBody>
                          {Object.entries(peptideClsObj).length !== 0 &&
                            Object.entries(peptideClsObj).map((item, index) =>
                              rowChildren(item, index)
                            )}

                          <TableRow className={scss.each_row} style={{ cursor: 'default' }}>
                            <MuiTableCell align="center"></MuiTableCell>
                            <MuiTableCell>
                              Sum
                            </MuiTableCell>
                            <MuiTableCell align="right">{peptideSum}</MuiTableCell>
                          </TableRow>

                          <TableRow className={scss.each_row}
                            data-selected={isSelectedTotalSeq}
                            onClick={(event) => {

                              if (!isSelectedTotalSeq) {
                                setSelectedSeqIndex(null)
                                setIsSelectedTotalSeq(true)
                                setSelectedPeptideClsName(totalSeqKey)
                              }
                            }}>
                            <MuiTableCell align="center"></MuiTableCell>
                            <MuiTableCell>
                              Total peptide sequences
                            </MuiTableCell>
                            <MuiTableCell align="right">{totalSeq}</MuiTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>

              <div className={scss.table_right}>
                <div className={scss.table_header_right}>
                  <div className={scss.header_title}>
                    <div className={scss.header_right}>
                      <div className={scss.text_header}>
                        {texts.tableHeaders[3]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={scss.row_right}>
                  <div className={scss.seq_block}>
                    {selectedPeptideClsName === "" && "Please click an peptide to see sequence."}
                    {letterObj[selectedPeptideClsName] === "" ? "There is no data, please click another peptide." :
                      <TagText html={letterObj[selectedPeptideClsName]} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </>}
      
    </LayoutPageV2 >
  )
}
export default ProteinClassGraph