import Grid from '@mui/material/Grid';

import { breadData } from 'components/bread/BreadBar';
import ToolItem from "components/tool/ToolItem";
import paths from 'utils/network/apiPath';
import LayoutPage from 'layouts/LayoutPage';
import scss from './Tools.module.scss';
import imgs, { images } from 'utils/imgs';
import cookie from 'utils/cookie';
import LayoutPage_2 from 'layouts/LayoutPage_2';
import LayoutPage_3 from 'layouts/LayoutPage_3';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> this page
// path: paths.site.tools
const texts = {
  bread:  breadData.create('Tools', 'Prediction & Calculation Tools'),
}

function toolData(title, content, imgUrl, link) {
  return {title, content, imgUrl, link}
}
export const toolList = [
  toolData(
    'HotSpot Search', //1
    "Mapping the peptides to the proteins.",
    images._03Tools['01_HotSpot_Search.png'],
    paths.spp.tool.hotspot_search(),
  ),
  toolData(
    'EHP-Tool', //2
    "Simulating enzyme hydrolysis for proteins.",
    images._03Tools['02_EHP-Tool.png'],
    paths.spp.tool.ehp_tool_search(),
  ),
  toolData(
    'BPP-Tool', //3
    "Predicting the bitterness of peptides.",
    images._03Tools['03_BPP-Tool.png'],
    paths.spp.tool.bpp_tool(),
  ),
  toolData(
    'AASD-Tool', //4
    "Obtaining the descriptors of peptides.",
    images._03Tools['04_AASD-Tool.png'],
    paths.spp.tool.aasd_tool(),
  ),
  toolData(
    'Peptide Calculator', //5
    "Estimating the properties of peptides.",
    images._03Tools['05_PeptideCalculator.png'],
    paths.spp.tool.peptide_calculator(),
  ),
  toolData(
    'Multi-cross Analysis', //6
    "Inferring the relationships between peptides.",
    images._03Tools['06_Multi-cross_Analysis.png'],
    paths.spp.tool.multi_cross_analysis(),
  ),
  toolData(
    'PDCAAS', //7
    "Calculation of PDCAAS value for a mixture.",
    images._03Tools['07_PDCAAS.png'],
    paths.spp.tool.pdcaas(),
  ),
  toolData(
    'Structure Prediction', //8
    "Predicting peptide's structure from amino acid sequence.",
    images._03Tools['08_Structure_prediction.png'],
    "",
  ),
  toolData(
    'CSS-Tool', //9
    "Cross source search tool",
    images._03Tools['07_PDCAAS.png'],
    paths.spp.tool.css_tool(),
  ),
  toolData(
    'HotSpot & CSS-Tool', //10
    "Mapping the peptides to the proteins. Cross source search tool",
    images._03Tools['10_HotSpot_PDCAAS.png'],
    paths.spp.tool.hotspot_css_tool(),
  ),
  toolData(
    'ACEiPP', //11
    'ACE-inhibitory Peptide Predictor',
    images._03Tools['11_ACEiPP.png'],
    paths.spp.tool.aceipp(),
  ),
  toolData(
    'AnOxPP', //12
    'Antioxidative Peptide Predictor',
    images._03Tools['12_AnOxPP.png'],
    paths.spp.tool.anoxpp(),
  ),
]

//---------------------------------------------------------------------------
const Tools = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss.layout}>
        {toolList.map((item, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <ToolItem imgUrl={item.imgUrl} altHint={item.title} link={item.link}
              title={item.title} content={item.content}></ToolItem>
          </Grid>
        ))}
      </div>
    </LayoutPageV2>
  )
}

export default Tools;