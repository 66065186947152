import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
// import Chart from "chart.js/auto";

import 'assets/scss/common.scss';
// import v from 'assets/scss/_variables.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import { breadData } from 'components/bread/BreadBar';
// import { cardData } from 'components/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText, Code } from 'components/tag/Text';
import { A } from 'components/tag/A';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import cookie from 'utils/cookie';
// import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage2 from 'layouts/LayoutPage2';
import LayoutPage from 'layouts/LayoutPage';
import paths from 'utils/network/apiPath';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { handleScroll } from 'components/peptide/sppLayout';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'EHP-Tool' button -> click one of 'All available enzymes' list -> this page
// path: paths.spp.tool.ehp_tool_enzyme_page(),
const jsons = spp.tool.ehp_tool_enzyme_page
const texts = {
  tabs: [
    'Overview',
    'Cross-references',
    'Reference(s)',
  ],
}

const tabs = {
  // _00card: {
  //   head: [],
  //   json: [],
  // },

  _0overview: {
    title: texts.tabs[0],
    head: [
      ...jsons.items_text.slice(0, 4+1),
    ],
    json: [
      ...jsons.items.slice(0, 4+1),
    ],
  },
  
  _1cross: {
    title: texts.tabs[1],
    head: [
      jsons.items_text[5], //"Database(s)",
    ],
    json: [
      jsons.items[5],
    ],
  },
  
  _2reference: {
    title: texts.tabs[2],
    head: [
      jsons.items_text[6], //"Literature(s)",
    ],
    json: [
      jsons.items[6],
    ],
  },  
}

//---------------------------------------------------------------------------
const ToolEHPToolEnzymePage = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const indexOfDFBPID = searchParams.get(paths.params.id)
  let input = jsons.input(indexOfDFBPID)
  // console.log(input);
  
  const [titleID, setTitleID] = useState('')
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let words = {
    bread: breadData.create('Tools', `Enzyme - ${titleID}`, bread3rd, 'Enzyme'),
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object in output
  const [card, setCard] = useState([])
  const [items, setItems] = useState([]) //page_info data

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.ehp_tool_enzyme_page(input) //50
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)
        
        let items = output[jsons.output[0]] //"page_info",
        setItems(items)
        // console.log(items)
        
        // let obj = {} //object in output
        // setOutputObj(obj)
        // console.log(obj)
        
        setTitleID(indexOfDFBPID)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      // let obj = {}
      // setOutputObj(obj)
    })
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    return (
      <>
        <Text className={'h3-darkGreen'}>{tab.title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let link = ""
          // let isFullCell = (json === "Highlight sequence")
          let isFullCell = false
          return (isHide
            ? null
            : <div key={index} className={scssPeptide[getScssNameCell(json, tab, index)]}>
              {isFullCell
                ? getValue(output, outputObj, tab.json, index, indexRef)
                : <>
                  <div key={`div-${item}`} className={scssPeptide.cell_label}>
                    <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                      {link !== ""
                        ? <A href={link} className={getClassNameTitle(item)} >
                            <TagText html={item} />
                          </A>
                        : <TagText html={item} />}
                    </Text>
                  </div>
                  <div key={`div-${index}`} className={scssPeptide.cell_value}>
                    <Text key={`text-${index}`} className={getClassName(json, output)}>
                      {getValue(output, outputObj, tab.json, index, indexRef)}
                    </Text>
                  </div>
                </>}
            </div>)
        })}
      </>
    )
  }
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log('index', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)
      
      /*
      if (Object.isObject(value)) {
        // console.log('name', name, 'value', value)
        let string = ''
        switch (name) { //spp.tool.ehp_tool_enzyme_page.items
          case "xxx":
            valueReturn = string
            break
        }
      }*/
      
      if(value === null) {
        valueReturn = ''
      } else {
        if(!Array.isArray(value) && !Object.isObject(value)) {
          // console.log(name);
          switch (name) { //spp.tool.ehp_tool_enzyme_page.items
            // case "xxx":
            //   return (<Code>{value.toCode()}</Code>)
            case "Cleavage sites":
              // console.log(value);
              valueReturn = value.replaceHTML()
              break
            default:
              valueReturn = value
          }
        }
      }
    } else {
      // console.log(name);
      valueReturn = name
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
  }, [])
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
 
  const ContentObjList = [
    <TabCell tab={tabs._0overview} output={items} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1cross} output={items} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._2reference} output={items} outputObj={outputObj}></TabCell>,
  ]
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPage2V2 bread={words.bread} card={card} output={output}>
      <div className={scssPeptide.layout}>
        <div className={scssPeptide.frame_left}>
          <StyledTabs value={tabValue}
            onChange={(e, value) => handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} label={item} index={index} />
            ))}
          </StyledTabs>
        </div>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssPeptide.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> :
                  item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2V2>
  )
}

export default ToolEHPToolEnzymePage;