import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';
import { linkData } from 'utils/links';


//---------------------------------------------------------------------------
// Go in: homepage -> 'Advanced Search' -> type text in 'Step 3' -> enter -> click 'Results' -> this page
// path: paths.spp.peptide.class_advanced_search,
const PeptideMultiSearch = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  let classification = searchParams.get(paths.params.class)
  let filterSelection = searchParams.get(paths.params.select)
  let filterValue = searchParams.get(paths.params.value)

  const [className, setClassName] = useState("")
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let bread4th = cookie.getCookie(cookie.keys.protein.bread4th)
  // console.log('bread4th', bread4th);
  let texts = {
    bread: breadData.create('Peptide', className, bread3rd, bread4th.text),

    searchHint: 'Search by SPP ID, AA sequence, AA length, Function counts, Multifunctional activities',
    tableHeadCell: spp.peptide.search.classification_advanced_search.output_text,
    tableBodyCell: spp.peptide.search.classification_advanced_search.output,
  }
  let multi_texts = {
    bread: breadData.create('Peptide', className),

    searchHint: 'Search by SPP ID, AA sequence, AA length, Function counts, Multifunctional activities',
    tableHeadCell: spp.peptide.search.advanced_multi.output_text,
    tableBodyCell: spp.peptide.search.advanced_multi.output,
  }

  useEffect(() => {
    const displayName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName)
    setClassName(displayName)
  }, []);
  return (
    <>
      {classification === 'multifunctionalpeptides'
        ? (<SearchResultLayout
          setInfo={setInfo}
          texts={multi_texts}
          configSearch={apiConfig.peptide.multi_search} //7
          jsonInput={spp.peptide.search.advanced_multi.input}
          inputProp={[filterSelection, filterValue]}
          jsonInputKeyword={spp.peptide.search.advanced_multi.input_keyword}
        />)
        : (
          <SearchResultLayout
            setInfo={setInfo}
            texts={texts}
            configSearch={apiConfig.peptide.class_advanced_search} //51
            jsonInput={spp.peptide.search.classification_advanced_search.input}
            inputProp={[classification, filterSelection, filterValue]}
            jsonInputKeyword={spp.peptide.search.classification_advanced_search.input_keyword}
          />
        )}
    </>

  )
}
export default PeptideMultiSearch